import { createContext, useContext, useEffect } from 'react';

import { getConfig, getSelfServeOnboardingUrl } from '../config/env';
import { useTranslation } from '../providers/i18next';
import { logWarning } from '../providers/rollbar';
import { logLocal } from '../providers/rollbar/api';
import { checkHasBrandingData } from '../utils/checkHasBrandingData';
import {
  decodeBase64UrlJson,
  encodeBase64UrlJson,
  getHashFragment,
  removeHashFragment,
} from '../utils/hashParams';
import { useSessionStorageRef } from '../utils/sessionStorageRef';

const HASH_PERSISTENCE_KEY = 'HashPersistence';
const WONDERSCHOOL_NETWORK_ID = 'wonderschool';

// external network hash payload shape:
// {
//   "ret": "http://pizza-planet.lego:4000/auth-return",
//   "rts": {
//     "segmentData": {
//       "action": "completed_login",
//       "data": {
//         "sessionId": "ad9c61e0-b7a4-11ed-9c4f-d54a02eb7473"
//       }
//     },
//     "networkId": "d49484ce-3af1-4970-930b-28188e773395",
//     "mode": "signin"
//   },
//   "bnd": {
//     "logo": "https://ucarecdn.com/5f5b7adb-a9a6-4ae6-8270-6d85992d9bc5/-/resize/200x/-/quality/smart/",
//     "logoSmall": "https://ucarecdn.com/499c4b59-3463-45c0-abdf-c095eaf6199e/-/resize/40x/-/quality/smart/",
//     "networkAbbr": "NM",
//     "networkId": "d49484ce-3af1-4970-930b-28188e773395",
//     "networkName": "Pizza Planet",
//     "networkAutoMigrateCcms": false, // false = 1.0 && true = 2.0 school
//   },
//   "lang": "en"
// }

export const RETURN_URL_KEY = 'ret'; // RETurn
const RETURN_STATE_KEY = 'rts'; // ReTurn State
const NO_SIGN_UP_KEY = 'nos'; // No Sign Up
const MOBILE_RETURN_KEY = 'mre'; // Mobile REturn
const INSTANT_RETURN_KEY = 'ire'; // Instant REturn
const FULL_SIGN_OUT_KEY = 'fso'; // Full Sign Out
const USE_ID_JWT_KEY = 'uij'; // Use Id token Jwt
const FORM_STATE_KEY = 'frm'; // FoRM state key
const BRANDING_STATE_KEY = 'bnd'; // BraND state key

export const RETURN_JWT_KEY = 'jwt'; // JWT
export const RETURN_EXT_KEY = 'ext'; // EXTernal
export const RETURN_SIGNIN_KEY = 'signin'; // is SIGNIN mode
export const RETURN_SIGNUP_KEY = 'signup'; // is SIGNUP mode
export const RETURN_SESSION_ID_KEY = 'ses'; // SESsion id
export const RETURN_LANGUAGE_KEY = 'lang'; // LANGuage

const { wsConfig } = getConfig();

export const DEFAULT_RETURN_URL =
  wsConfig.defaultReturn || '//wonderschool.com/auth-return';

export const SELF_SERVE_ONBOARDING_URL = getSelfServeOnboardingUrl();

const ReturnValueContext = createContext({
  /** @type {Object} json */
  returnValue: {}, // return payload after auth completes
  /**
   * Gets the return state out of return value
   * @param {Object?} retVal optional return value, defaults to current value
   * @returns {Object} new return value
   */
  getReturnState: (_retVal = null) => ({}),
  /**
   * Extends the ext data param in the return value with new data
   * and returns the brand new return value
   * @param {Object} newValue json
   * @returns {Object} new return value
   */
  extendReturnValue: (_newValue) => ({}),
  /**
   * Replaces the saved return value with a new one, for example, the actionReturn data
   * and returns the brand new return value
   * @param {Object} newValue json
   * @returns {Object} new return value
   */
  replaceReturnValue: (_newValue) => ({}),
  /**
   * Using saved data and provided `retData`, creates a url to return to the client app with the payload
   * @param {Object} retData json
   * @returns {string | undefined}
   */
  makeReturnUrl: (_retData) => '',
  /**
   * Returns to the app without any auth data
   * @returns {string | undefined}
   */
  makeBounceUrl: () => '',
  /**
   * deletes the saved return values
   * @returns {void}
   */
  removeReturnValue: () => undefined,
  /**
   * saves a new saved language key to return value and updates i18next
   * @param {string} shortCode for example, 'en'
   * @returns {void}
   */
  saveNewLanguage: (_shortCode) => undefined,
  /**
   * whether the arriving url has requested the no signup flow
   * @type {boolean}
   */
  isNoSignUpMode: false,
  /**
   * whether the arriving url has requested a full sign out instead of the normal one
   * full signout signs out of all sessions and devices everywhere
   * @type {boolean}
   */
  isFullSignOutMode: false,
  /**
   * whether the arriving url has requested the id token jwt for mobile (og lego),
   * instead of the custom token jwt used normally
   * @type {boolean}
   */
  isIdTokenJwtMode: false,
  /**
   * whether to use the mobile return page
   * @type {boolean}
   */
  isMobileReturnMode: false,
  /**
   * whether to instantly return after signout
   * @type {boolean}
   */
  isInstantReturnMode: false,
  /**
   * default values for forms supplied by client
   * @type {object | undefined}
   */
  formDefaults: undefined,
  /**
   * default values for forms supplied by client
   * @type {{
   *  disclaimer: string | object | undefined,
   *  logo: string,
   *  logoSmall: string
   *  favicon: {
   *    href: string,
   *    rel: string,
   *    sizes: string,
   *    type: string
   *  } | undefined,
   *  faviconSmall: {
   *   href: string,
   *    rel: string,
   *    sizes: string,
   *    type: string
   * }
   * } | undefined}
   */
  brandingData: undefined,
});

/**
 * finds & decodes the hash fragment or returns undefined
 * @param {Location} location window.location
 */
const findReturnValueFromLocation = (location) => {
  try {
    const fragment = getHashFragment(location);
    return decodeBase64UrlJson(fragment);
  } catch (err) {
    return; // ignore invalid hashes
  }
};

/**
 * 1. Look for value from hash fragment.
 * 2. If found, this overrides everything else.
 *    Extract it, save it to LocalStorage, and remove it from url
 * 3. If not found, try to find it from local storage.
 *    If a value is there, use it.
 * 4. Otherwise, no value anywhere.
 */
export const ReturnValueProvider = ({ children }) => {
  const hashObject = findReturnValueFromLocation(window.location);
  const [readReturnValue, setReturnValue] = useSessionStorageRef(
    HASH_PERSISTENCE_KEY,
    hashObject || undefined, // if a hash fragment is found, use that for initialization
    !!hashObject // whether to use hashObject as default init value
  );
  const { changeLanguage, selectedLangMeta } = useTranslation();

  // on initial mount, if there is a different hash fragment than the saved one, use the new one and overwrite the old
  // and if there was a fragment, clean it up once consumed
  useEffect(() => {
    const hasFragment = !!hashObject;
    if (
      hasFragment &&
      JSON.stringify(hashObject) !== JSON.stringify(readReturnValue())
    ) {
      // override existing value if there's a new hash fragment
      setReturnValue(hashObject);
    }

    // change langauge if set in returnValue
    const newLang = hashObject?.[RETURN_LANGUAGE_KEY];
    if (newLang && newLang !== selectedLangMeta.short) {
      changeLanguage(newLang);
    }

    if (hasFragment) {
      logLocal({ hashObject });
      // clean up url after hash has been consumed
      removeHashFragment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only remove url hash once

  const extendReturnValue = (newValue) => {
    // make sure to separate `ext` return data
    const { [RETURN_EXT_KEY]: ext, ...returnRest } = readReturnValue() || {};
    const nextExt = { ...(ext || {}), ...newValue };
    const newReturnValue = {
      ...returnRest,
      [RETURN_EXT_KEY]: { ...nextExt },
    };
    setReturnValue(newReturnValue);
    return newReturnValue;
  };

  const replaceReturnValue = (newValue) => {
    setReturnValue(newValue);
    return newValue;
  };

  /**
   * Using saved data and provided `retData`, creates a url to return to the client app with the payload
   * @param {Object} retData json
   * @param {Object?} returnPayload override returnValue with custom value
   * @returns {string}
   */
  const makeReturnUrl = ({ jwt, ...nextExtData }, returnPayload) => {
    let {
      // special cases that need to be extracted
      [RETURN_URL_KEY]: savedBase,
      [RETURN_EXT_KEY]: savedExtData,
      [RETURN_STATE_KEY]: returnState,
      [NO_SIGN_UP_KEY]: noSignUp, // stripped off and ignored
      [FULL_SIGN_OUT_KEY]: fullSignOut, // stripped off and ignored
      [MOBILE_RETURN_KEY]: mobileReturn, // stripped off but checked
      [USE_ID_JWT_KEY]: useIdJwt, // stripped off but checked
      [FORM_STATE_KEY]: formState, // stripped off and ignored
      [BRANDING_STATE_KEY]: brandingState, // stripped off and ignored
      ...restData
    } = returnPayload || readReturnValue() || {};

    // the default `baseOverride` is what was saved
    // however, there are use cases checked below that may override this
    let baseOverride = savedBase;

    // CCMS-1269, +CCMS-2167
    // 1. If the user is using mobile, always use the mobile requested return.
    // 2. Else If the original app specifies a SSO-based return (eg SAML), return to the specified SSO route.
    // 3. Else If the user is a new user signup from CCMS (only) (and out of the WS network), re-route to Marketplace onboarding as a director.
    // 4. Else If the original app did not specify a return (eg corp), re-route to Marketplace using the specified user type, if present, from the original app.
    // 5. Otherwise, return to the original app as specified.
    // TODO - move #3 to CCMS - https://moxit.atlassian.net/browse/CCMS-2168
    const isWSNetwork = returnState?.networkId === WONDERSCHOOL_NETWORK_ID;
    const isCCMSReturn =
      wsConfig?.ccmsDomain && baseOverride?.indexOf(wsConfig.ccmsDomain) >= 0;
    const signupData =
      nextExtData?.[RETURN_SIGNUP_KEY] || savedExtData?.[RETURN_SIGNUP_KEY];
    const isSignup = !!signupData;
    const isInvite = isSignup && !!signupData?.invitationId;
    const isDirectorReturn = isCCMSReturn && !isInvite;
    const legoSignupPayload = {
      mode: 'signup',
      source: isCCMSReturn ? 'ccms_signup' : 'sso_signup',
      type: isDirectorReturn ? 'director' : undefined,
    };
    const isCCMSOverride = isSignup && isCCMSReturn && !isWSNetwork;
    const isMissingReturnUrl = !baseOverride;
    const isSSOLocalReturn =
      !isMissingReturnUrl &&
      baseOverride?.startsWith('/') &&
      !baseOverride?.startsWith('//');

    // screen out any networks that need to remain 1.0 accounts.
    const checkShouldAutoMigrateToCcms = () => {
      // check the payload for the auto-migrate to 2.0 flag.
      // if the flag is true, then we can migrate it. if it's missing or false, it stays 1.0
      const hasAutoMigrateFlagTrue = brandingState?.networkAutoMigrateCcms;

      // handle the unfortunate requirement that florida is 2.0, but needs to be onboarded via 1.0
      const FLORIDA_NETWORK_ID = '7f448e30-515c-4b6b-872f-a436ef1a28df';
      const isFloridaNetwork = returnState?.networkId === FLORIDA_NETWORK_ID;

      return hasAutoMigrateFlagTrue && !isFloridaNetwork;
    };

    const checkIsExternalNetwork = () => {
      const networkId = returnState?.networkId ?? brandingState?.networkId;
      const hasExternalNetworkId =
        networkId && networkId != WONDERSCHOOL_NETWORK_ID;
      const hasBranding = checkHasBrandingData(brandingState);
      return hasExternalNetworkId || hasBranding;
    };

    const checkIsProviderSignup = () => {
      const isExplicitProviderSignup =
        isSignup &&
        returnState?.type === 'director' &&
        returnState?.mode === 'signup' &&
        !isInvite;

      return isExplicitProviderSignup;
    };

    const shouldRedirectToOnboarding = () => {
      const isProviderSignup = checkIsProviderSignup();
      const isExternalNetwork = checkIsExternalNetwork();

      // then check if it's a signup and not a signin, invite action, or external network signup
      const isBasicSignup = isProviderSignup && !isInvite && !isExternalNetwork;

      // only send provider signups to onboarding - ELI network must remain 1.0 school so filter those
      const isExtAndShouldSendToOnboard =
        isExternalNetwork && isProviderSignup && checkShouldAutoMigrateToCcms();

      return isBasicSignup || isExtAndShouldSendToOnboard;
    };

    // maybe override the savedBase url
    // use deeplink provided by mobile app in hash
    if (mobileReturn) {
      baseOverride = mobileReturn;
    }
    // use provided SSO endpoint already set in `baseOverride`
    else if (isSSOLocalReturn) {
      // good to go
    }
    // send provider signups to self serve onboarding
    else if (shouldRedirectToOnboarding()) {
      // this should only resolve true if the network is external
      // standard signups should trust the original return url
      if (!baseOverride?.includes?.(SELF_SERVE_ONBOARDING_URL)) {
        baseOverride = SELF_SERVE_ONBOARDING_URL;
      }
      // TODO: remove this commented block
      // baseOverride = SELF_SERVE_ONBOARDING_URL;
    }
    // TODO - move this section to CCMS - https://moxit.atlassian.net/browse/CCMS-2168
    // use default marketplace onboarding return url
    else if (isCCMSOverride) {
      baseOverride = DEFAULT_RETURN_URL;

      // set lego director payload
      returnState = {
        ...returnState,
        ...legoSignupPayload,
      };
    }
    // use default marketplace onboarding return url, user-type ignored
    else if (isMissingReturnUrl) {
      baseOverride = DEFAULT_RETURN_URL;
      if (isSignup) {
        // set lego signup payload
        returnState = {
          ...returnState,
          ...legoSignupPayload,
        };
      }
    }
    // else, use provided return url already set in `baseOverride`

    // build the payload
    const hashData = {
      ...restData,
      [RETURN_JWT_KEY]: jwt,
      [RETURN_EXT_KEY]: { ...savedExtData, ...nextExtData },
      [RETURN_STATE_KEY]: returnState,
      // external network provider signups via self-serve require additional payload data
      ...(checkIsExternalNetwork() && checkShouldAutoMigrateToCcms()
        ? {
            [RETURN_URL_KEY]: savedBase,
            [BRANDING_STATE_KEY]: brandingState,
          }
        : {}),
    };

    if (useIdJwt) {
      // don't encode if just passing id token
      return baseOverride + '#' + jwt;
    } else {
      // encode it
      const encoded = encodeBase64UrlJson(hashData);
      // add the hash
      return baseOverride + '#' + encoded;
    }
  };

  /**
   * Unlike Return Url, the Bounce Url is goes back to the app with no requirements
   * about auth flow. It requires the return url to be in the payload and the instant return
   * flag to be set. Otherwise, it tries to use the default return url without a auth payload.
   * @returns {string | undefined} instant return url w/o auth data
   * undefined if return data is not met.
   */
  const makeBounceUrl = () => {
    let {
      [RETURN_URL_KEY]: baseReturn,
      [INSTANT_RETURN_KEY]: isInstantReturnMode,
      [RETURN_STATE_KEY]: returnState,
    } = readReturnValue() || {};
    if (isInstantReturnMode && baseReturn) {
      if (returnState) {
        const hashData = {
          [RETURN_STATE_KEY]: returnState,
        };
        const encoded = encodeBase64UrlJson(hashData);
        // add the hash
        return baseReturn + '#' + encoded;
      } else {
        return baseReturn;
      }
    } else {
      return makeReturnUrl({});
    }
  };

  const removeReturnValue = () => {
    try {
      setReturnValue();
    } catch (err) {
      logWarning(`error delete localStorage value: ${err.message}`, { err });
      // can be ignored - this will get overriden next pass
    }
  };

  const saveNewLanguage = (shortCode) => {
    changeLanguage(shortCode);
    setReturnValue({
      ...readReturnValue(),
      [RETURN_LANGUAGE_KEY]: shortCode,
    });
  };

  const returnValue = readReturnValue();
  return (
    <ReturnValueContext.Provider
      value={{
        returnValue, // json object
        getReturnState: (val = readReturnValue()) =>
          val?.[RETURN_STATE_KEY] || {},
        extendReturnValue,
        replaceReturnValue,
        makeReturnUrl,
        makeBounceUrl,
        removeReturnValue,
        saveNewLanguage,
        // NOTE: These are not expected to be updated from within this app.
        // They should only be set about initial app entry.
        // Updating from either sessionStorage or extend/replaceReturnValue methods will NOT trigger an update
        isNoSignUpMode: !!returnValue?.[NO_SIGN_UP_KEY],
        isFullSignOutMode: !!returnValue?.[FULL_SIGN_OUT_KEY],
        isIdTokenJwtMode:
          !!returnValue?.[MOBILE_RETURN_KEY] && !!returnValue?.[USE_ID_JWT_KEY],
        isMobileReturnMode: !!returnValue?.[MOBILE_RETURN_KEY],
        isInstantReturnMode: !!returnValue?.[INSTANT_RETURN_KEY],
        formDefaults: returnValue?.[FORM_STATE_KEY],
        brandingData: returnValue?.[BRANDING_STATE_KEY],
      }}
    >
      {children}
    </ReturnValueContext.Provider>
  );
};

/**
 * Makes the ReturnValue context available with the useReturnValue hook
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withReturnValue = (WrappedComponent) =>
  function WrappedWithReturnValue(props) {
    return (
      <ReturnValueProvider>
        <WrappedComponent {...props} />
      </ReturnValueProvider>
    );
  };

/**
 * hook that returns the ReturnValueContext object
 */
const useReturnValue = () => useContext(ReturnValueContext);

export { withReturnValue, useReturnValue };
