import { useTranslation } from '../../../../providers/i18next';
import LogoBox from './LogoBox';
import LogoImage from './LogoImage';

const LeftBrandLogo = ({ srcSmall, srcBig }) => {
  const { txn } = useTranslation();
  const alt = txn('Logo');
  return (
    <LogoBox>
      <LogoImage
        // small
        display={{
          base: 'block',
          sm: 'none',
        }}
        src={srcSmall}
        alt={alt}
      />
      <LogoImage
        // big
        display={{
          base: 'none',
          sm: 'block',
        }}
        src={srcBig}
        alt={alt}
      />
    </LogoBox>
  );
};

export default LeftBrandLogo;
