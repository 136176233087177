import { Box, Center, CircularProgress } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { ExternalLink } from '../components/Links';
import LoadingSpinner from '../components/LoadingSpinner';
import { useAuthState } from '../providers/authState';
import { PROGRESS_SIZE } from '../providers/chakra/layout';
import { useGlobalLoader } from '../providers/globalLoader';
import { useTranslation } from '../providers/i18next';
import { useReturnValue } from '../providers/returnValue';
import { logError } from '../providers/rollbar';
import { logWarning } from '../providers/rollbar/api';
import { SIGNOUT_ROUTE } from '../routes/constants';
import { useSessionStorageRef } from '../utils/sessionStorageRef';
import LoadingPage from './LoadingPage';
import { RETURN_URL_TEMP_STORE, ReturnStages } from './ReturnPage';

const ReturnCompletePage = ({ timeout = 3000 }) => {
  // Hooks
  const { txn } = useTranslation();
  const { disableLoader } = useGlobalLoader();
  const { isAuthenticated, signOut } = useAuthState();
  const { isMobileReturnMode } = useReturnValue();
  const [getReturnUrlFromStore, _setReturnUrlInStore] = useSessionStorageRef(
    RETURN_URL_TEMP_STORE
  );

  // State - Page
  const [error, setError] = useState();
  const [didTimeout, setDidTimeout] = useState(false);
  const [redirectReady, setRedirectReady] = useState(false);

  // Refs
  const signingOut = useRef(false);
  const shownUnexpectedDelay = useRef(false);

  useEffect(
    () => {
      // set a timer to fail if we can't find the return url
      let timer = setTimeout(() => {
        if (!getReturnUrlFromStore()) {
          // force signout
          window.location.replace(SIGNOUT_ROUTE);
        }
      }, 6000);

      return () => clearTimeout(timer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // only once on mount
    ]
  );

  // Stage 5 - Maybe Sign Out
  useEffect(() => {
    let stillMounted = true;
    if (!signingOut.current) {
      const trySignOutAndRedirect = async () => {
        // only signout if necessary
        // 1.0 mobile return payload: https://github.com/wonderschool/mobile/blob/28cdbaf6cf037e68ab1d2eb9b7269712e1f57e88/src/screens/sso/SSOLoginScreen.tsx#L57
        // const params = { ret: '', rts: {}, uij: true, nos: true, mre: callback_uri_string }
        // makeReturnUrl will use mre for the returnUrl base if mobile
        if (isMobileReturnMode && isAuthenticated) {
          const result = await signOut();
          if (!result && stillMounted) {
            throw new Error('Could not sign out');
            // caught by catch below
          }
        }
        // either after sign out or if sign out isn't needed
        setRedirectReady(true);
      };
      signingOut.current = true;
      trySignOutAndRedirect()
        .catch((err) => {
          disableLoader();
          if (stillMounted) {
            logError('Could not sign user out', err, {
              isMobileReturnMode,
            });
            setError(new Error('User Cleanup Failed'));
            // if there's an error, the error message will allow the user to manually signout
          }
        })
        .finally(() => {
          signingOut.current = false;
        });
      return () => {
        stillMounted = false;
        signingOut.current = false;
      };
    }
  }, [
    disableLoader,
    getReturnUrlFromStore,
    isAuthenticated,
    isMobileReturnMode,
    signOut,
  ]);

  // Stage 6 - Perform Redirect
  useEffect(() => {
    if (redirectReady) {
      window.location.replace(getReturnUrlFromStore());
    }
  }, [getReturnUrlFromStore, redirectReady]);

  const currentReturnStage = !redirectReady
    ? ReturnStages.MaybeMobileSigningOut
    : ReturnStages.PerformingRedirect;

  const showLoading = () => {
    if (didTimeout) {
      return (
        <Box>
          <Center>
            <CircularProgress
              capIsRound
              isIndeterminate
              size={PROGRESS_SIZE}
              color={currentReturnStage.color}
            />
          </Center>
          <Center>
            {txn(currentReturnStage.msg)}
            <span>&hellip;</span>
          </Center>
          <Center>
            <ExternalLink useNewTab={false} href={getReturnUrlFromStore()}>
              {txn('Click here to finish')}
            </ExternalLink>
          </Center>
        </Box>
      );
    } else {
      return <LoadingSpinner />;
    }
  };

  return (
    <LoadingPage
      titleBase={txn('Redirecting')}
      timeout={timeout}
      error={error}
      onTimeout={() => {
        setDidTimeout(true);
        disableLoader();

        if (!shownUnexpectedDelay.current) {
          logWarning(
            'This is taking a little longer than expected. Please wait or refresh your page.',
            { timeout, returnComplete: true }
          );
        }
        // Prevent the log from showing multiple times
        shownUnexpectedDelay.current = true;
      }}
    >
      {showLoading()}
    </LoadingPage>
  );
};

export default ReturnCompletePage;
