import { ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { LoadingPage } from '../pages';
import { useAuthState } from '../providers/authState';
import { RETURN_ROUTE } from './constants';
import LoadingRoute from './LoadingRoute';

const defaultAuthRoute = RETURN_ROUTE;
const MAX_TIMEOUT = 4000;

interface Props {
  children: ReactNode | undefined;
  authenticatedRedirect?: string;
  bypassLoadingWrapper?: boolean;
}
/**
 * Waits for User initialization from LoadingRoute to complete.
 * Then waits a bit of time for any potential asynchronous signout events to complete.
 * 1) if the user is already signed out (isAuth = false), show guest directly but allow this to be changed up to `MAX_TIMEOUT`
 * 2) if the user auth status changes within the changeable window, allow it. If it changes to auth, it will be permanent
 * 3) if the change window times out, lock it in. if a guest status hasn't been locked in by now, force auth redirect
 * @param {{authenticatedRedirect: string?, children, ...rest}} props props
 *  - authenticatedRedirect: where to redirect if the user is authenticated
 * @returns {React.Component}
 */
const GuestRoute = ({
  authenticatedRedirect,
  children,
  bypassLoadingWrapper,
  ...rest
}: Props) => {
  const { isAuthenticated, isLoading, isReady, isUserLoading } = useAuthState();
  const isFinished = !isLoading && isReady && !isUserLoading;
  const [showGuest, setShowGuest] = useState(!isAuthenticated);
  const [showAuthRedirect, setShowAuthRedirect] = useState(false);
  const canChangeRouteRef = useRef(true);

  // after timeout, we don't change ref
  useEffect(() => {
    const timer = setTimeout(() => {
      canChangeRouteRef.current = false;
      if (!showGuest && isAuthenticated) {
        // ok to update auth status if timed out
        setShowAuthRedirect(true);
      }
    }, MAX_TIMEOUT);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGuest, isAuthenticated]);

  // Be wary since these effects can happen before LoadingRoute completes
  // IsFinished duplicates the checks in LoadingRoute to prevent needless redirection
  useEffect(() => {
    if (isFinished && canChangeRouteRef.current) {
      // only let authRedirect happen once
      if (isAuthenticated) {
        canChangeRouteRef.current = false;
      }
      // ok to update guest/auth status if isAuthenticated changes
      setShowGuest(!isAuthenticated);
      setShowAuthRedirect(isAuthenticated);
    }
  }, [canChangeRouteRef, isAuthenticated, isFinished]);

  const renderRoute = (): React.ReactElement | null => {
    // during transition from auth -> guest
    if (
      !isAuthenticated && // guest (already changed)
      !showGuest && // not showing guest (not changed yet)
      !showAuthRedirect && // ignore if already showed auth redirect
      canChangeRouteRef.current // ignore if can't change
    ) {
      // brief state change when user is found (isAuthenticated) but showGuest has not been updated. skip this state.
      return <LoadingPage timeout={0} />;
    }

    if (showGuest) {
      // guest mode
      return <>{children}</>;
    } else if (showAuthRedirect) {
      // user found, redirect to the specified guest route.
      return (
        <Navigate to={authenticatedRedirect || defaultAuthRoute} replace />
      );
    } else {
      return <LoadingPage />;
    }
  };

  // WARNING: this is a hacky work around for some very weird behavior related to SIGN UPs.
  // the <LoadingRoute> component claims it will not rerender once ready, but it definitely does.
  // it somehow interrupts SIGN UPs during auth state transition by doing a "partial remount" of children
  // in the middle of what should be a synchronous function.
  // "partial remount" in this context means: the onSubmit function is interrupted mid-execution,
  // the component is remounted/mounted with the initial local state, specifically the
  //  `const [loading, setLoading] = useState(false)` is reset to false,
  // after which, the onSubmit handler finishes its execution.
  // this is especially baffling as the react-form-hooks `isSubmitting` is also reset.
  // trusting that this double wrapping of <Loading /> serves an important purpose,
  // this bypass flag is only used for SIGN UPs, which should avoid any regression in use cases
  // involving active sessions and SIGN IN events.
  if (bypassLoadingWrapper) {
    return renderRoute();
  }

  return <LoadingRoute {...rest}>{renderRoute()}</LoadingRoute>;
};

export default GuestRoute;
