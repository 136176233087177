import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './App';
import { getGtmId } from './config/env';

import './utils/polyfills';

const GTM_ID = getGtmId();

if (GTM_ID) {
  const tagManagerArgs = {
    gtmId: GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

/**
 * Entry point and React starter
 */
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
