import { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// import global styles
import './styles/global.css';

import { withMiddlewares } from './providers/middlewares';
import AppRoutes from './routes/AppRoutes';

/**
 * Middlewares wrap provider contexts around the app, providing redux/flux-like funtionality.
 * AppRoutes represent the routes & pages that the app provides. They rely on the middleware contexts.
 * Those are joined together into one super React component
 */
const CombinedApp = withMiddlewares(
  AppRoutes as unknown as Component<any, any, any>
);
const App = () => (
  <BrowserRouter>
    <CombinedApp />
  </BrowserRouter>
);

export default App;
