/*****************************/
/* CLONED in CCMS-WEB-WSAuth */
/*****************************/

import useLocalStorage from 'use-local-storage';

const SESSION_PERSISTENCE_KEY = 'SessionId';

/**
 * hook to persist a session id into local storage
 * @returns {[
 *  savedSessionId: string,
 *  setSessionId: (string) => void
 * ]}
 */
const useSavedSessionId = () => {
  const [savedSessionId, setSessionId] = useLocalStorage(
    SESSION_PERSISTENCE_KEY,
    undefined, // rely on presisted value to initialize
    {
      logger: (_err) => {
        // this is too noisy on empty json so we're silencing it
      },
    }
  );
  return [savedSessionId, setSessionId];
};

export default useSavedSessionId;
