import { useCallback, useEffect, useReducer, useState } from 'react';

import { useAuthState } from '../../../providers/authState';
import { useTranslation } from '../../../providers/i18next';
import { supportEmail } from '../../../utils/support';

const verificationInitState = {
  verifying: false,
  verified: false,
};

const initVerificationActionType = 'checking';
const savedVerifiedActionType = 'verified';

const initVerificationAction = {
  type: initVerificationActionType,
};

const saveVerifiedAction = (verified) => ({
  type: savedVerifiedActionType,
  verified,
});

const verificationReducer = (state, action) => {
  switch (action.type) {
    case initVerificationActionType:
      return {
        ...state,
        verifyReady: 1, // intermediate state
      };
    case savedVerifiedActionType:
      return {
        ...state,
        verifyReady: true,
        verified: action.verified,
      };
    default:
      return state;
  }
};

/**
 * A hook that will initiate the verification process for an action code
 * It will either verify the action code (leaving it usable) or apply it (enacting the contained change
 * and consuming it) depending on the provided useApply argument.
 * This hook ensures that the verification is only performed once and it returns status udpates
 * along the way.
 * It returns the verification statuses and an error message string if needed.
 * @param {string} actionCode
 * @param {boolean} useApply
 * @returns {{
 *  isVerifyReady: boolean,
 *  isVerified: boolean,
 *  verifyError: string
 * }}
 *  - isVerifyReady: whether the state is stable
 *  - isVerified: whether the action code has been verified
 */
const useVerificationHook = (actionCode, useApply = false) => {
  if (!actionCode) {
    throw new Error('action code required');
  }
  const { txn } = useTranslation();
  const [verifyError, setVerifyError] = useState(undefined);
  const [actionCodeInfo, setActionCodeInfo] = useState(undefined);
  const [verification, dispatch] = useReducer(
    verificationReducer,
    verificationInitState
  );
  const { isReady, applyActionCode, verifyActionCode } = useAuthState();
  const shouldVerify = isReady && !verification.verifyReady && !actionCodeInfo;

  const performVerificationAction = useCallback(async () => {
    const actionCodeMethod = useApply ? applyActionCode : verifyActionCode;
    const result = await actionCodeMethod(actionCode);
    if (result?.errorCode) {
      switch (result.errorCode) {
        case 'actionCode':
          setVerifyError(
            txn('Your link has expired. Please generate a new one.')
          );
          return false;
        case 'user':
          setVerifyError(
            txn(
              "There's an issue with your account. Please contact {{supportEmail}}.",
              { supportEmail }
            )
          );
          return false;
        default:
          setVerifyError(txn('Oops, something went wrong'));
          return false;
      }
    } else {
      if (result) {
        if (!useApply) {
          setActionCodeInfo(result);
        }
        setVerifyError(undefined);
      }
      return !!result;
    }
  }, [actionCode, applyActionCode, txn, useApply, verifyActionCode]);

  // asynchronously performs the verification action, taking care to only run once
  // unlike other effects, it is fine that this resolves even if it isn't on the current
  // render cycle because we have safeguards to ensure it only successfully completes once
  useEffect(() => {
    async function verify() {
      if (shouldVerify) {
        dispatch(initVerificationAction);
        const isVerified = await performVerificationAction();
        dispatch(saveVerifiedAction(isVerified));
      }
    }
    verify();
  }, [performVerificationAction, shouldVerify]);

  return {
    isVerifyLoading: verification.verifyReady !== true,
    isVerifyError: verification.verifyReady === true && !verification.verified,
    isVerified: verification.verifyReady === true && verification.verified,
    verifyError,
    actionCodeInfo,
  };
};

export default useVerificationHook;
