import { chakra } from '@chakra-ui/react';
import { createContext, useContext, useRef, useState } from 'react';

import Loader from '../components/LoaderV2';
import PageBaseV2 from '../pages/PageLayout/PageBaseV2';
import { useTranslation } from './i18next';

const GlobalLoaderContext = createContext({
  activateLoader: () => undefined,
  disableLoader: () => undefined,
  setSkipLoadingTimeout: () => undefined,
  isSkipLoadingTimeout: () => false,
});

const LoaderContainer = chakra('div', {
  baseStyle: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
  },
});

/**
 * Either shows a global loader or the normal children.
 * To prevent (some) mount/unmount effects, the children will only be hidden
 * when the global loader is activated
 * @param {{ active: boolean, children: React.ReactChildren }} props
 */
const LoaderTakeover = ({ active, children }) => {
  const { txn } = useTranslation();

  return (
    <>
      <LoaderContainer display={active ? 'block' : 'none'}>
        {/*  
            TODO: convert to TS
            TODO: convert to Tailwind
            TODO: remove the feature flag so the loading page is universal.
            TODO: confirm default loading text 
          */}
        <PageBaseV2 titleBase={txn('Loading')}>
          <Loader text={txn('Loading')} />
        </PageBaseV2>
      </LoaderContainer>
      {children}
    </>
  );
};

const GlobalLoaderProvider = ({ children }) => {
  const [isGlobalLoaderOn, setGlobalLoader] = useState(false);
  const shouldSkipLoadingTimeout = useRef(false);
  const activateLoader = () => {
    setGlobalLoader(true);
  };
  const disableLoader = () => {
    setGlobalLoader(false);
  };
  const setSkipLoadingTimeout = () => {
    shouldSkipLoadingTimeout.current = true;
  };
  const isSkipLoadingTimeout = () => shouldSkipLoadingTimeout.current;
  return (
    <GlobalLoaderContext.Provider
      value={{
        activateLoader,
        disableLoader,
        setSkipLoadingTimeout,
        isSkipLoadingTimeout,
      }}
    >
      <LoaderTakeover active={isGlobalLoaderOn}>{children}</LoaderTakeover>
    </GlobalLoaderContext.Provider>
  );
};

/**
 * Adds a global state that will render a loader and prevent stuttering renders
 * @param {React.Component} WrappedComponent
 */
const withGlobalLoader = (WrappedComponent) =>
  function WrappedWithGlobalLoader(props) {
    return (
      <GlobalLoaderProvider>
        <WrappedComponent {...props} />
      </GlobalLoaderProvider>
    );
  };

const useGlobalLoader = () => useContext(GlobalLoaderContext);

export { withGlobalLoader, useGlobalLoader };
