import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from '../providers/authState';
import { useGlobalLoader } from '../providers/globalLoader';
import { useTranslation } from '../providers/i18next';
import { useReturnValue } from '../providers/returnValue';
import { logError } from '../providers/rollbar';
import { SIGNOUT_COMPLETE_ROUTE } from '../routes/constants';
import LoadingPage from './LoadingPage';

const SignoutPage = ({ timer = 2500 }) => {
  const { txn } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthState();
  const [error, setError] = useState();
  const { isFullSignOutMode } = useReturnValue();
  const { signOut, fullSignOut } = useAuthState();
  const {
    // activateLoader,
    disableLoader,
  } = useGlobalLoader();
  const [didSignOut, setDidSignOut] = useState(false);

  const finishSignout = () => {
    disableLoader();
    navigate(SIGNOUT_COMPLETE_ROUTE, { replace: true });
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!isAuthenticated && !didSignOut && !error) {
        finishSignout();
      }
    }, timer);
    return () => clearTimeout(timerId);
  });

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isAuthenticated && !didSignOut) {
        try {
          // activateLoader();
          setDidSignOut(true);
          let result;
          if (isFullSignOutMode) {
            result = await fullSignOut();
          } else {
            result = await signOut();
          }
          if (!result) {
            throw new Error('Could not sign out');
          }
        } catch (err) {
          disableLoader();
          if (isMounted) {
            setDidSignOut(false);
            logError(`sign out error: ${err.message}`, err);
            setError(new Error('Oops! Please try again.'));
          }
        }
      } else if (didSignOut && !isAuthenticated) {
        finishSignout();
      }
    })();
    return () => (isMounted = false);
  });

  return (
    <LoadingPage titleBase={txn('Signing out')} timeout={0} error={error} />
  );
};

export default SignoutPage;
