const dataTestIds = {
  changePwd: {
    email: 'changepwd-email',
    btnReqReset: 'changepwd-btn-req-reset',
    btn: 'changepwd-btn',
    btnLogin: 'changepwd-btn-login',
    btnGoBack: 'changepwd-btn-goback',
    returnToSignin: 'changepwd-btn-return-to-signin',
  },
  signinForm: {
    signUpLink: 'sign-up-link',
    baseSigninForm: 'base-signin-form',
    username: 'username',
    password: 'password',
    forgotPwdLink: 'forgot-password-link',
    loginBtn: 'login-button',
  },
  signupForm: {
    invitationId: 'invitation-id',
    newSupplyCheckbox: 'signup-form-new-supply-checkbox',
    firstName: 'signup-form-first-name',
    lastName: 'signup-form-last-name',
    mobile: 'signup-form-mobile',
    email: 'signup-form-email',
    password: 'signup-form-password',
    passwordConfirm: 'signup-form-password-confirm',
    termsAndConditions: 'terms-and-conditions',
    btnSubmit: 'signup-form-submit-btn',
    btnGoBack: 'signup-form-goback-btn',
  },
  legalFooter: {
    termsLink: 'terms-link',
    privacyPolicyLink: 'privacy-policy-link',
  },
  supportEmailLink: 'support-email-link',
};

export { dataTestIds };
