import {
  getIdToken as getIdTokenFirebase,
  sendEmailVerification as sendEmailVerificationFirebase,
} from 'firebase/auth';

import { logError, logWarning } from '../../providers/rollbar';
import { getDefaultSettings } from './actionCodeSettings';
import { ACTION_TYPES, saveActionReturn } from './actionReturn';
import { getCustomToken as getCustomTokenAPI } from './functions';

/**
 * @typedef {import('firebase/auth').User} User
 */

/**
 * ### Async / Promise
 * Gets a custom token that can be used to sign into another firebase instance.
 * This will not contain much identifying information in the token.
 * This is useful for most apps, especially ones that rely on firebase or other modern
 * authentication systems. (ex: CCMS-web and -mobile, lego-web)
 * @param {User} authUser firebase user
 * @param {boolean?} forceRefresh
 * @returns {Promise<string | undefined>}
 * if undefined, an error occurred.
 * otherwise, returns custom token.
 */
export const getCustomToken = async (authUser, forceRefresh) => {
  try {
    if (!authUser) {
      throw new Error('Must be logged in');
    }
    const idToken = await getIdTokenFirebase(authUser, forceRefresh);
    if (!idToken) {
      throw new Error('Firebase did not return an id token');
    }
    const response = await getCustomTokenAPI(idToken);
    const customToken = response?.jwt;
    if (customToken) {
      return customToken;
    } else {
      throw new Error('Server did not return a custom token');
    }
  } catch (err) {
    logError(`could not create a custom token: ${err.message}`, err, {
      err,
      uid: authUser.uid,
      forceRefresh,
    });
    return; // signals failure
  }
};
/**
 * ### Async / Promise
 * Gets the fully-hydrated id token. This will contain richer identifying information,
 * but will not be able to re-authenticate in another firebase client.
 * This is useful for legacy apps with their own authentication systems, where the app
 * just needs a way to associate a verified user identity. (ex: lego og mobile app);
 * @param {User} authUser firebase user
 * @param {boolean?} forceRefresh
 * @returns {Promise<string | undefined>}
 * if undefined, an error occurred.
 * otherwise, returns encoded (jwt) id token.
 */
export const getIdToken = async (authUser, forceRefresh) => {
  try {
    if (!authUser) {
      throw new Error('Must be logged in');
    }
    const idToken = await getIdTokenFirebase(authUser, forceRefresh);
    if (idToken) {
      return idToken;
    } else {
      throw new Error('Could not fetch firebase id token');
    }
  } catch (err) {
    logError(`Could not get id token: ${err.message}`, err, {
      err,
      uid: authUser.uid,
      forceRefresh,
    });
    return; // signals failure
  }
};
/**
 * ### Async / Promise
 * Sends a verification email to the user. Saves the return data beforehand so the user will always be able to come back to original app
 * @param {User} authUser firebase user
 * @returns {Promise<boolean | { errorCode: string}>}
 *  - true: success
 *  - false: generic error
 *  - errorCode object - firebase handled error
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: none
 */
export const sendVerificationEmail = async (
  authUser,
  actionReturnData = {}
) => {
  try {
    if (!authUser) {
      throw new Error('Must be logged in');
    }
    await saveActionReturn(
      authUser.uid,
      ACTION_TYPES.VERIFY_EMAIL,
      actionReturnData // available for any device that opens the verification email
    );
    await sendEmailVerificationFirebase(authUser, getDefaultSettings());
    return true;
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/missing-android-pkg-name - An Android package name must be provided if the Android app is required to be installed.
     * auth/missing-continue-uri - A continue URL must be provided in the request.
     * auth/missing-ios-bundle-id - An iOS bundle ID must be provided if an App Store ID is provided.
     * auth/invalid-continue-uri - The continue URL provided in the request is invalid.
     * auth/unauthorized-continue-uri - The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
     */
    if (err.code) {
      switch (err.code) {
        default:
          logWarning(
            `Could not send verification email (handled): ${err.message}`,
            {
              err,
              code: err.code,
              uid: authUser.uid,
              actionReturnData,
            }
          );
          return false; // signals unhandled error
      }
    } else {
      logError(`Could not send verification email: ${err.message}`, err, {
        err,
        uid: authUser.uid,
        actionReturnData,
      });
      return; // signals failure
    }
  }
};
