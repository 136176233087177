import { useRef } from 'react';

import { logWarning } from '../providers/rollbar';

// from https://www.30secondsofcode.org/react/s/use-session-storage
/**
 * Creates a stateful value that is persisted to sessionStorage, and a function to update it.
 *
 * Use the useState() hook with a function to initialize its value lazily.
 * Use a try...catch block and Storage.getItem() to try and get the value from Window.sessionStorage.
 * If no value is found, use Storage.setItem() to store the defaultValue and use it as the initial state. If an error occurs, use defaultValue as the initial state.
 * Define a function that will update the state variable with the passed value and use Storage.setItem() to store it.
 * @param {string} keyName
 * @param {*} defaultValue
 * @param {boolean} useDefaultAsInitialValue whether to set the default value argument as the initial value
 * @returns [reader, setter]
 *
 * Provides non-observable functions to prevent unnecessary re-renders
 */
export const useSessionStorageRef = (
  keyName,
  defaultValue,
  useDefaultAsInitialValue = false
) => {
  const sessionWriteSafe = (maybeVal) => {
    try {
      if (maybeVal === undefined) {
        window.sessionStorage.removeItem(keyName);
      } else {
        window.sessionStorage.setItem(keyName, JSON.stringify(maybeVal));
      }
    } catch (err) {
      logWarning(
        `caught error in sessionStorage.sessionWriteSafe: ${err.message}`,
        { err }
      );
    }
  };

  const readValueWithDefault = () => {
    try {
      if (useDefaultAsInitialValue) {
        sessionWriteSafe(defaultValue);
        return defaultValue;
      } else {
        const value = window.sessionStorage.getItem(keyName);

        if (value) {
          return JSON.parse(value);
        } else {
          sessionWriteSafe(defaultValue);
          return defaultValue;
        }
      }
    } catch (err) {
      logWarning(
        `caught error in sessionStorage.storedValueRef: ${err.message}`,
        { err }
      );
      return defaultValue;
    }
  };

  // initialize default value
  const storedValueRef = useRef(readValueWithDefault());

  // use ref to update latest values and prevent re-renders
  const setValue = (newValue) => {
    sessionWriteSafe(newValue);
    storedValueRef.current = newValue;
  };
  const readValue = () => storedValueRef.current;

  return [readValue, setValue];
};
