import {
  ChakraProvider,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import GlobalFontModerat from './chakra/baseFont';
import theme from './chakra/theme';
import { useToast } from './chakra/useToast';

/**
 * Implements Chakra UI library and style theming, including Moderat font
 * Makes the Chakra context available for use with Chakra hooks
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withChakra = (WrappedComponent) =>
  function WrappedWithChakra(props) {
    return (
      <ChakraProvider theme={theme}>
        <GlobalFontModerat />
        <WrappedComponent {...props} />
      </ChakraProvider>
    );
  };

export { withChakra, useColorMode, useColorModeValue, useToast };
