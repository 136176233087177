import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { isLocal } from '../env';
import { logWarning } from '../rollbar';

let i18nMemo;
const initI18N = (defaultLanguage = 'en') => {
  i18nMemo =
    i18nMemo ||
    (() => {
      i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          fallbackLng: defaultLanguage,
          debug: isLocal(),

          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },

          parseMissingKeyHandler: (key) => {
            const str = `Missing translation: ${key}`;
            logWarning(str, { key });
            return key;
          },
        });
      return i18n;
    })();
  return i18nMemo;
};

export default initI18N;
