import { isEmpty, isString } from 'lodash';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useEnv } from './env';

/**
 * Wraps component with a React Context to support Helmet component.
 * https://github.com/staylor/react-helmet-async
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withHelmet = (WrappedComponent) =>
  function WrappedWithHelmet(props) {
    return (
      <HelmetProvider>
        <WrappedComponent {...props} />
      </HelmetProvider>
    );
  };

const envIcons = {
  local: '💻',
  dev: '🧪',
  prod: '',
};

/**
 * Title will default to `Wonderschool` if not provided.
 * Adds some environment icons for non-prod envs.
 * @param {string} title
 * @param {React.Component[]} favicons list of favicons
 * @returns {React.Component} Helmet component with a title.
 */
const HelmetTitle = ({ title = '', favicons = [] }) => {
  const { env } = useEnv();
  let titleString = 'Wonderschool';
  if (isString(title) && !isEmpty(title)) {
    const icon = envIcons[env] || '';
    if (!isEmpty(icon)) {
      titleString = `${icon} ${title} ${icon}`;
    } else {
      titleString = title;
    }
  }
  return (
    <Helmet>
      <title>{titleString}</title>
      {favicons}
    </Helmet>
  );
};

export { withHelmet, HelmetTitle };
