// CODE MIRRORED IN /functions/api/actionReturn.js (v9)
// This is using Firebase v9 syntax

import { deleteDoc, getDocument, writeMergeDoc } from './firestore';

const ACTION_TYPES = {
  RECOVER_EMAIL: 'recoverEmail',
  RESET_PASSWORD: 'resetPassword',
  VERIFY_EMAIL: 'verifyEmail',
};

/**
 * For each action type, we can save one state record
 * for any device to use when it consumes the action
 */
const paths = {
  /**
   * @param {string} userId firebase id
   * @param {'recoverEmail' | 'resetPassword' | 'verifyEmail'} actionType type of action
   * @returns {string} doc path
   */
  actionReturn: (userId, actionType) =>
    `authSession/${userId}/actionReturn/${actionType}`,
};
/**
 * make json payload for signup data
 * @param {string} userId firebase id
 * @param {'recoverEmail' | 'resetPassword' | 'verifyEmail'} actionType type of action
 * @param {Object} returnData data to be saved for later use
 * @returns {Object} action return data, sanitized
 */
const makeActionReturnData = (userId, actionType, returnData = {}) => ({
  userId,
  actionType,
  ...returnData,
});
/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to persist actionReturn data. If successful, the (slightly different) written data is returned.
 * @param {string} userId firebase id
 * @param {string} actionType type of action
 * @param {Object} data data to be saved
 * @returns {Promise<Object>} saved data
 * @throws { Error } if userId is not provided
 * @throws { Error } if actionType is not provided
 * @throws { Error } record fails to write
 */
const saveActionReturn = async (userId, actionType, data) => {
  if (!userId) {
    throw new Error('userId required');
  }
  if (!actionType) {
    throw new Error('actionType required');
  }
  const actionReturnData = makeActionReturnData(userId, actionType, data);
  const didWrite = await writeMergeDoc(
    paths.actionReturn(userId, actionType),
    actionReturnData
  );
  if (didWrite) {
    return actionReturnData;
  } else {
    throw new Error('Error writing action return data');
  }
};
/**
 * ### Async / Promise
 * gets the saved action return state from firestore,
 * consumming/deleting it in the process
 * @param {string} userId firebase id
 * @param {string} actionType type of action
 * @returns {Promise<DocumentData | undefined>}
 * if undefined, either the document was not found or an error occurred.
 * otherwise, returns the document data as json object
 * will not delete if an error occurs
 */
const consumeActionReturn = async (userId, actionType) => {
  const docPath = paths.actionReturn(userId, actionType);
  const actionReturnData = await getDocument(docPath);
  // only delete if successful fetch
  if (actionReturnData) {
    await deleteDoc(docPath);
  }
  return actionReturnData || undefined;
};

export { saveActionReturn, consumeActionReturn, ACTION_TYPES };
