import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';

import { useColorMode, useColorScheme } from '../../../providers/chakra/colors';
import { HEADER_SIZE } from '../../../providers/chakra/layout';
import { useFeatureFlags } from '../../../providers/featureFlags';
import { languages, useTranslation } from '../../../providers/i18next';
import { useReturnValue } from '../../../providers/returnValue';

const RightMenu = () => {
  const inputColors = useColorScheme('makeInputColors');
  const { selectedLangMeta, txn } = useTranslation();
  const { saveNewLanguage } = useReturnValue();
  const { isDark, toggleColorMode } = useColorMode();
  const { DarkModeFlag } = useFeatureFlags();
  const darkModeFeatureReady = DarkModeFlag.enabled;

  const defaultValue = selectedLangMeta.short;

  return (
    <Box
      flex="1 1 auto"
      h={HEADER_SIZE}
      align="right"
      display="flex"
      alignSelf="center"
      alignItems="center"
      alignContent="center"
      justifyContent="flex-end"
    >
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          h="28px"
          fontWeight="normal"
          {...inputColors}
        >
          <Text
            display={{
              base: 'block',
              sm: 'none',
            }}
          >
            {selectedLangMeta.short}
          </Text>
          <Text
            display={{
              base: 'none',
              sm: 'block',
            }}
          >
            {selectedLangMeta.display}
          </Text>
        </MenuButton>
        <MenuList minWidth="min">
          <MenuOptionGroup
            defaultValue={defaultValue}
            type="radio"
            onChange={(value) => {
              saveNewLanguage(value);
            }}
          >
            {Object.entries(languages).map(([code, { display }]) => {
              return (
                <MenuItemOption key={code} value={code}>
                  {display}
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
          {darkModeFeatureReady && (
            <>
              <MenuDivider />
              <MenuGroup>
                <MenuItem
                  closeOnSelect={false}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleColorMode();
                  }}
                >
                  <Stack direction="row" align="center">
                    <Text>{txn('Dark Mode')}</Text>
                    <Spacer minWidth="1" />
                    <Switch size="sm" isReadOnly isChecked={isDark} />
                  </Stack>
                </MenuItem>
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default RightMenu;
