import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LoadingPage } from '../pages';
import { useAuthState } from '../providers/authState';
import { logWarning } from '../providers/rollbar';
import { FEATURE_FLAGS_ROUTE, ROOT_ROUTE } from './constants';
import LoadingRoute from './LoadingRoute';

const TIMEOUT = 5000;

interface Props {
  authenticatedRedirect: string;
  guestRedirect: string;
}
/**
 * ### BEWARE of causing infinite loops here.
 * Extends a LoadingRoute and ReactRouter Route component with a few checks.
 *  - after the LoadingRoute checks,
 *  - if the user is found, redirect to the intended starting authenticated user route
 *  - otherwise, redirect to the intended starting guest route
 * ### Checks and throws if a path is specified. That is not intended usage.
 * @param {{authenticatedRedirect: string, guestRedirect: string, ...rest}} props props
 *  - authenticatedRedirect: the route to send non-guest users to
 *  - guestRedirect: the route to send guest users to
 *  - rest: standard props for ReactRouter Route
 * @returns {React.Component}
 */
const DefaultRoute = ({
  authenticatedRedirect,
  guestRedirect,
  ...rest
}: Props) => {
  const { isAuthenticated } = useAuthState();
  const [isWaitFinished, setWaitFinished] = useState(false);
  const location = useLocation();

  // these routes bypass the wait
  const isExpectedRoute =
    location.pathname === ROOT_ROUTE ||
    location.pathname === FEATURE_FLAGS_ROUTE;

  // wait a minimum amount of time
  useEffect(() => {
    const timer = setTimeout(() => {
      setWaitFinished(true);
    }, TIMEOUT);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (!isExpectedRoute) {
      const str = `Default Route activated for ${location.pathname}`;
      logWarning(str, { location });
    }
  }, [location, isExpectedRoute]);

  const renderRoute = () => {
    // Make sure to wait a bit during state transitions since redirects are one-way nav changes
    // but root routes should go quickly to prevent long spinners in the default case
    if (!isWaitFinished && !isExpectedRoute) {
      return <LoadingPage />;
    } else if (isAuthenticated) {
      // user found, redirect to the specified authenticated route
      return <Navigate to={authenticatedRedirect} replace />;
    } else {
      // no user, redirect to the specified guest route.
      return <Navigate to={guestRedirect} replace />;
    }
  };

  return <LoadingRoute {...rest}>{renderRoute()}</LoadingRoute>;
};

export default DefaultRoute;
