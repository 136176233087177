import InputMask from 'comigo-tech-react-input-mask'; // https://github.com/sanniassin/react-input-mask/issues/239
import React, { forwardRef, HTMLInputTypeAttribute, useState } from 'react';
import type { FieldError } from 'react-hook-form';

import { styles } from './styles';

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  as?: typeof InputMask;
  mask?: string;
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    { autoComplete, error, type = 'text', as: InputMask, mask, ...props },
    ref
  ) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const onShowPasswordClick = () => {
      setIsShowPassword(!isShowPassword);
    };

    return (
      <div className="relative">
        {type === 'password' ? (
          <div
            className="absolute inset-y-0 flex items-center right-3 hover:cursor-pointer"
            onClick={onShowPasswordClick}
          >
            <img
              src={isShowPassword ? '/eye-icon-disallow.svg' : '/eye-icon.svg'}
            />
          </div>
        ) : null}

        {InputMask && mask ? (
          <InputMask
            className={`${error ? styles.inputError : styles.inputClean} px-3`}
            mask={mask}
            alwaysShowMask={false}
            maskPlaceholder={null}
            {...props}
            ref={ref}
          />
        ) : (
          <input
            className={`${error ? styles.inputError : styles.inputClean}`}
            autoComplete={
              typeof autoComplete === 'undefined'
                ? undefined
                : autoComplete.toString()
            }
            type={isShowPassword ? 'text' : type}
            ref={ref}
            {...props}
          />
        )}
      </div>
    );
  }
);

// context here: https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/display-name.md
FormInput.displayName = 'FormInput';

export default FormInput;
