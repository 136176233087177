import {
  I18nextProvider,
  Trans,
  useTranslation as useTranslationOriginal,
} from 'react-i18next';

import initI18N from './i18next/config';

/**
 * supported languages
 */
const languages = {
  en: { display: 'English', short: 'en' },
  es: { display: 'Español', short: 'es' },
};
const DEFAULT_LANGUAGE = 'en';

/**
 * Makes the i18next context available with the useTranslation hook
 * https://react.i18next.com/
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withI18Next = (WrappedComponent) =>
  function WrappedWithI18Next(props) {
    const i18n = initI18N(DEFAULT_LANGUAGE);
    return (
      <I18nextProvider i18n={i18n}>
        <WrappedComponent {...props} />
      </I18nextProvider>
    );
  };

/**
 * wrapper for https://react.i18next.com/latest/usetranslation-hook
 * example: `selectedLangMeta`: { display: 'English', short: 'EN' }
 * `translation`, `txn`
 * `changeLanguage('en')`
 * @param  {...any} args see: https://react.i18next.com/latest/usetranslation-hook
 * @returns same hook args with some aliases for `t` and language display data
 */
const useTranslation = (...args) => {
  const retObj = useTranslationOriginal(...args);
  const { t, i18n } = retObj;
  return {
    ...retObj,
    translation: t,
    txn: t,
    languageKey: i18n.resolvedLanguage,
    selectedLangMeta:
      languages[i18n.resolvedLanguage] || languages[DEFAULT_LANGUAGE],
    changeLanguage: (code) => i18n.changeLanguage(code),
    Trans,
  };
};

export { withI18Next, DEFAULT_LANGUAGE, languages, useTranslation };
