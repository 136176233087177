import { extendTheme } from '@chakra-ui/react';

// extend the default chakra theme
const theme = extendTheme({
  config: {
    // light/dark
    initialColorMode: 'light',
    useSystemColorMode: false,
    // https://chakra-ui.com/docs/styled-system/color-mode#common-configurations
    // App color mode is detached from system color mode changes.
  },
  // base font Moderat
  fonts: {
    body: `Moderat, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    heading: `Moderat, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  },
  colors: {
    gray: {
      100: '#F7F7F7',
      400: '#CBCBCB',
    },
    blue: {
      500: '#2E8BD2',
    },
  },
});

export default theme;
