import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { getIdToken } from '../api/firebase/currentUser';
import { getSamlTokenData } from '../api/firebase/samlFunctions';
import SamlError from '../components/Saml/SamlError';
import SamlPostForm from '../components/Saml/SamlPostForm';
import SamlStatus from '../components/Saml/SamlStatus';
import { useAuthState } from '../providers/authState';
import { useTranslation } from '../providers/i18next';
import { logError } from '../providers/rollbar';
import PageBase from './PageLayout/PageBase';

const SamlIdpPage = () => {
  const { txn } = useTranslation();
  const { authUser } = useAuthState();

  const [tokenData, setTokenData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const serviceId = queryParams.get('serviceId');
  const requestId = queryParams.get('requestId');

  useEffect(() => {
    if (!serviceId && !requestId) {
      setIsError(true);
    }
  }, [serviceId, requestId]);

  useEffect(() => {
    let isMounted = true;

    const getToken = async () => {
      const id = await getIdToken(authUser);
      if (serviceId || requestId) {
        getSamlTokenData(id, requestId, serviceId)
          .then((returnedData) => {
            if (isMounted) {
              setTokenData(returnedData);
            }
          })
          .catch((err) => {
            logError(`Error retrieving token: ${err.name}`, err);
            setIsError(true);
          });
      }
      return () => {
        isMounted = false;
      };
    };

    getToken();
  }, [authUser, requestId, serviceId]);

  // after timeout below, set to error to display message to user after timeout
  useEffect(() => {
    const ERROR_TIMEOUT = 10 * 1000;
    const errorTimer = setTimeout(() => {
      setIsLoading(false);
      setIsError(true);
    }, ERROR_TIMEOUT);
    return () => clearTimeout(errorTimer);
  }, []);

  const haveToken = () => {
    if (tokenData) {
      return <SamlPostForm token={tokenData} />;
    } else {
      return null;
    }
  };

  return (
    <PageBase titleBase={txn('Identity Provider')}>
      <Text fontSize="xl" pb="4">
        Wonderschool {txn('Identity Provider')}
      </Text>
      {!isError ? (
        <SamlStatus
          serviceId={serviceId}
          requestId={requestId}
          token={tokenData}
          isLoading={isLoading}
        ></SamlStatus>
      ) : (
        <SamlError></SamlError>
      )}
      {haveToken()}
    </PageBase>
  );
};

export default SamlIdpPage;
