import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from '../../providers/authState';
import { PADDING, PADDING_MORE } from '../../providers/chakra/layout';
import { useTranslation } from '../../providers/i18next';
import { RETURN_ROUTE } from '../../routes/constants';
import { BaseLink } from '../Links';
import { FormHeading, FormSubtitle, ResendVerificationLink } from './Parts';

const VerifyCheck = () => {
  const { txn } = useTranslation();
  const navigate = useNavigate();
  const { authUser } = useAuthState();
  const userId = authUser?.uid;
  const hasUser = !!authUser && !!userId;
  const emailVerified = !!authUser?.emailVerified;

  useEffect(() => {
    if (hasUser && emailVerified) {
      navigate(RETURN_ROUTE, { replace: true });
    }
  }, [hasUser, emailVerified, navigate]);

  return (
    <>
      <FormHeading>{txn('Confirm your account')}</FormHeading>
      <Box marginTop={PADDING}>
        <FormSubtitle>
          {txn(
            'Thanks for signing up with us. Your account is almost ready. Please check your inbox to confirm your email address'
          )}
          : <strong>{authUser?.email}</strong>
        </FormSubtitle>
      </Box>
      <Box marginTop={PADDING}>
        <ResendVerificationLink />
      </Box>
      <Box marginTop={PADDING_MORE}>
        <BaseLink
          onClick={() => {
            window.location.reload();
          }}
        >
          {txn('Click here to reload and try again')}
        </BaseLink>
      </Box>
    </>
  );
};

export default VerifyCheck;
