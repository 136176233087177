import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { PADDING, PADDING_MORE } from '../../providers/chakra/layout';
import { useTranslation } from '../../providers/i18next';
import { SIGNIN_RESET_ROUTE, SIGNIN_ROUTE } from '../../routes/constants';
import {
  BaseSetPasswordForm,
  FormHeading,
  FormSubtitle,
  MIN_PASS_LENGTH,
  ReturnToSigninLink,
} from './Parts';

const InvitedUserForm = ({ actionCode }) => {
  const { txn } = useTranslation();
  return (
    <BaseSetPasswordForm
      actionCode={actionCode}
      FormMessage={() => {
        return (
          <>
            <FormHeading>{txn('Welcome!')}</FormHeading>
            <FormSubtitle>
              {txn(
                'Get started by choosing a secure password at least {{minLength}} characters long with both letters and numbers',
                { minLength: MIN_PASS_LENGTH }
              )}
            </FormSubtitle>
          </>
        );
      }}
      SuccessMessage={({ onComplete }) => {
        return (
          <>
            <FormHeading>{txn('Success!')}</FormHeading>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn('Your account has been successfully created.')}
              </FormSubtitle>
            </Box>
            <Box marginTop={PADDING_MORE}>
              <FormSubtitle>
                <ReturnToSigninLink
                  route={SIGNIN_RESET_ROUTE}
                  onClick={onComplete}
                />
              </FormSubtitle>
            </Box>
          </>
        );
      }}
      ErrorMessage={() => {
        return (
          <>
            <FormHeading>{txn('Oops!')}</FormHeading>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn(
                  'Unfortunately, this invitation has expired or has already been used. Please reach out to your center administrator to send you a new invitation.'
                )}
              </FormSubtitle>
            </Box>
            <Box marginTop={PADDING_MORE}>
              <FormSubtitle>
                <ReturnToSigninLink route={SIGNIN_ROUTE} />
              </FormSubtitle>
            </Box>
          </>
        );
      }}
    />
  );
};

InvitedUserForm.propTypes = {
  actionCode: PropTypes.string.isRequired,
};

export default InvitedUserForm;
