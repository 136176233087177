import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormErrorIcon,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { useColorScheme } from '../../../providers/chakra/colors';
import {
  INPUT_RADIUS,
  PADDING,
  PADDING_INPUT,
} from '../../../providers/chakra/layout';
import { INPUT_FONT, SMALLER_FONT } from '../../../providers/chakra/typography';
import { useTranslation } from '../../../providers/i18next';

/**
 * A wrapped form component for one-line textual inputs, like text, phone, password, etc.
 * Handles error display if provided. This is shown in the form of a warning icon on the right side
 * with a tooltip for the specific error message.
 *
 * For passwords, it will handle the show/hide toggle.
 *
 *
 * Ref is forwarded for compatibility with react-hook-form
 * https://react-hook-form.com/get-started#Integratinganexistingform
 */
const FormInput = forwardRef(function FormInputFwdRef(
  {
    error,
    /** @type {Error} If truthy, the input will show the error state and message */
    label,
    /** @type {Error} If truthy, the input will show the error state and message */
    placeholder,
    /** @type {string} https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/text#placeholder */
    type,
    /** @type {string} most, but probably not all of https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types */
    /** if password type, this input will have special behavior for toggling visibility (between password/text types) */
    autoComplete,
    /** @type { boolean | string } https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
    noTop,
    /** @type {boolean} hide top padding and margin, useful for top of forms or paired/half inputs */
    ...props /** rest of props, forwarded to Chakra <Input> */
  },
  ref
) {
  const { txn } = useTranslation();
  const [show, setShow] = useState(false); // for password input only
  const inputColors = useColorScheme('makeInputColors');
  const errorIconColors = useColorScheme('makeErrorColors');
  const errorTooltipColors = useColorScheme('makeErrorColors', 'bg');
  const eyeColors = useColorScheme('makeSubtleColors');

  // special case for password inputs
  const filteredType =
    type === 'password' ? (show ? 'text' : 'password') : type;

  return (
    <FormControl isInvalid={error} marginTop={noTop ? '0' : PADDING}>
      {label && <FormLabel htmlFor={props.name}>{label}</FormLabel>}
      <InputGroup>
        <Input
          placeholder={placeholder}
          type={filteredType}
          autoComplete={autoComplete}
          borderRadius={INPUT_RADIUS}
          // make sure padding and margin align with InputRightElement
          py={PADDING_INPUT}
          paddingLeft={PADDING_INPUT}
          paddingRight={type === 'password' ? '20' : PADDING_INPUT}
          fontSize={INPUT_FONT}
          variant="outline"
          {...inputColors}
          ref={ref}
          {...props}
        />
        <InputRightElement
          // make sure padding and margin align with Input
          padding={PADDING_INPUT}
          minWidth={error ? 'min' : 'max'}
        >
          <>
            {type === 'password' && (
              <Tooltip
                hasArrow
                placement="top"
                label={txn(show ? 'Hide' : 'Show')}
                fontSize={SMALLER_FONT}
                gutter={10}
                borderRadius="4"
              >
                <Button
                  size="sm"
                  onClick={() => setShow(!show)}
                  bg="transparent"
                  padding="0"
                  mr={error ? PADDING_INPUT : undefined}
                >
                  <Icon
                    boxSize={PADDING}
                    as={show ? FaEye : FaEyeSlash}
                    {...eyeColors}
                  />
                </Button>
              </Tooltip>
            )}
            <FormErrorIcon
              as={() => (
                <Tooltip
                  hasArrow
                  placement="top"
                  label={error?.message}
                  fontSize={SMALLER_FONT}
                  closeOnClick={false}
                  gutter={10}
                  borderRadius="4"
                  defaultIsOpen
                  {...errorTooltipColors}
                >
                  <Icon
                    as={WarningTwoIcon}
                    boxSize={PADDING}
                    {...errorIconColors}
                  />
                </Tooltip>
              )}
            />
          </>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
});
FormInput.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  autoComplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  noTop: PropTypes.bool,
};

export default FormInput;
