import { Box, Text } from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useRef } from 'react';

import { appConstant } from '../../../config/app-constant';
// import { trackSigninEngagementEvent } from '../../../api/firebase/engagements';
import { dataTestIds } from '../../../config/data-testids';
import { getFunctions } from '../../../config/init';
import { useAuthState } from '../../../providers/authState';
import {
  PADDING,
  PADDING_HALF,
  PADDING_MORE,
} from '../../../providers/chakra/layout';
import { SUBTITLE_FONT } from '../../../providers/chakra/typography';
// import { useGlobalLoader } from '../../../providers/globalLoader';
import { useTranslation } from '../../../providers/i18next';
import {
  RETURN_LANGUAGE_KEY,
  RETURN_SIGNIN_KEY,
  useReturnValue,
} from '../../../providers/returnValue';
import { logError } from '../../../providers/rollbar';
import { FORGOT_PASSWORD_ROUTE } from '../../../routes/constants';
import { SupportEmailLink } from '../../../utils/support';
import { InternalLink } from '../../Links';
import { FormButton, FormInput, useFormStateHook } from './index';

const StyledSupportEmail = () => (
  <SupportEmailLink
    {...{
      color: 'whiteAlpha.900',
      _hover: {
        color: 'gray.800',
      },
      textDecoration: 'underline',
      fontWeight: 'normal',
    }}
  />
);

const BaseSigninForm = ({
  showForgotPasswordLink = true,
  onSigninComplete = () => {},
}) => {
  const { txn, languageKey, Trans } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    markError,
    isDisabled,
    watch,
  } = useFormStateHook();
  const { signInWithEmailAndPassword } = useAuthState();
  const { extendReturnValue, formDefaults } = useReturnValue();
  // const { activateLoader } = useGlobalLoader();
  const wrongPasswordCount = useRef(0);
  const emailOriginal = watch('email'); // will keep capitalization until the submit form

  useEffect(() => {
    // reset counter for new email
    wrongPasswordCount.current = 0;
  }, [emailOriginal]);

  const onSubmit = async ({ email, password }) => {
    try {
      const emailParsed = email.toLocaleLowerCase();
      // activateLoader();
      const signinData = {
        [RETURN_SIGNIN_KEY]: true,
        [RETURN_LANGUAGE_KEY]: languageKey,
      };
      const defaultError = new Error('Failed to sign in');
      const signinResult = await signInWithEmailAndPassword(
        emailParsed,
        password,
        signinData
      );

      if (signinResult?.errorCode) {
        switch (signinResult.errorCode) {
          case 'emailInvalid':
            markError(txn('This email address is invalid.'), 'email');
            return;
          case 'email':
            markError(
              <>
                <Text>
                  {txn("We can't find an account associated with this email.")}
                </Text>
                <Trans i18nKey="Please try using a different email or contact support at {{supportEmail}}.">
                  Please try using a different email or contact support at{' '}
                  <StyledSupportEmail />.
                </Trans>
              </>,
              'email'
            );
            return;
          case 'password':
            // maybe show a toast if too many
            await httpsCallable(
              getFunctions(),
              'authCallables-lockIP'
            )({ email: emailParsed });

            wrongPasswordCount.current += 1;
            if (
              wrongPasswordCount.current < appConstant.maxWrongPasswordCount
            ) {
              markError(
                txn('The password entered is incorrect.') +
                  ' ' +
                  txn(
                    'Please attempt using a different password or reset your password.'
                  ),
                'password'
              );
            } else {
              markError(txn('The password entered is incorrect.'), 'password'); // A shorter error on the input element
              markError(
                {
                  title: txn(
                    'You have submitted too many incorrect passwords.'
                  ),
                  description: (
                    <Trans i18nKey="Please try again later or contact support at {{supportEmail}}.">
                      Please try again later or contact support at{' '}
                      <StyledSupportEmail />.
                    </Trans>
                  ),
                },
                'toast'
              );
            }
            return;
          case 'tooMany':
            markError(
              {
                title: (
                  <>
                    <Text>
                      {txn(
                        'You have exceeded the maximum number of login attempts.'
                      )}
                    </Text>
                    <Text>
                      {txn(
                        'As a result, your account has been temporarily locked.'
                      )}
                    </Text>
                  </>
                ),
                description: (
                  <Trans i18nKey="Please try again later or reach out to our support team at {{supportEmail}} for further assistance.">
                    Please try again later or reach out to our support team at{' '}
                    <StyledSupportEmail /> for further assistance.
                  </Trans>
                ),
              },
              'toast'
            );
            return;
          default:
            throw defaultError; // caught below
        }
      } else if (!signinResult) {
        throw defaultError; // caught below
      } else {
        // success
        // this is temporarily paused until we can figure out how to avoid rate limits
        // try {
        //   trackSigninEngagementEvent(email);
        // } catch (error) {
        //   // swallow the error
        // }
        extendReturnValue(signinData);
        onSigninComplete();
      }
    } catch (err) {
      logError(`SignInForm Error: ${err.message}`, err);
      markError(txn("We can't sign into your account"), 'toast');
    }
  };

  return (
    <form
      disabled={isDisabled}
      onSubmit={(e) => {
        // WARNING! do not remove this ever
        // https://stackoverflow.com/a/39010163
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Box w="100%" paddingTop={PADDING} marginBottom={PADDING_HALF}>
        <FormInput
          label={txn('Email')}
          placeholder={txn('Email')}
          type="email"
          autoComplete="email"
          disabled={isDisabled}
          defaultValue={formDefaults?.email}
          error={errors.email}
          {...register('email', {
            required: txn('Email is required'),
          })}
          style={{
            textTransform: emailOriginal ? 'lowercase' : 'none',
          }}
          data-testid={dataTestIds.signinForm.username}
        />
        <FormInput
          label={txn('Password')}
          placeholder={txn('Password')}
          type="password"
          autoComplete="password"
          disabled={isDisabled}
          error={errors.password}
          {...register('password', {
            required: txn('Password is required'),
          })}
          data-testid={dataTestIds.signinForm.password}
        />
      </Box>
      {showForgotPasswordLink && (
        <Box
          align="right"
          fontSize={SUBTITLE_FONT}
          flex="1"
          verticalAlign="middle"
        >
          <InternalLink
            to={FORGOT_PASSWORD_ROUTE}
            disabled={isDisabled}
            data-testid={dataTestIds.signinForm.forgotPwdLink}
          >
            {txn('Forgot your password?')}
          </InternalLink>
        </Box>
      )}
      <Box marginTop={PADDING_MORE}>
        <FormButton
          type="submit"
          isLoading={isDisabled}
          disabled={isDisabled}
          data-testid={dataTestIds.signinForm.loginBtn}
        >
          {txn('Log in')}
        </FormButton>
      </Box>
    </form>
  );
};

export default BaseSigninForm;
