import SignupFormV2 from '../components/Forms/v2/SignupForm';
import { useTranslation } from '../providers/i18next';
import PageBaseV2 from './PageLayout/PageBaseV2';

const SignupPage = () => {
  const { txn } = useTranslation();
  return (
    <PageBaseV2 titleBase={txn('Sign Up')}>
      <SignupFormV2 />
    </PageBaseV2>
  );
};

export default SignupPage;
