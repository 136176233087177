import { Global } from '@emotion/react';

import { getCurrentEnv, isDev, isLocal, isProd } from '../env';

/**
 * Based on the environment detected in the env config,
 * attempt to load font files. If production, prefer cloudfront hosted files.
 * If local, use local files that helps with console error messages.
 * :: TODO :: Some specific font styles still need to be pushed to cloudfront.
 * @returns {ReactElement} global stylesheet for fonts
 */
const Fonts = () => {
  const env = getCurrentEnv();
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'Moderat';
        font-style: normal;
        font-weight: 300;
        src: 
          ${
            isProd(env) || isDev(env)
              ? `url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-light.woff2')
                  format('woff2'),
                url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-light.woff')
                  format('woff')`
              : `local('Moderat Light'), local('Moderat-Light'),
                  url('/fonts/moderat/Moderat-Light.woff2') format('woff2'),
                  url('/fonts/moderat/Moderat-Light.woff') format('woff')`
          }
        ;
      }
      
      @font-face {
        font-family: 'Moderat';
        font-style: normal;
        font-weight: 400;
        src: 
          ${
            isProd(env) || isDev(env)
              ? `url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular.woff2')
                  format('woff2'),
                url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular.woff')
                  format('woff')`
              : `local('Moderat Regular'), local('Moderat-Regular'),
                  url('/fonts/moderat/Moderat-Regular.woff2') format('woff2'),
                  url('/fonts/moderat/Moderat-Regular.woff') format('woff')`
          }
        ;
      }

      ${
        isLocal(env)
          ? `@font-face {
              font-family: 'Moderat';
              font-style: normal;
              font-weight: 500;
              src: 
                local('Moderat Medium'), local('Moderat-Medium'),
                url('/fonts/moderat/Moderat-Medium.woff2') format('woff2'),
                url('/fonts/moderat/Moderat-Medium.woff') format('woff')
              ;
            }`
          : ``
      }
      
      @font-face {
        font-family: 'Moderat';
        font-style: italic;
        font-weight: 400;
        src: 
          ${
            isProd(env) || isDev(env)
              ? `url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular-italic.woff2')
                  format('woff2'),
                url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular-italic.woff')
                  format('woff')`
              : `local('Moderat Regular Italic'), local('Moderat-Regular-Italic'),
                  url('/fonts/moderat/Moderat-Regular-Italic.woff2') format('woff2'),
                  url('/fonts/moderat/Moderat-Regular-Italic.woff') format('woff')`
          }
        ;
      }
      
      @font-face {
        font-family: 'Moderat';
        font-style: normal;
        font-weight: 700;
        src: 
          ${
            isProd(env) || isDev(env)
              ? `url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-bold.woff2')
                  format('woff2'),
                url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-bold.woff')
                  format('woff')`
              : `local('Moderat Bold'), local('Moderat-Bold'),
                  url('/fonts/moderat/Moderat-Bold.woff2') format('woff2'),
                  url('/fonts/moderat/Moderat-Bold.woff') format('woff')`
          }  
        ;
      }

      ${
        isLocal(env)
          ? `@font-face {
              font-family: 'Moderat';
              font-style: normal;
              font-weight: 900;
              src: 
                local('Moderat Black'), local('Moderat-Black'),
                url('/fonts/moderat/Moderat-Black.woff2') format('woff2'),
                url('/fonts/moderat/Moderat-Black.woff') format('woff')
              ;
            }`
          : ``
      }
    `}
    />
  );
};
export default Fonts;
