import { functionsDomain } from '../../config/init';
import { logError } from '../../providers/rollbar';

/**
 * ### Async / Promise
 * Helper method to make a generic request to functions backend and return json
 * @param {string} endpoint endpoint to use
 * @param {Object} body body payload
 * @returns {Promise<Object | undefined>} json
 */
export const makeFunctionsRequest = async (endpoint, body) => {
  const url = functionsDomain + endpoint;
  const config = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include', // includes cookies
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  try {
    const response = await fetch(url, config);
    const text = await response.text();
    try {
      // if parseable, return json
      return JSON.parse(text);
    } catch {
      // otherwise return plain text
      return text;
    }
  } catch (err) {
    logError(`Error making functions request: ${err.message}`, err, {
      endpoint,
      err,
      body,
    });
    return; // signals an error occurred
  }
};

/**
 * ### Async / Promise
 * Helper method to make a user-authenticated request to functions backend and return json
 * @param {string} endpoint endpoint to use
 * @param {string} idToken idToken
 * @returns {Promise<Object | undefined>} json
 */
const makeUserVerifiedRequest = async (endpoint, idToken) => {
  return makeFunctionsRequest(endpoint, { idToken });
};

// ------------------------------------------------- //

/**
 * ### Async / Promise
 * given email, actionType, and payload
 * tries to find a user id (via admin sdk) so that the return payload can be saved
 * into the actionReturn document for that type,
 * returns a json payload of saved data.
 * @param {string} email user email
 * @param {ACTION_TYPES} actionType
 * @param {Object} payload data to save
 * @returns {Promise<Object | undefined>} json
 * undefined if error
 */
export const saveGuestReturnData = async (email, actionType, payload) => {
  return makeFunctionsRequest('/auth-saveActionReturnByEmail', {
    email,
    actionType,
    payload,
  });
};

/**
 * ### Async / Promise
 * Exchanges the signed-in user idToken for a custom token jwt
 * that can be used to authenticate other firebase clients.
 * This is returned to the client app as part of the signin flow.
 * @param {Object} idToken json
 * @returns {Promise<{ jwt: string } | undefined>} custom token jwt
 * undefined if error
 */
export const getCustomToken = async (idToken) => {
  return makeUserVerifiedRequest('/auth-getCustomToken', idToken);
};

/**
 * ### Async / Promise
 * Attempts to stop all active sessions. Returns both the updated sessions
 * and the ignored (inactive) sessions.
 * This will allow the user to sign out of all devices.
 * @param {Object} idToken json
 * @returns {Promise<{ updatedSessions: Object[], inactiveSessions: Object[] } | undefined>}
 * undefined if error
 */
export const stopAllSessions = async (idToken) => {
  return makeUserVerifiedRequest('/auth-stopAllSessions', idToken);
};
