import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import { useAuthState } from '../../../providers/authState';
import { PADDING, PADDING_MORE } from '../../../providers/chakra/layout';
import { useTranslation } from '../../../providers/i18next';
import { logError } from '../../../providers/rollbar';
import { supportEmail } from '../../../utils/support';
import LoadingSpinner from '../../LoadingSpinner';
import {
  FormButton,
  FormInput,
  useFormStateHook,
  useVerificationHook,
} from './index';

export const MIN_PASS_LENGTH = 8;

const BaseSetPasswordForm = ({
  actionCode,
  FormMessage,
  SuccessMessage,
  ErrorMessage,
  submitText,
}) => {
  const { txn } = useTranslation();
  const { isAuthenticated, confirmPasswordReset, fullSignOut } = useAuthState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    markError,
    isDisabled,
    isFinished,
    setFinished,
  } = useFormStateHook();
  const password = useRef({});
  password.current = watch('password', '');
  const useApply = false;
  const { isVerifyLoading, isVerifyError, isVerified, verifyError } =
    useVerificationHook(actionCode, useApply);
  const isLoading = isVerifyLoading;
  const isError = isVerifyError;
  const isSuccess = isVerified && isFinished;
  const isForm = isVerified && !isFinished;

  useEffect(() => {
    if (verifyError) {
      markError(verifyError, 'toast');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyError]);

  const onSubmit = async ({ password }) => {
    try {
      const defaultError = new Error('Reset operation failed');
      const result = await confirmPasswordReset(actionCode, password);
      if (result?.errorCode) {
        switch (result.errorCode) {
          case 'actionCode':
            markError(
              txn('Your link has expired. Please generate a new one.'),
              'toast'
            );
            return;
          case 'user':
            markError(
              txn(
                "There's an issue with your account. Please contact {{supportEmail}}.",
                { supportEmail }
              ),
              'toast'
            );
            return;
          case 'password':
            markError(
              txn('This password is not strong enough. Please try another.'),
              'password'
            );
            return;
          default:
            throw defaultError; // caught below
        }
      } else if (result) {
        setFinished(true);

        // this is where we should load the actionReturn data for password reset
        // BUT we don't have a user. So we make sure to check it on sign in reset.
      } else {
        throw defaultError; // caught below
      }
    } catch (err) {
      logError(`BaseSetPasswordForm: ${err.message}`, err);
      markError(txn('We could not process your request.'), 'toast');
    }
  };

  const showResetForm = () => {
    return (
      <>
        <FormMessage />
        <form
          onSubmit={(e) => {
            handleSubmit(onSubmit)(e);
          }}
        >
          <Box w="100%" marginTop={PADDING}>
            <FormInput
              label={txn('Password')}
              placeholder={txn('Password')}
              type="password"
              autoComplete="new-password"
              disabled={isDisabled}
              error={errors.password}
              {...register('password', {
                required: txn('Password is required'),
                minLength: {
                  value: MIN_PASS_LENGTH,
                  message: txn(
                    'Password must have at least {{minLength}} characters',
                    { minLength: MIN_PASS_LENGTH }
                  ),
                },
              })}
            />
            <FormInput
              placeholder={txn('Re-enter Password')}
              type="password"
              autoComplete="new-password"
              disabled={isDisabled}
              error={errors.confirmPassword}
              {...register('confirmPassword', {
                required: txn('Confirm your password'),
                validate: {
                  match: (v) =>
                    v === password.current || txn('Passwords do not match'),
                },
              })}
            />
          </Box>
          <Box marginTop={PADDING_MORE}>
            <FormButton type="submit" isLoading={isDisabled}>
              {submitText || txn('Set my password')}
            </FormButton>
          </Box>
        </form>
      </>
    );
  };

  if (isLoading) {
    // need to check action code
    return <LoadingSpinner />;
  } else if (isForm) {
    // action code good, can proceed
    return showResetForm();
  } else if (isError) {
    // action code failed
    return <ErrorMessage />;
  } else if (isSuccess) {
    // password reset completed
    return (
      <SuccessMessage
        onComplete={async () => {
          if (isAuthenticated) {
            await fullSignOut();
          }
        }}
      />
    );
  } else {
    // Unexpected error
    return <ErrorMessage />;
  }
};

BaseSetPasswordForm.propTypes = {
  actionCode: PropTypes.string.isRequired, // oob param from url
  FormMessage: PropTypes.elementType.isRequired, // Heading for Reset Form
  SuccessMessage: PropTypes.elementType.isRequired, // Full page success message component
  ErrorMessage: PropTypes.elementType.isRequired, // Full page error message component
  submitText: PropTypes.string, // optional text to show in reset form submit button
};

export default BaseSetPasswordForm;
