import { Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useColorScheme } from '../../providers/chakra/colors';

/**
 * A basic link with a click handler
 */
const BaseLink = ({
  children /** @type {React.Children} */,
  text /** @type {string} text to display, overridden by children */,
  onClick /** @type {() => {}} internal route */,
  ...props
}) => {
  const linkColors = useColorScheme('makeLinkColors');
  const combinedProps = {
    ...linkColors,
    fontWeight: 'medium',
    ...props,
  };
  return (
    <Link {...combinedProps} onClick={onClick}>
      {children ? children : text}
    </Link>
  );
};

BaseLink.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default BaseLink;
