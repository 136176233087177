import { Route, Routes } from 'react-router-dom';

import {
  ActionPage,
  ErrorPage,
  FeatureFlagsPage,
  ForgotPasswordPage,
  InvitationSignupPage,
  InvitationSignupPageV2,
  InvitationStatusPage,
  InvitationStatusPageV2,
  ResetPasswordPage,
  ReturnCompletePage,
  ReturnPage,
  SamlIdpPage,
  SigninPage,
  SigninResetPage,
  SignoutCompletePage,
  SignoutPage,
  SignupPage,
  SignupPageV2,
  VerifyPage,
} from '../pages';
import CustomTokenLoginPage from '../pages/CustomTokenLoginPage';
import { useFeatureFlags } from '../providers/featureFlags';
import {
  ACTION_ROUTE,
  CUSTOM_TOKEN_LOGIN_ROUTE,
  ERROR_ROUTE,
  FEATURE_FLAGS_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  INVITATION_SIGNUP_ROUTE,
  INVITATION_STATUS_ROUTE,
  OLD_INVITATION_SIGNUP_ROUTE,
  // TODO: delete this
  OLD_INVITATION_STATUS_ROUTE,
  OLD_SIGNUP_ROUTE,
  OLD_SIGNUP_ROUTE_WITH_HASH,
  RESET_PASSWORD_ROUTE,
  RETURN_COMPLETE_ROUTE,
  RETURN_ROUTE,
  // TDOO: delete this
  RETURN_ROUTE_V2,
  SAML_IDP_ROUTE,
  SIGNIN_RESET_ROUTE,
  SIGNIN_ROUTE,
  SIGNOUT_COMPLETE_ROUTE,
  SIGNOUT_ROUTE,
  SIGNUP_ROUTE,
  VERIFY_ROUTE,
} from './constants';
import DefaultRoute from './DefaultRoute';
import GuestRoute from './GuestRoute';
import LoadingRoute from './LoadingRoute';
// TODO: delete this
import LoadingRouteV2 from './LoadingRouteV2';
import UserRoute from './UserRoute';

function AppRoutes() {
  const { FeatureFlagsUIFlag } = useFeatureFlags();

  return (
    <Routes>
      {/* Guest Routes */}
      <Route
        // "/signin"
        path={SIGNIN_ROUTE}
        element={
          <GuestRoute>
            <SigninPage />
          </GuestRoute>
        }
      />
      <Route
        // "/signin-reset"
        path={SIGNIN_RESET_ROUTE}
        element={
          <GuestRoute>
            <SigninResetPage />
          </GuestRoute>
        }
      />
      <Route
        // "/old/signup"
        path={OLD_SIGNUP_ROUTE}
        element={
          <GuestRoute>
            <SignupPage />
          </GuestRoute>
        }
      />
      <Route
        // "/signup"
        path={SIGNUP_ROUTE}
        element={
          <GuestRoute bypassLoadingWrapper>
            <SignupPageV2 />
          </GuestRoute>
        }
      />
      <Route
        // "/old/signup/:signupHash"
        path={OLD_SIGNUP_ROUTE_WITH_HASH}
        element={
          <GuestRoute>
            <SignupPage />
          </GuestRoute>
        }
      />
      <Route
        // "/forgot"
        path={FORGOT_PASSWORD_ROUTE}
        element={
          <GuestRoute>
            <ForgotPasswordPage />
          </GuestRoute>
        }
      />
      <Route
        // "/old/invitation/:invitationHash"
        path={OLD_INVITATION_SIGNUP_ROUTE}
        element={
          <GuestRoute>
            <InvitationSignupPage />
          </GuestRoute>
        }
      />
      <Route
        // "/invitation/:invitationHash"
        path={INVITATION_SIGNUP_ROUTE}
        element={
          <GuestRoute bypassLoadingWrapper>
            <InvitationSignupPageV2 />
          </GuestRoute>
        }
      />
      <Route
        // "/custom-token-login"
        path={CUSTOM_TOKEN_LOGIN_ROUTE}
        element={
          <GuestRoute>
            <CustomTokenLoginPage />
          </GuestRoute>
        }
      />

      {/* Authenticated Routes - default guestRedirect={SIGNOUT_COMPLETE_ROUTE} */}
      <Route
        // "/invitation-status"
        path={INVITATION_STATUS_ROUTE}
        element={
          <UserRoute>
            <InvitationStatusPageV2 />
          </UserRoute>
        }
      />

      {/* TODO: delete me */}
      <Route
        // "/old/invitation-status"
        path={OLD_INVITATION_STATUS_ROUTE}
        element={
          <UserRoute>
            <InvitationStatusPage />
          </UserRoute>
        }
      />

      <Route
        // "/verify"
        path={VERIFY_ROUTE}
        element={
          <UserRoute>
            <VerifyPage />
          </UserRoute>
        }
      />

      {/* SAML Routes */}
      <Route
        // "/samlidp-redirect"
        path={SAML_IDP_ROUTE}
        element={
          <UserRoute>
            <SamlIdpPage />
          </UserRoute>
        }
      />

      {/* Misc Routes - Non-standard behavior */}
      <Route
        // "/return"
        path={RETURN_ROUTE}
        element={
          <LoadingRoute>
            <ReturnPage />
          </LoadingRoute>
        }
      />
      {/* TODO: rename the below route to RETURN_ROUTE and mark the above RETURN_ROUTE_OLD */}
      <Route
        // "/v2/return"
        path={RETURN_ROUTE_V2}
        element={
          <LoadingRouteV2 loadingText="One moment while we get everything ready">
            <ReturnPage />
          </LoadingRouteV2>
        }
      />
      <Route
        // "/return-complete"
        path={RETURN_COMPLETE_ROUTE}
        element={
          <LoadingRoute>
            <ReturnCompletePage />
          </LoadingRoute>
        }
      />
      <Route
        // "/signout"
        path={SIGNOUT_ROUTE}
        element={
          <LoadingRoute>
            <SignoutPage />
          </LoadingRoute>
        }
      />
      <Route
        // "/signout-complete"
        path={SIGNOUT_COMPLETE_ROUTE}
        element={
          <LoadingRoute>
            <SignoutCompletePage />
          </LoadingRoute>
        }
      />
      <Route
        // "/reset-password"
        path={RESET_PASSWORD_ROUTE}
        element={
          <LoadingRoute>
            <ResetPasswordPage />
          </LoadingRoute>
        }
      />
      <Route
        // "/action"
        path={ACTION_ROUTE}
        element={
          <LoadingRoute>
            <ActionPage />
          </LoadingRoute>
        }
      />
      <Route
        // "/error"
        path={ERROR_ROUTE}
        element={<ErrorPage />}
      />
      {FeatureFlagsUIFlag.enabled && (
        <Route
          // "/feature-flags"
          path={FEATURE_FLAGS_ROUTE}
          element={<FeatureFlagsPage />}
        />
      )}

      {/* Default for Not Found */}
      <Route
        path="/*"
        element={
          <DefaultRoute
            authenticatedRedirect={RETURN_ROUTE}
            guestRedirect={SIGNIN_ROUTE}
          />
        }
      />
    </Routes>
  );
}

export default AppRoutes;
