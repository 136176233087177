import { useEffect, useState } from 'react';

import { ErrorPage, LoadingPage } from '../pages';
import { useAuthState } from '../providers/authState';
import { useGlobalLoader } from '../providers/globalLoader';

const TIMEOUT = 20000;
/**
 * Extends a ReactRouter Route component with a few checks.
 *  - if there has been some sort of initialization error with the auth subsystems,
 *    display a takeover Error page
 *  - if the auth subsystems are still being initialized,
 *    display a takeover Loading page
 *  - otherwise, should the component intended for this route
 * @returns {React.Component}
 */
const LoadingRoute = ({ children }) => {
  const { isLoading, isFailed, isReady, isUserLoading, authError } =
    useAuthState();
  const [isTimeout, setIsTimeout] = useState(false);
  const isFinished = !isLoading && isReady && !isUserLoading;
  const isError = (isFailed && authError) || isTimeout;
  const errorToShow = isTimeout ? new Error('Timeout') : authError;
  const { isSkipLoadingTimeout } = useGlobalLoader();

  // taking too long, give up and show error
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isFinished && !isSkipLoadingTimeout()) {
        setIsTimeout(true);
      }
    }, TIMEOUT);
    return () => clearTimeout(timer);
  });

  if (isError) {
    // something went wrong during initialization or first user lookup.
    return <ErrorPage error={errorToShow} />;
  } else if (isFinished) {
    // once ready, this will never be unready so this won't rerender
    // app is ready. show intended component.
    return children;
  } else {
    // waiting for firebase to initialize or perform first user lookup, show loading.
    return <LoadingPage />;
  }
};

export default LoadingRoute;
