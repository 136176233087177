import { Box, Button, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import SignupFormV2 from '../components/Forms/SignupForm';
import LoadingSpinner from '../components/LoadingSpinner';
import { useTranslation } from '../providers/i18next';
import { useInvitationPayload } from '../providers/invitations';
import { SIGNOUT_ROUTE } from '../routes/constants';
import PageBase from './PageLayout/PageBase';

const Validating = () => {
  const { txn } = useTranslation();
  return (
    <Center>
      <Stack>
        <LoadingSpinner />

        <Text>{txn('Validating invitation')}...</Text>
      </Stack>
    </Center>
  );
};

const InvalidInvitation = () => {
  const { txn } = useTranslation();

  return (
    <Box>
      <Heading mb={4}>
        {txn('It looks like this is an invalid invitation.')}
      </Heading>

      <Text mb={10}>{txn('Please click the button below to sign in.')}</Text>

      <Button
        variant="solid"
        colorScheme="blue"
        size="lg"
        as={NavLink}
        to={SIGNOUT_ROUTE}
        replace
      >
        {txn('Sign in')}
      </Button>
    </Box>
  );
};

const InvitationSignupPage = () => {
  const { txn } = useTranslation();
  const { invitationData, isValidating, validationError } =
    useInvitationPayload();

  const renderContent = () => {
    if (isValidating) {
      return <Validating />;
    }

    if (!invitationData?.id || validationError) return <InvalidInvitation />;

    return <SignupFormV2 invitation={invitationData} />;
  };

  return <PageBase titleBase={txn('Sign Up')}>{renderContent()}</PageBase>;
};

export default InvitationSignupPage;
