import { useEffect } from 'react';

import LoadingSpinner from '../components/LoadingSpinner';
import { useToast } from '../providers/chakra';
import { useGlobalLoader } from '../providers/globalLoader';
import { useTranslation } from '../providers/i18next';
import ErrorPage from './ErrorPage';
import PageBase from './PageLayout/PageBase';

/**
 * A loading page that can handle async state transitions and errors
 * Accepts a title that's assumed to be translated. The default `Wonderschool` does not need translation.
 * If this is a whitelabled aka branded site, show a translated Loading page title.
 * You can pass in a custom timeout interval and any errors you wish to be rendered.
 * If an error is set in the props, it is used and the error message is rendered.
 * If the timeout is reached, the optional callback is fired and there will be a toast warning about a delayed response.
 * The timer will be cleared on unmount.
 * Otherwise, the page will show a basic page and either a spinner or a react children if provided
 */
const LoadingPage = ({
  titleBase = '', // should be translated already
  timeout = 10000, // set to 0 to ignore
  error = null,
  onTimeout = () => {}, // callback function
  children = null,
}) => {
  const { txn } = useTranslation();
  const { disableLoader } = useGlobalLoader();
  const toast = useToast();

  useEffect(
    () => {
      if (timeout > 0) {
        // start a timer for fallback
        const timer = setTimeout(() => {
          // if timed out, something unknown went wrong
          onTimeout();
          disableLoader();
          toast({
            title: txn('Unexpected delay'),
            duration: null, // manual close only
            description: txn(
              'This is taking a little longer than expected. Please wait or refresh your page.'
            ),
            status: 'warning',
          });
        }, timeout);
        return () => {
          clearTimeout(timer);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // mount/unmount only
  );
  return error ? (
    <ErrorPage error={error} />
  ) : (
    <PageBase titleBase={titleBase || txn('Loading')}>
      {children || <LoadingSpinner />}
    </PageBase>
  );
};

export default LoadingPage;
