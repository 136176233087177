import { withAuthState } from './authState';
import { withChakra } from './chakra';
import { withCookies } from './cookies';
import { withEnv } from './env';
import { withFeatureFlags } from './featureFlags';
import { withGlobalLoader } from './globalLoader';
import { withHelmet } from './helmet';
import { withI18Next } from './i18next';
import { withLaunchDarkly } from './launchDarkly';
import { LaunchDarklyContextInitializer } from './launchDarkly/LaunchDarklyContextInitializer';
import { withReturnValue } from './returnValue';
import { withRollbar } from './rollbar';
import { withSuspense } from './suspense';

/**
 * Wraps a plain app component with all the middlewares
 * @param {React.Component} MainAppComponent
 * @returns React.Component
 */
const withMiddlewares = (MainAppComponent) =>
  function WrappedWithMiddlewares(props) {
    // order matters - applied in reverse order so first element is outer layer
    const middlewares = [
      withEnv,
      withHelmet,
      withCookies,
      withSuspense,
      withI18Next,
      withChakra,
      withRollbar,
      withFeatureFlags,
      withGlobalLoader,
      withReturnValue,
      withLaunchDarkly,
      withAuthState,
    ];

    const WrappedMainApp = [...middlewares]
      .reverse() // ensure ordering
      .reduce(
        (WrappedApp, withFn) => {
          return withFn(WrappedApp);
        },
        () => (
          <MainAppComponent {...props}>
            <LaunchDarklyContextInitializer />
          </MainAppComponent>
        )
      );

    return <WrappedMainApp />;
  };

export { withMiddlewares };
