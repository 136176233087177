import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { PADDING_BIG } from '../../../providers/chakra/layout';
import { SMALLER_FONT } from '../../../providers/chakra/typography';

/**
 * Container to put at the bottom of a form.
 * Designed to help with vertical alignment.
 * Pairs with FormHeading.
 */
const FormFooter = ({ children /** @type {React.Children} */ }) => {
  return (
    <Box align="left" marginTop={PADDING_BIG} fontSize={SMALLER_FONT}>
      <Text>{children}</Text>
    </Box>
  );
};

FormFooter.propTypes = {
  children: PropTypes.any,
};

export default FormFooter;
