import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { getSessionRef } from '../../api/firebase/session';
import { trackNewSession } from '../rollbar';

/**
 * Takes optional session identifiers and returns the latest state if possible.
 * Returns null for session if not present
 * @param {string} maybeUserId
 * @param {string} maybeSessionId
 */
const useAuthSession = (maybeUserId, maybeSessionId) => {
  /**
   * This is a little tricky.
   *
   * 1) eventually both user and session ids are passed
   * 2) That creates a non-null docRef which is used in the useDocumentData hook
   * 3) when that is created, isLoading is initially false
   * 4) Then isLoading is set to true by the hook when loading begins.
   * 5) That triggers the effect that sets didOnce to true.
   * 6) When isLoading is then moved back to false at loading completiong AND didOnce has been flipped to true
   *    we can check the authSession value. If it exists, everything is good. If it is missing, the session is invalid.
   * 7) When the user/session is eventually lost, we reset to original conditions.
   */
  const docRef = getSessionRef(maybeUserId, maybeSessionId);
  const [authSession, isLoading, sessionError] = useDocumentData(docRef);
  const [didOnce, setDidOnce] = useState(false);

  const isFailed = !!sessionError;
  const hasUserAndSession = maybeUserId && maybeSessionId;
  const isReadyWithUser = hasUserAndSession && didOnce && !isLoading;
  const isReadyNoUser = !hasUserAndSession;
  const isReady = isReadyNoUser || isReadyWithUser;
  const hasSession = !!authSession;
  const isInvalidSession =
    (isReadyWithUser && !hasSession) || (hasUserAndSession && isFailed);

  // when loading begins, set the flag
  useEffect(() => {
    // isLoading should be true because
    if (hasUserAndSession && isLoading && !didOnce) {
      setDidOnce(true); // must try to load at least once for it to be ready, otherwise, uncertain if the undefined state is missing or waiting session
    }
  }, [didOnce, isLoading, hasUserAndSession]);

  // reset when user/session is lost
  useEffect(() => {
    if (!hasUserAndSession) {
      setDidOnce(false);
    }
  }, [hasUserAndSession]);

  useEffect(() => {
    trackNewSession(authSession);
  }, [authSession]);

  return {
    isLoading, // whether firebase is initialized and the session listener is setup (regardless of if a session is found)
    isFailed, // whether there was an error trying to load the session
    isReady, // whether initialization has completed
    hasSession, // whether a session has been found
    authSession, // the auth session. `null` if not present, `undefined` if not yet loaded
    sessionError, // the error if it exists, undefined otherwise
    isInvalidSession, // the session id did not produce a valid session. needs to be cleared and reset
  };
};

export default useAuthSession;
