import React, { forwardRef, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import { useTranslation } from '../../../../providers/i18next';

interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  error?: FieldError;
  placeholder?: string;
  'data-testid': string;
  children: ReactNode | undefined;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { disabled = false, 'data-testid': testId, error, children, ...props },
    ref
  ) => {
    const { txn } = useTranslation();

    // styles for the text label
    const textStyles = disabled ? 'text-gray-400' : 'text-gray-800';

    // label wrapper styles
    const baseLabelStyles =
      'inline-flex flex-row justify-start gap-0 items-center';
    const cursorStyles = disabled ? 'cursor-default' : 'cursor-pointer';

    // actual checkbox label styles
    const baseInputStyles = 'h-6 w-6 cursor-pointer rounded';
    const errorCheckboxStyles =
      'text-red-900 border-red-300 placeholder:text-red-300';
    const cleanCheckboxStyles =
      'text-blue-900 border-gray-600 hover:border-blue-900 checked:border-blue-900 checked:bg-blue-900 focus:ring-1 focus:ring-blue-900 focus:outline-none placeholder:text-gray-400 disabled:border-gray-400 disabled:cursor-default disabled:bg-gray-400';
    const checkboxStyles = `${baseInputStyles} ${error ? errorCheckboxStyles : cleanCheckboxStyles}`;

    return (
      <div>
        <label className={`${cursorStyles} ${baseLabelStyles}`}>
          <input
            className={checkboxStyles}
            type="checkbox"
            ref={ref}
            {...props}
          />
          <span
            data-testid={`${testId}-label`}
            className={`pl-2 ${textStyles} text-sm`}
          >
            {children}
          </span>
        </label>
        {error?.message ? (
          <p className="mt-1 text-sm text-red-600">{txn(error.message)}</p>
        ) : null}
      </div>
    );
  }
);

// context here: https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/display-name.md
Checkbox.displayName = 'Checkbox';

export default Checkbox;
