import { FORM_FIELD_NAMES } from './types';

export const PLACEHOLDERS = {
  [FORM_FIELD_NAMES.FIRST_NAME]: 'First name',
  [FORM_FIELD_NAMES.LAST_NAME]: 'Last name',
  [FORM_FIELD_NAMES.EMAIL]: 'Email address',
  [FORM_FIELD_NAMES.PHONE]: 'Mobile phone',
  [FORM_FIELD_NAMES.PASSWORD]: 'Password',
  [FORM_FIELD_NAMES.PASSWORD_CONFIRM]: 'Re-enter password',
};
