import 'react-app-polyfill/stable';

import { Buffer } from 'buffer';
import fromEntries from 'object.fromentries';
import allSettled from 'promise.allsettled';

window.Buffer = window.Buffer || Buffer;

allSettled.shim(); // will be a no-op if not needed
fromEntries.shim(); // will be a no-op if not needed
