import { useEffect } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';

/**
 * Makes the Cookie context available with the useCookie hook
 * @param {React.Component} WrappedComponent
 */
const withCookies = (WrappedComponent) =>
  function WrappedWithCookies({ cookies, ...props }) {
    return (
      <CookiesProvider cookies={cookies}>
        <WrappedComponent {...props} />
      </CookiesProvider>
    );
  };

/**
 * provides [allCookies, setCookie, removeCookie]
 * also listens to cookie change events to trigger updates
 * https://github.com/reactivestack/cookies/tree/master/packages/react-cookie
 *
 * ALSO: overrides the interface to take a specific key name to mimic `./localStorage` interface
 *
 * standard usage:
 * ```
 * const [example, setExample, removeExample] = useCookie('example', 'optInitVal')
 *
 * setExample(value, cookieOptions)
 * removeExample(filterOptions)
 * ```
 * @param {string} key index name
 * @param {string} initVal only set if no pre-existing value
 * @param {string} initOptions standard cookie options
 * ```
 * path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
 * expires (Date): absolute expiration date for the cookie
 * maxAge (number): relative max age of the cookie from when the client receives it in seconds
 * domain (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
 * secure (boolean): Is only accessible through HTTPS?
 * httpOnly (boolean): Can only the server access the cookie? Note: You cannot get or set httpOnly cookies from the browser, only the server.
 * sameSite (boolean|none|lax|strict): Strict or Lax enforcement
 * ```
 * @returns [value, setValue], standard hook return value
 */
const useCookie = (key, initVal = undefined, initOptions = undefined) => {
  const [allCookies, setCookie, removeCookie] = useCookies([key]);

  useEffect(() => {
    // if cookie is not yet set, use initVal
    if (initVal && allCookies[key] == null) {
      setCookie(key, initVal, initOptions);
    }
  });

  return [
    allCookies[key],
    (value, options) => setCookie(key, value, options),
    (options) => removeCookie(key, options),
  ];
};

export { withCookies, useCookie };
