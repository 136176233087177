import {
  historyContext,
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
} from '@rollbar/react';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { isTest } from '../config/env';
import { ErrorPage } from '../pages';
import {
  isRollbarEnabled,
  logCritical,
  logError,
  logInfo,
  logLocal,
  logWarning,
  trackFlags,
  trackNewSession,
  trackUser,
  trackUserSignout,
} from './rollbar/api';
import { getRollbar } from './rollbar/config';

const localHistoryListener = (location, action) => {
  logLocal(`History Context Change (Rollbar): ${location.pathname}`, {
    location,
    action,
  });
};
const localErrorListener = (error, info) => {
  logLocal('Local Error Boundary (Rollbar):', { error, info });
};
export const withLocalErrorBoundary = (WrappedComponent) =>
  function WrappedWithLocalErrorBoundary({
    fallback = ErrorPage,
    errorListener = localErrorListener,
    ...props
  }) {
    return (
      <ReactErrorBoundary FallbackComponent={fallback} onError={errorListener}>
        <WrappedComponent {...props} />
      </ReactErrorBoundary>
    );
  };
const withRollbarErrorBoundary = (WrappedComponent) =>
  function WrappedWithRollbarErrorBoundary({ fallback = ErrorPage, ...props }) {
    return (
      <RollbarErrorBoundary fallbackUI={fallback}>
        <WrappedComponent {...props} />
      </RollbarErrorBoundary>
    );
  };

/**
 * Makes the Rollbar context available
 * @param {React.Component} WrappedComponent
 */
const rollbar = !isTest() ? getRollbar() : undefined;
const initHistory = () => {
  if (isTest()) {
    return undefined;
  } else if (!window.BrowserHistory) {
    window.BrowserHistory = window.BrowserHistory || createBrowserHistory();
  }
  return window.BrowserHistory;
};
const history = initHistory();
const withRollbar = (WrappedComponent) =>
  function WrappedWithRollbar(props) {
    useEffect(() => {
      if (history) {
        return history.listen(
          isRollbarEnabled() ? historyContext(rollbar) : localHistoryListener
        );
      }
    }, []);

    if (isRollbarEnabled()) {
      return (
        <>
          {/* Provider instantiates Rollbar client instance handling any uncaught
        errors or unhandled promises in the browser */}
          <RollbarProvider instance={rollbar}>
            {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
            {withRollbarErrorBoundary(WrappedComponent)(props)}
          </RollbarProvider>
        </>
      );
    } else {
      return withLocalErrorBoundary(WrappedComponent)(props);
    }
  };

export {
  withRollbar,
  logCritical,
  logError,
  logWarning,
  logInfo,
  trackUser,
  trackUserSignout,
  trackNewSession,
  trackFlags,
  isRollbarEnabled,
};
