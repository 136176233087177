export enum PROGRAM_STATUS_ENUMS {
  EXISTING = 'EXISTING_PROGRAM',
  NEW = 'NEW_PROGRAM',
}

export enum FORM_FIELD_NAMES {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  INVITATION_ID = 'invitationId',
  LAST_NAME = 'lastName',
  NETWORK_CODE = 'networkCode',
  NEW_SUPPLY = 'newSupply',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
  PHONE = 'phone',
  PROGRAM_STATUS = 'programStatus',
  PROGRAM_TYPE = 'programType',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  USER_TYPE = 'userType',
}

export interface UserCredentialsFormSchema {
  [FORM_FIELD_NAMES.EMAIL]: string;
  [FORM_FIELD_NAMES.PASSWORD]: string;
  [FORM_FIELD_NAMES.PASSWORD_CONFIRM]: string;
  [FORM_FIELD_NAMES.FIRST_NAME]: string;
  [FORM_FIELD_NAMES.LAST_NAME]: string;
  [FORM_FIELD_NAMES.EMAIL]: string;
  [FORM_FIELD_NAMES.PHONE]?: string;
  [FORM_FIELD_NAMES.NEW_SUPPLY]?: boolean;
  [FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]: boolean;
  [FORM_FIELD_NAMES.NETWORK_CODE]?: string;
  [FORM_FIELD_NAMES.INVITATION_ID]?: string;
}

export interface PasswordResetFormSchema {
  [FORM_FIELD_NAMES.EMAIL]: string;
  [FORM_FIELD_NAMES.PASSWORD]: string;
  [FORM_FIELD_NAMES.PASSWORD_CONFIRM]: string;
}

export type ExtendedError = Error & {
  errorCode?: string;
  msg?: string;
};
