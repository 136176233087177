import InputMask from 'comigo-tech-react-input-mask'; // https://github.com/sanniassin/react-input-mask/issues/239
import React, { forwardRef, HTMLInputTypeAttribute } from 'react';
import { FieldError } from 'react-hook-form';

import { useTranslation } from '../../../../providers/i18next';
import FormInput from './FormInput';
import FormSelect from './FormSelect';

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLSelectElement> {
  label: string;
  name: string;
  as?: typeof InputMask;
  mask?: string;
  error?: FieldError;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  defaultValue?: string;
  options?: {
    value: string;
    label: string;
    // The following 2 are used for the default value / dropdown placeholder
    defaultValue?: boolean;
    disabled?: boolean;
  }[];
}

const FormField = forwardRef<HTMLInputElement | HTMLSelectElement, Props>(
  (
    {
      label,
      name,
      error,
      placeholder,
      defaultValue = '',
      options = null,
      type = 'text',
      ...rest
    },
    ref
  ) => {
    const { txn } = useTranslation();

    return (
      <div className="space-y-3">
        <label
          className="block mb-2 text-sm font-normal text-gray-800"
          aria-label={txn(label ?? '')}
          htmlFor={name}
          {...(rest.hidden ? { hidden: true } : {})}
        >
          {txn(label ?? '')}
          {rest.required ? <span className="text-red-600">&nbsp;*</span> : null}
        </label>
        {/* if options present, then use the <select /> elem. else, use <input /> element */}
        {options ? (
          <FormSelect
            error={error}
            name={name}
            options={options}
            defaultValue={txn(defaultValue)}
            {...rest}
            ref={ref as React.ForwardedRef<HTMLSelectElement>}
          />
        ) : (
          <FormInput
            error={error}
            name={name}
            placeholder={txn(placeholder ?? '')}
            type={type}
            {...rest}
            ref={ref as React.ForwardedRef<HTMLInputElement>}
          />
        )}

        {error?.message ? (
          <p className="mt-1 text-sm text-red-600">{txn(error.message)}</p>
        ) : null}
      </div>
    );
  }
);

// context here: https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/display-name.md
FormField.displayName = 'FormField';

export default FormField;
