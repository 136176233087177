import { chakra } from '@chakra-ui/react';
import { createContext, useContext } from 'react';

import { InternalLink } from '../components/Links';
import { FEATURE_FLAGS_ROUTE } from '../routes/constants';
import { useColorScheme } from './chakra/colors';
import { INPUT_RADIUS, PADDING, PADDING_TINY } from './chakra/layout';
import { TINY_FONT } from './chakra/typography';
import {
  IS_DARK_MODE_ENABLED,
  IS_FEATURE_FLAGS_UI_ENABLED,
  IS_ROLLBAR_ENABLED,
  isRollbarFlagEnabled,
  useFeatureFlag,
} from './featureFlags/useFeatureFlag';
import { useTranslation } from './i18next';

const FeatureFlagContainer = chakra('div', {
  baseStyle: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    zIndex: '9999',
    display: 'flex',
    justifyContent: 'flexEnd',
    marginRight: PADDING,
  },
});

const FlagLink = ({ children, ...props }) => {
  const featureFlagColors = useColorScheme('makeFeatureFlagColors');
  const combinedProps = {
    ...props,
    ...featureFlagColors,
    borderBottomRadius: INPUT_RADIUS,
    paddingTop: 0,
    paddingX: PADDING_TINY,
    paddingBottom: '1',
    fontSize: TINY_FONT,
    fontWeight: 'bold',
    boxShadow: 'md',
  };
  return <InternalLink {...combinedProps}>{children}</InternalLink>;
};

const FeatureFlagLink = () => {
  const { txn } = useTranslation();
  const { FeatureFlagsUIFlag } = useFeatureFlags();

  if (!FeatureFlagsUIFlag.enabled) {
    return null;
  }
  return (
    <FeatureFlagContainer>
      <FlagLink to={FEATURE_FLAGS_ROUTE}>{txn('Feature Flags')}</FlagLink>
    </FeatureFlagContainer>
  );
};

const defaultFlagObject = {
  name: '',
  enabled: false,
  toggle: () => {},
};

const FeatureFlagsContext = createContext({
  FeatureFlagsUIFlag: { ...defaultFlagObject }, // whether to show flags ui
  RollbarFlag: { ...defaultFlagObject }, // whether to use rollbar logging
  DarkModeFlag: { ...defaultFlagObject }, // whether to allow a darkmode UI toggle,
});

const FeatureFlagsProvider = ({ children }) => {
  const featureFlagsUIFlag = useFeatureFlag(IS_FEATURE_FLAGS_UI_ENABLED);
  const rollbarFlag = useFeatureFlag(IS_ROLLBAR_ENABLED);
  const darkModeFlag = useFeatureFlag(IS_DARK_MODE_ENABLED);
  return (
    <FeatureFlagsContext.Provider
      value={{
        FeatureFlagsUIFlag: featureFlagsUIFlag,
        RollbarFlag: rollbarFlag,
        DarkModeFlag: darkModeFlag,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

/**
 * Adds a feature flag tab to the app UI if enabled
 * @param {React.Component} WrappedComponent
 */
const withFeatureFlags = (WrappedComponent) =>
  function WrappedWithFlags(props) {
    return (
      <FeatureFlagsProvider>
        <FeatureFlagLink />
        <WrappedComponent {...props} />
      </FeatureFlagsProvider>
    );
  };

const useFeatureFlags = () => useContext(FeatureFlagsContext);

export { withFeatureFlags, useFeatureFlags, isRollbarFlagEnabled };
