interface ExternalLinkProps {
  children: React.ReactNode;
  href: string;
  useNewTab?: boolean;
  ['data-testid']?: string;
}

const ExternalLinkV2 = ({
  children,
  href,
  useNewTab = true,
}: ExternalLinkProps) => {
  return (
    <a
      href={href}
      className="text-blue-900"
      {...(useNewTab ? { target: '_blank' } : {})}
    >
      {children}
    </a>
  );
};

export default ExternalLinkV2;
