import { useCallback } from 'react';

export enum WidgetSizeEnum {
  X_SMALL = 'x-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_LARGE = 'x-large',
}

export type SpinnerProps = {
  size?: WidgetSizeEnum;
  spinnerColor?: string;
  overlayColor?: string;
  hasOverlay?: boolean;
};

export function Spinner({
  size = WidgetSizeEnum.SMALL,
  spinnerColor = 'sky',
  overlayColor = 'white',
  hasOverlay = false,
}: SpinnerProps): JSX.Element {
  const spinnerClassName = `animate-spin ${getSizeClasses(
    size
  )} text-${spinnerColor}-900 z-20`;
  const overlayClassName = `absolute bg-${overlayColor} bg-opacity-80 z-10 h-full w-full flex items-center justify-center `;

  const SpinnerIcon = useCallback(() => {
    return (
      <svg
        className={spinnerClassName}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  }, [spinnerClassName]);

  if (hasOverlay) {
    return (
      <div className={overlayClassName}>
        <SpinnerIcon />
      </div>
    );
  } else {
    return <SpinnerIcon />;
  }
}
function getSizeClasses(size: WidgetSizeEnum): string {
  switch (size) {
    case WidgetSizeEnum.SMALL:
      return 'h-5 w-5';
    case WidgetSizeEnum.MEDIUM:
      return 'h-10 w-10';
    case WidgetSizeEnum.LARGE:
      return 'h-16 w-16';
    default:
      return 'h-5 w-5';
  }
}
export default Spinner;
