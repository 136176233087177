import React from 'react';

import Loader from '../components/LoaderV2';
import PageBaseV2 from './PageLayout/PageBaseV2';

interface Props {
  titleBase?: string; // should be translated already
  loadingText?: string; // should be translated already
  timeout?: number; // set to 0 to ignore
  error?: Error;
  onTimeout?: () => void; // callback function
  children?: typeof React.Children;
}
export default function LoadingPageV2({
  titleBase = '', // should be translated already
  loadingText = '', // should be translated already
  // timeout = 10000, // set to 0 to ignore
  // error = null,
  // onTimeout = () => {}, // callback function
  // children = null,
}: Props) {
  return (
    <PageBaseV2 titleBase={titleBase}>
      <Loader text={loadingText} />
    </PageBaseV2>
  );
}
