import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useToast } from '../../../providers/chakra';
import { useGlobalLoader } from '../../../providers/globalLoader';

const useFormStateHook = () => {
  const formObject = useForm();
  const {
    setError,
    formState: { isSubmitting },
  } = formObject;
  const toast = useToast();
  const { disableLoader } = useGlobalLoader();
  const [isFinished, setFinished] = useState(false);
  const isDisabled = isSubmitting || isFinished;

  return {
    ...formObject,
    isDisabled,
    toast,
    isFinished,
    setFinished,
    markError: (msg, type = 'toast') => {
      disableLoader();
      if (type === 'toast') {
        if (msg?.title) {
          toast({
            title: msg.title,
            description: msg.description ?? undefined,
            status: 'error',
          });
        } else {
          toast({
            title: msg,
            status: 'error',
          });
        }
      } else {
        setError(type, {
          type: 'manual',
          message: msg,
        });
      }
    },
  };
};
export default useFormStateHook;
