import React from 'react';
import { FieldError } from 'react-hook-form';

import { styles } from './styles';

interface FormSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  error?: FieldError;
  options: {
    value: string;
    label: string;
    // The following 2 are used for the default value / dropdown placeholder
    defaultValue?: boolean;
    disabled?: boolean;
  }[];
}

// with forwardRef, we can pass the ref to the component
const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  ({ error, disabled, options, ...rest }, ref) => {
    return (
      <select
        className={error ? styles.dropdownError : styles.dropdownClean}
        ref={ref}
        disabled={disabled}
        {...rest}
      >
        {options.map((opt) => (
          <option
            key={opt.value}
            value={opt.value}
            {...(opt.defaultValue ? { defaultValue: 'true' } : {})}
            {...(opt.defaultValue || opt.disabled ? { disabled: true } : {})}
          >
            {opt.label}
          </option>
        ))}
      </select>
    );
  }
);

// context here: https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/display-name.md
FormSelect.displayName = 'FormSelect';

export default FormSelect;
