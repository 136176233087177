import {
  useColorMode as useColorModeOriginal,
  useColorModeValue,
} from '@chakra-ui/react';

// light mode, dark mode
const SCHEME_NAMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

// specific colors for Wonderschool scheme
const WonderColors = {
  SoftBlack: '#333',
  BlackNavy: '#1e2542',
  WhiteBlue: '#fff',
  MiddleGray1: '#9a9a9a',
  BlueGray: '#287bbb',
  BrightBlue: '#5196cb',
  YellowHighlight: '#e8ce72',
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for background
 */
const makeBackgroundColors = (scheme) => {
  return {
    color: scheme.textMain,
    bg: scheme.bgMain,
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for form inputs
 */
const makeInputColors = (scheme) => {
  return {
    bg: scheme.inputBg,
    borderColor: scheme.inputBorder,
    _placeholder: {
      color: scheme.inputPlaceholder,
    },
    _focus: {
      color: scheme.inputAccent,
      borderColor: scheme.inputBorderAccent,
      _placeholder: { color: scheme.inputBorderAccent },
    },
    _hover: {
      borderColor: scheme.inputBorderAccent,
      _placeholder: {
        color: scheme.inputPlaceholderAccent,
      },
      _focus: {
        color: scheme.inputAccent,
        borderColor: scheme.inputBorderAccent,
        _placeholder: { color: scheme.inputBorderAccent },
      },
    },
    _autofill: {
      bg: scheme.inputBg + ' !important',
    },
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for anchor links
 */
const makeLinkColors = (scheme) => {
  return {
    color: scheme.link,
    _hover: { color: scheme.linkHover },
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for button inputs
 */
const makeButtonColors = (scheme) => {
  return {
    bg: scheme.btnPrimary,
    color: scheme.btnText,
    _hover: {
      bg: scheme.btnHover,
      color: scheme.btnHoverText,
    },
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for button inputs
 */
const makeSpinnerColors = (scheme) => {
  return {
    color: scheme.spinnerColor,
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} optKey lets you move the color from text color to background, etc
 * @returns CSS color styles for error elements
 */
const makeErrorColors = (scheme, optKey = 'color') => {
  return {
    [optKey]: scheme.invalidColor,
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} optKey lets you move the color from text color to background, etc
 * @returns CSS color styles for subtle, muted elements
 */
const makeSubtleColors = (scheme, optKey = 'color') => {
  return {
    [optKey]: scheme.textLight,
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for feature flag link
 */
const makeFeatureFlagColors = (scheme) => {
  return {
    bg: scheme.bgBright,
    color: scheme.textBright,
    _hover: {
      bg: scheme.bgBrightHover,
      color: scheme.textBright,
    },
  };
};

// ----------------------------------------------------------------
// utility to add style builders to individual themes. use `curryFunctions` to
// curry with a specific theme and bind to the theme itself.
const functions = {
  makeBackgroundColors,
  makeInputColors,
  makeLinkColors,
  makeButtonColors,
  makeSpinnerColors,
  makeErrorColors,
  makeSubtleColors,
  makeFeatureFlagColors,
};
const curryFunctions = (functions, scheme) => {
  const list = Object.entries(functions).map(([name, fn]) => {
    return [name, (...args) => fn(scheme, ...args)];
  });
  return list.reduce((prev, [name, fn]) => {
    return {
      ...prev,
      [name]: fn,
    };
  }, {});
};
// ----------------------------------------------------------------
// ****************************************************************
const LightScheme = {
  bgMain: 'gray.100',
  bgAccent: 'gray.50',
  bgBright: 'yellow.400',
  bgBrightHover: WonderColors.YellowHighlight,
  bgDir: 'tl',
  textMain: WonderColors.SoftBlack,
  textLight: 'gray.400',
  textBright: WonderColors.SoftBlack,
  inputBg: WonderColors.WhiteBlue,
  inputBorder: 'gray.400',
  inputText: WonderColors.MiddleGray1,
  inputPlaceholder: 'blackAlpha.400',
  inputAccent: 'black',
  inputBorderAccent: WonderColors.BlueGray,
  inputPlaceholderAccent: 'black',
  link: 'blue.500',
  linkHover: 'blue.700',
  btnPrimary: WonderColors.BlueGray,
  btnText: 'gray.100',
  btnHover: WonderColors.BrightBlue,
  btnHoverText: 'white',
  spinnerColor: WonderColors.BlueGray,
  invalidColor: 'red.500',
};
Object.assign(LightScheme, curryFunctions(functions, LightScheme));

// ****************************************************************
const DarkScheme = {
  bgMain: 'gray.800',
  bgAccent: WonderColors.BlackNavy,
  bgBright: 'teal.500',
  bgBrightHover: 'teal.400',
  bgDir: 'br',
  textMain: 'gray.100',
  textLight: 'whiteAlpha.500',
  textBright: 'blackAlpha.700',
  inputBg: WonderColors.BlackNavy,
  inputBorder: 'whiteAlpha.500',
  inputText: 'whiteAlpha.900',
  inputPlaceholder: 'whiteAlpha.300',
  inputAccent: 'blue.500',
  inputBorderAccent: 'whiteAlpha.600',
  inputPlaceholderAccent: 'whiteAlpha.400',
  link: 'blue.300',
  linkHover: 'blue.100',
  btnPrimary: 'blue.600',
  btnText: 'gray.100',
  btnHover: 'blue.500',
  btnHoverText: 'gray.200',
  spinnerColor: 'blue.500',
  invalidColor: 'red.300',
};
Object.assign(DarkScheme, curryFunctions(functions, DarkScheme));
// ****************************************************************
const ColorSchemes = { Light: LightScheme, Dark: DarkScheme };
// ****************************************************************

/**
 * given the color function, determines whether to use light or dark mode, and returns the css styles
 * @example useColorScheme('makeButtonColors');
 * @param {string} schemeFnName name of function that generates the styles. one of the listed ones above.
 * @param  {...any} args additional args like `optKey`
 * @returns
 */
const useColorScheme = (schemeFnName, ...args) => {
  return useColorModeValue(
    LightScheme[schemeFnName](...args),
    DarkScheme[schemeFnName](...args)
  );
};

/**
 * use for figuring out which color mode/scheme is being used, light or dark.
 * @returns same as useColorMode (from Chakra) and adds isDark/isLight helpers
 */
const useColorMode = () => {
  const colorModeObj = useColorModeOriginal();
  return {
    ...colorModeObj,
    /**
     * @returns {boolean}
     */
    isDark: colorModeObj.colorMode === SCHEME_NAMES.DARK,
    /**
     * @returns {boolean}
     */
    isLight: colorModeObj.colorMode === SCHEME_NAMES.LIGHT,
  };
};

export { ColorSchemes, useColorScheme, useColorMode };
