import { Suspense } from 'react';

/**
 * Implements React.Suspense
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withSuspense = (WrappedComponent) =>
  function WrappedWithSuspense(props) {
    return (
      <Suspense fallback="">
        <WrappedComponent {...props} />
      </Suspense>
    );
  };

export { withSuspense };
