import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import InvitedUserForm from '../components/Forms/InvitedUserForm';
import ResetPasswordForm from '../components/Forms/ResetPasswordForm';
import VerifyEmailForm from '../components/Forms/VerifyEmailForm';
import { useTranslation } from '../providers/i18next';
import { logWarning } from '../providers/rollbar';
import { INVITATION_ROUTE } from '../routes/constants';
import { getUrlPathname } from '../utils/urls';
import LoadingPage from './LoadingPage';

const ActionPage = () => {
  const location = useLocation();
  const { txn } = useTranslation();
  const [error, setError] = useState();
  const search = location?.search ?? '';

  const selectMode = () => {
    const params = new URLSearchParams(search);

    // Get the action to complete.
    // One of "resetPassword" or "verifyEmail"
    const mode = params.get('mode');

    // Get the one-time code from the query parameter.
    // Used to authenticate auth requests
    const actionCode = params.get('oobCode');

    // Get the continue Url to distinguish if the user is invited
    // If provided, it can distinguish whether to show a reset password form or
    // the InvitedUser flow
    const continueUrl = params.get('continueUrl');
    const isInvited =
      !!continueUrl && getUrlPathname(continueUrl) === INVITATION_ROUTE;

    if (!error) {
      switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          if (isInvited) {
            return <InvitedUserForm actionCode={actionCode} />;
          } else {
            return <ResetPasswordForm actionCode={actionCode} />;
          }
        case 'verifyEmail':
          return <VerifyEmailForm actionCode={actionCode} />;

        default:
          logWarning(`showing error for action page`, { mode });
          setError(new Error('Unrecognized action mode'));
          break;
      }
    }
  };

  return (
    <LoadingPage titleBase={txn('Account Action')} error={error} timeout={0}>
      {selectMode()}
    </LoadingPage>
  );
};

export default ActionPage;
