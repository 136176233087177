import { useMemo } from 'react';

import FormSelect from '../../../components/Forms/Parts/v2/FormSelect';
import { languages, useTranslation } from '../../../providers/i18next';
import { useReturnValue } from '../../../providers/returnValue';

const RightMenuV2 = () => {
  const { selectedLangMeta } = useTranslation();
  const { saveNewLanguage } = useReturnValue();

  const options = useMemo(() => {
    return Object.entries(languages).map(([code, { display }]) => ({
      value: code,
      label: display,
    }));
  }, []);

  return (
    <div className="w-28">
      <FormSelect
        options={options}
        defaultValue={selectedLangMeta.code}
        onChange={(e) => {
          const value = e.currentTarget?.value || e.target.value;
          saveNewLanguage(value);
        }}
      />
    </div>
  );
};

export default RightMenuV2;
