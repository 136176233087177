import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FormHeader from '../components/Forms/Parts/v2/FormHeader';
import Loader from '../components/LoaderV2';
import { useAuthState } from '../providers/authState';
import { useTranslation } from '../providers/i18next';
import { RETURN_SIGNIN_KEY, useReturnValue } from '../providers/returnValue';
import { checkHasBrandingData } from '../utils/checkHasBrandingData';

const CustomTokenLoginPage = () => {
  const navigate = useNavigate();
  const { txn } = useTranslation();
  const { brandingData, getReturnState, extendReturnValue } = useReturnValue();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { signInWithCustomToken } = useAuthState();

  const hasBranding = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  // try to login with the custom token in the URL hash data
  useEffect(() => {
    const signinData = {
      [RETURN_SIGNIN_KEY]: true,
    };

    const trySignIn = async (token: string) => {
      try {
        return await signInWithCustomToken(token, signinData);
      } catch (error) {
        return undefined;
      }
    };

    const login = async () => {
      const customToken = getReturnState().customToken;
      const result = await trySignIn(customToken);
      if (isNil(result) || result.errorCode) {
        setLoading(false);
        setError(true);
        return;
      }
    };

    login();
  }, [getReturnState, signInWithCustomToken, extendReturnValue, navigate]);

  if (loading) {
    return <Loader hasBranding={hasBranding} />;
  }

  if (error) {
    return (
      <FormHeader
        text={txn('Oops, something went wrong')}
        subtext={txn('We could not process your request')}
      />
    );
  }

  return <div>&nbsp;</div>;
};

export default CustomTokenLoginPage;
