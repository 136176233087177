import { Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useColorScheme } from '../../providers/chakra/colors';

/**
 * A link to an external resource (URL), NOT a internal route
 */
const ExternalLink = ({
  children /** @type {React.Children} */,
  text /** @type {string} text to display, overridden by children */,
  href /** @type {string} url href, http:// */,
  useNewTab = true /** @type {boolean} passes to chakra "isExternal" prop */,
  ...props
}) => {
  const linkColors = useColorScheme('makeLinkColors');
  const combinedProps = {
    ...linkColors,
    fontWeight: 'medium',
    ...props,
  };
  return (
    <Link {...combinedProps} isExternal={useNewTab} href={href}>
      {children ? children : text}
    </Link>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string,
  href: PropTypes.string.isRequired,
  useNewTab: PropTypes.bool,
};

export default ExternalLink;
