import SignupForm from '../components/Forms/SignupForm';
import { useTranslation } from '../providers/i18next';
import PageBase from './PageLayout/PageBase';

const SignupPage = () => {
  const { txn } = useTranslation();
  return (
    <PageBase titleBase={txn('Sign Up')}>
      <SignupForm />
    </PageBase>
  );
};

export default SignupPage;
