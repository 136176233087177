import { Box, Flex, Spacer, Stack } from '@chakra-ui/react';
import { useMemo } from 'react';

import { InternalLink } from '../../components/Links';
import { useColorScheme } from '../../providers/chakra/colors';
import {
  HEADER_SIZE,
  LOGO_WIDTH,
  PADDING,
} from '../../providers/chakra/layout';
import { HelmetTitle } from '../../providers/helmet';
import { useReturnValue } from '../../providers/returnValue';
import { ROOT_ROUTE } from '../../routes/constants';
import { checkHasBrandingData } from '../../utils/checkHasBrandingData';
import { LeftBrandLogo, LeftWSLogo, RightMenu } from './Header';

const PageBase = ({ titleBase, children }) => {
  const bgColors = useColorScheme('makeBackgroundColors');
  const { brandingData } = useReturnValue();

  const hasBranding = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  const pageData = {};
  if (hasBranding) {
    // example:
    // "favicon": {
    //   "href": "https://ucarecdn.com/020dbd3a-129c-454c-9d05-a90b2dd365d6/-/resize/32x/",
    //   "rel": "icon",
    //   "sizes": "32x32",
    //   "type": "image/png"
    // },
    const favicons = [brandingData.favicon, brandingData.faviconSmall]
      .filter((fav) => fav?.href)
      .map((fav) => {
        const rel = fav?.rel ?? 'icon';
        const type = fav?.type ?? 'image/png';
        const sizes = fav?.sizes ? { sizes: fav.sizes } : {};
        const href = fav.href;

        return <link key={href} href={href} rel={rel} type={type} {...sizes} />;
      });
    // example:
    // "logo": "https://ucarecdn.com/c1303c54-e765-4c61-bdc0-3922e768c490/-/format/auto/",
    // "logoSmall": "https://ucarecdn.com/2f563cc2-ca9d-4668-b693-f8aed0159112/-/resize/19x/-/quality/smart/"
    Object.assign(pageData, {
      favicons,
      logo: (
        <LeftBrandLogo
          srcBig={brandingData.logo}
          srcSmall={brandingData.logoSmall}
        />
      ),
    });
  } else {
    Object.assign(pageData, {
      favicons: [],
      logo: <LeftWSLogo />,
    });
  }

  return (
    <>
      <HelmetTitle
        title={`${titleBase}${hasBranding ? '' : ' - Wonderschool'}`}
        favicons={pageData.favicons}
      />
      <Flex
        minH="100vh"
        direction="column"
        align="center"
        justify="center"
        h="full"
        textAlign="center"
        {...bgColors}
      >
        <Stack direction="row" padding={PADDING} w="100%" {...bgColors}>
          <InternalLink to={ROOT_ROUTE}>{pageData.logo}</InternalLink>
          <RightMenu />
        </Stack>

        <Spacer
          display={{
            base: 'none',
            sm: 'block',
          }}
        />

        <Box
          marginTop={{
            base: '4', // unique for narrow viewport
            sm: '0',
          }}
          padding={PADDING}
          minWidth={{
            md: '2xl',
          }}
          maxWidth={{
            md: '2xl',
            lg: '3xl',
          }}
          align="left"
        >
          {children}
        </Box>

        <Spacer />
        <Box padding={PADDING} w="100%" minH={HEADER_SIZE}>
          <Box w={LOGO_WIDTH} h={HEADER_SIZE} /* fake logo spacer*/ />
        </Box>
      </Flex>
    </>
  );
};

export default PageBase;
