import { Box, Flex, Progress, SimpleGrid, Spacer } from '@chakra-ui/react';

import { useTranslation } from '../../providers/i18next';

const SamlStatus = ({ serviceId, requestId, token, isLoading }) => {
  const { txn } = useTranslation();
  let lines = [];
  const newLine = (title, status) => ({
    title,
    status,
  });

  const getDomainFromEndpoint = ({ entityEndpoint }) => {
    const domain = new URL(entityEndpoint);
    return domain.hostname;
  };

  //  Heads up status logging to window.
  if (requestId) {
    lines.push(newLine(`${txn('Retrieving data for request')}: `, requestId));
  } else if (serviceId) {
    lines.push(newLine(`${txn('Retrieving data for service')}: `, serviceId));
  }

  if (!token) {
    lines.push(
      newLine(`${txn('Generating SAML Token')}: `, `${txn('In process')}`)
    );
  } else {
    lines.push(
      newLine(`${txn('Generating SAML Token')}: `, `${txn('Complete')}`)
    );
    lines.push(
      newLine(`${txn('Redirecting to')}: `, getDomainFromEndpoint(token))
    );
  }

  const drawLine = ({ key, title, status }) => {
    const keyTitle = key.toString().concat('_title');
    const keyStatus = key.toString().concat('_status');
    return [
      <Flex key={key} bg="white" p="3" border="1px" borderColor="gray.200">
        <Box key={keyTitle}>{title}</Box>
        <Spacer />
        <Box key={keyStatus}>{status}</Box>
      </Flex>,
    ];
  };

  return (
    <SimpleGrid columns={1} spacing={2}>
      {lines.map((line, i) => {
        return drawLine({ ...line, key: i });
      })}
      {isLoading ? <Progress size="sm" isIndeterminate /> : null}
    </SimpleGrid>
  );
};

export default SamlStatus;
