import { Center, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useColorScheme } from '../providers/chakra/colors';

/**
 * A simple spinner used mainly for indicating page loading
 * Probably too large to be used for smaller sub-components
 */
const LoadingSpinner = ({
  width,
  height,
  size = '7rem',
  thickness = '4px',
  speed = '1.0s',
}) => {
  const spinnerColors = useColorScheme('makeSpinnerColors');
  const [showSpinner, setShowSpinner] = useState(false);

  // delay after mount before showing spinner to prevent flickering
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Center>
      {showSpinner ? (
        <Spinner
          width={width ?? size}
          height={height ?? size}
          speed={speed}
          thickness={thickness}
          {...spinnerColors}
        />
      ) : null}
    </Center>
  );
};

LoadingSpinner.propTypes = {};

export default LoadingSpinner;
