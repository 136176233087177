import { useToast as useToastChakra } from '@chakra-ui/react';

const toastCommonConfig = {
  duration: 5000,
  isClosable: true,
  position: 'top',
};

export const useToast = () => {
  const toast = useToastChakra();

  return (toastConfig) => {
    toast({
      ...toastCommonConfig,
      ...toastConfig,
    });
  };
};
