import { Box, Text } from '@chakra-ui/react';

import { useColorScheme } from '../../providers/chakra/colors';
import { useTranslation } from '../../providers/i18next';

const SamlError = () => {
  const { txn } = useTranslation();
  const errorColors = useColorScheme('makeErrorColors');
  return (
    <Box bg="white" p="4" border="1px" borderColor="gray.200" {...errorColors}>
      <Text pb="2">{txn('An error occured processing your request.')}</Text>
      <Text>{txn('Please try refreshing the page or logging in again.')}</Text>
    </Box>
  );
};

export default SamlError;
