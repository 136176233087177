import { createContext, useContext, useState } from 'react';

/**
 * Uses process.env variables (NODE_ENV, REACT_APP_FIREBASE_EMULATOR)
 * @returns {'local' | 'dev' | 'prod'}
 */
const getCurrentEnv = () => {
  let env;
  if (process.env.NODE_ENV === 'development') {
    env = 'dev';
    if (process.env.REACT_APP_FIREBASE_EMULATOR === 'ACTIVE') {
      env = 'local';
    }
  } else {
    env = 'prod';
  }
  return env;
};

/**
 * @param {'local' | 'dev' | 'prod'} env
 * @returns {boolean}
 */
const isLocal = (env = getCurrentEnv()) => env === 'local';
/**
 * @param {'local' | 'dev' | 'prod'} env
 * @returns {boolean}
 */
const isDev = (env = getCurrentEnv()) => env === 'dev';
/**
 * @param {'local' | 'dev' | 'prod'} env
 * @returns {boolean}
 */
const isProd = (env = getCurrentEnv()) => env === 'prod';

const EnvContext = createContext({
  /** @type {'local' | 'dev' | 'prod'} */
  env: '',
  /**
   * @param {'local' | 'dev' | 'prod'} env
   * @returns {React.Dispatch<React.SetStateAction<string>>}
   */
  setEnv: (_env) => {},
  /**
   * @returns {boolean}
   */
  isLocal: () => {},
  /**
   * @returns {boolean}
   */
  isDev: () => {},
  /**
   * @returns {boolean}
   */
  isProd: () => {},
});

/**
 * @returns {React.Provider}
 */
const EnvProvider = ({ children }) => {
  const [env, setEnv] = useState(getCurrentEnv());
  return (
    <EnvContext.Provider
      value={{
        env,
        setEnv,
        isLocal: () => isLocal(env),
        isDev: () => isDev(env),
        isProd: () => isProd(env),
      }}
    >
      {children}
    </EnvContext.Provider>
  );
};

/**
 * Makes the Env context available with the useEnv hook
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withEnv = (WrappedComponent) =>
  function WrappedWithEnv(props) {
    return (
      <EnvProvider>
        <WrappedComponent {...props} />
      </EnvProvider>
    );
  };

/**
 * returns the EnvContext object
 */
const useEnv = () => useContext(EnvContext);

export { withEnv, useEnv, getCurrentEnv, isLocal, isDev, isProd };
