import { Button, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import LoadingSpinner from '../components/LoadingSpinner';
import useAuthUser from '../providers/auth/useAuthUserV2';
import { useGlobalLoader } from '../providers/globalLoader';
import { useTranslation } from '../providers/i18next';
import { useInvitationListener } from '../providers/invitations';
import { RETURN_URL_KEY, useReturnValue } from '../providers/returnValue';
import { RETURN_ROUTE, SIGNOUT_ROUTE } from '../routes/constants';
import PageBase from './PageLayout/PageBase';

const InvitationStatusPage = () => {
  const { txn } = useTranslation();
  const navigate = useNavigate();
  const { returnValue, replaceReturnValue } = useReturnValue();
  const { disableLoader } = useGlobalLoader();

  const { state } = useLocation();
  const { isInviteCompleted, isInviteError, inviteRedirectUrl } =
    useInvitationListener(state?.invitationId);
  const { isEmailVerified } = useAuthUser();

  useEffect(() => {
    if (isInviteCompleted || isInviteError) {
      disableLoader();
    }
  }, [disableLoader, isInviteCompleted, isInviteError]);

  const renderContent = () => {
    if (isInviteCompleted && isEmailVerified) {
      return (
        <Stack>
          <Text fontSize="xl" my="8">
            {txn('All is set! Click the button below to go to your dashboard')}
          </Text>
          <Button
            variant="solid"
            colorScheme="blue"
            size="lg"
            onClick={() => {
              replaceReturnValue({
                ...returnValue,
                [RETURN_URL_KEY]: inviteRedirectUrl,
              });
              navigate(RETURN_ROUTE, { replace: true });
            }}
          >
            {txn('Go to your Dashboard')}
          </Button>
        </Stack>
      );
    } else if (isInviteError) {
      return (
        <Stack>
          <Heading mb={4}>
            {txn('It looks like this is an invalid invitation.')}
          </Heading>

          <Text mb={10}>
            {txn('Please click the button below to sign in.')}
          </Text>

          <Button
            variant="solid"
            colorScheme="blue"
            size="lg"
            as={NavLink}
            to={SIGNOUT_ROUTE}
            replace
          >
            {txn('Sign in')}
          </Button>
        </Stack>
      );
    } else {
      // isInviteLoading
      return (
        <Stack>
          <LoadingSpinner />

          <Heading>{txn('Please wait while your account is set up.')}</Heading>
        </Stack>
      );
    }
  };

  return (
    <PageBase titleBase={txn('Preparing account')}>
      <Center>{renderContent()}</Center>
    </PageBase>
  );
};

export default InvitationStatusPage;
