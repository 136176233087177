import { z } from 'zod';

import { getConfig } from '../../config/env';

const { wsConfig } = getConfig();
const authApiUrl = wsConfig.authApiDomain;

const identitySchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string().nullable(),
  password: z.string(),
});

const createIdentityResponseSchema = z.object({
  identityCreate: z.object({
    firebaseCustomToken: z.string(),
  }),
});

export type Identity = z.infer<typeof identitySchema>;

const createIdentityMutation = `
  mutation IdentityCreate($identity: IdentityCreateInput!) {
    identityCreate(identity: $identity) {
      firebaseCustomToken
    }
  }
`;

export const createIdentity = async (
  identityCreate: Identity
): Promise<string> => {
  const variables = { identity: identityCreate };

  const response = await fetch(authApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: createIdentityMutation,
      variables,
    }),
  });

  const responseBody = await response.json();

  if (responseBody.errors) {
    throw new Error(`Errors: ${JSON.stringify(responseBody.errors)}`);
  }

  const responseData = createIdentityResponseSchema.parse(responseBody.data);
  return responseData.identityCreate.firebaseCustomToken;
};
