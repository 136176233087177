import { makeFunctionsRequest } from './functions';

/**
 * ### Async / Promise
 * Sends user id and requestId or ServiceId
 * to generate token for saml exchange
 * This token data is used to create form to post to SP.
 * @param {string} idToken
 * @param {string} requestId  id of saved request
 * @param {string} serviceId  if not sp initiated
 * @returns { Promise<tokenDetails  | undefined> }
 * undefined if error
 */
export const getSamlTokenData = async (idToken, requestId, serviceId) => {
  const payload = {
    idToken,
    requestId,
    serviceId,
  };
  return makeFunctionsRequest('/saml-idp-token', payload);
};
