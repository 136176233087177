import { ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { LoadingPage } from '../pages';
import { useAuthState } from '../providers/authState';
import { SIGNOUT_COMPLETE_ROUTE } from './constants';
import LoadingRoute from './LoadingRoute';

const defaultGuestRoute = SIGNOUT_COMPLETE_ROUTE;
const MAX_TIMEOUT = 4000;

interface Props {
  children: ReactNode | undefined;
  guestRedirect?: string;
}

/**
 * Waits for User initialization from LoadingRoute to complete.
 * Then waits a bit of time for any potential asynchronous signin events to complete.
 * 1) if the user is already signed in (isAuth = true), show auth directly but allow this to be changed up to `MAX_TIMEOUT`
 * 2) if the user auth status changes within the changeable window, allow it. If it changes to guest, it will be permanent
 * 3) if the change window times out, lock it in. if a auth status hasn't been locked in by now, force guest redirect
 * @param {{guestRedirect: string?, children, ...rest}} props props
 *  - guestRedirect: where to send if the user isn't found
 *  - rest: standard props for ReactRouter Route
 * @returns {React.Component}
 */
const UserRoute = ({ guestRedirect, children, ...rest }: Props) => {
  const { isAuthenticated, isLoading, isReady, isUserLoading } = useAuthState();
  const isFinished = !isLoading && isReady && !isUserLoading;
  const [showAuth, setShowAuth] = useState(isAuthenticated);
  const [showGuestRedirect, setShowGuestRedirect] = useState(false);
  const canChangeRouteRef = useRef(true);

  // after timeout, we don't change ref
  useEffect(() => {
    const timer = setTimeout(() => {
      canChangeRouteRef.current = false;
      if (!showAuth && !isAuthenticated) {
        // ok to update guest status if timed out
        setShowGuestRedirect(true);
      }
    }, MAX_TIMEOUT);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAuth, isAuthenticated]);

  // Be wary since these effects can happen before LoadingRoute completes
  // IsFinished duplicates the checks in LoadingRoute to prevent needless redirection
  useEffect(() => {
    if (isFinished && canChangeRouteRef.current) {
      // only let guestRedirect happen once
      if (!isAuthenticated) {
        canChangeRouteRef.current = false;
      }
      // ok to update guest/auth status if isAuthenticated changes
      setShowAuth(isAuthenticated);
      setShowGuestRedirect(!isAuthenticated);
    }
  }, [canChangeRouteRef, isAuthenticated, isFinished]);

  const renderRoute = () => {
    // during transition from guest -> auth
    if (
      isAuthenticated && // auth (already changed)
      !showAuth && // not showing auth (not changed yet)
      !showGuestRedirect && // ignore if already showing guest redirect
      canChangeRouteRef.current // ignore if can't change
    ) {
      // brief state change when user is found (isAuthenticated) but showAuth has not been updated. skip this state.
      return <LoadingPage timeout={0} />;
    }

    if (showAuth) {
      // user found, show the intended user component
      return children;
    } else if (showGuestRedirect) {
      // no user, redirect to the specified guest route.
      return <Navigate to={guestRedirect || defaultGuestRoute} replace />;
    } else {
      return <LoadingPage />;
    }
  };

  return <LoadingRoute {...rest}>{renderRoute()}</LoadingRoute>;
};

export default UserRoute;
