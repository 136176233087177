import { Box, Center, CircularProgress } from '@chakra-ui/react';
// import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import Loader from '../components/LoaderV2';
import LoadingSpinner from '../components/LoadingSpinner';
import { useAuthState } from '../providers/authState';
import { PROGRESS_SIZE } from '../providers/chakra/layout';
import { useGlobalLoader } from '../providers/globalLoader';
import { useTranslation } from '../providers/i18next';
import {
  RETURN_JWT_KEY,
  RETURN_LANGUAGE_KEY,
  RETURN_SESSION_ID_KEY,
  useReturnValue,
} from '../providers/returnValue';
import { logError } from '../providers/rollbar';
import { logLocal, logWarning } from '../providers/rollbar/api';
import { RETURN_COMPLETE_ROUTE, SIGNOUT_ROUTE } from '../routes/constants';
import { useSessionStorageRef } from '../utils/sessionStorageRef';
import LoadingPage from './LoadingPage';

// import PageBaseV2 from './PageLayout/PageBaseV2';

export const RETURN_URL_TEMP_STORE = 'RETURN_URL_TEMP_STORE';

// Stages
export const ReturnStages = {
  // Initial state
  WaitingForFirebase: {
    color: 'teal.200',
    msg: 'Initiating',
    // FirebaseUserReady :: false -> true (one-way)
  },
  WaitingForUserToken: {
    color: 'red.500',
    msg: 'Loading Authorization',
    // UserToken :: undefined -> string (one-way)
  },
  WaitingForAuthSession: {
    color: 'orange.400',
    msg: 'Loading User Data',
    // AuthSessionId :: undefined -> string (one-way)
  },
  BuildingReturnUrl: {
    color: 'yellow.500',
    msg: 'Building Return Protocol',
    // returnUrl :: undefined -> string (one-way)
  },
  MaybeMobileSigningOut: {
    color: 'green.400',
    msg: 'Cleaning Up',
    // signingOut ref -> true while processing
    // redirectReady :: false -> true (one-way)
  },
  PerformingRedirect: {
    color: 'blue.400',
    msg: 'Redirecting',
    // http redirect -> returnUrl
  },
};

const ReturnPage = ({ timeout = 3000 }) => {
  // Hooks
  const { txn, languageKey } = useTranslation();
  // const { onboardV2 } = useFlags();
  const navigate = useNavigate();
  const { disableLoader } = useGlobalLoader();
  const { makeReturnUrl, isIdTokenJwtMode } = useReturnValue();
  const {
    isReady,
    isAuthenticated,
    isUserLoading,
    getCustomToken,
    getIdToken,
    authUser,
    authSession,
  } = useAuthState();
  const [getReturnUrlFromStore, setReturnUrlInStore] = useSessionStorageRef(
    RETURN_URL_TEMP_STORE
  );

  // State - Page
  const [error, setError] = useState();
  const [didTimeout, setDidTimeout] = useState(false);

  // State - Effect Loop
  const [firebaseUserReady, setFirebaseUserReady] = useState(false);
  const [userToken, setUserToken] = useState(undefined);
  const [sessionId, setSessionId] = useState(undefined);

  // Refs
  const isUserVerified = useRef(false);
  const fetchingUserToken = useRef(false);
  const shownUnexpectedDelay = useRef(false);

  // Stage 0 - Init (Default)
  useEffect(
    () => {
      // reset the session storage
      setReturnUrlInStore(undefined);
      // set a timer to fail if we can't build the return url
      let timer = setTimeout(() => {
        if (!getReturnUrlFromStore()) {
          // force signout
          window.location.replace(SIGNOUT_ROUTE);
        }
      }, 6000);

      return () => clearTimeout(timer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // only on mount
    ]
  );

  // Stage 1 - Wait for Firebase User Ready
  useEffect(() => {
    // is there's a user, we can fetch a token, so wait until the user is ready
    const isUserNowReady =
      isReady && isAuthenticated && authUser && !isUserLoading;
    // only set once
    if (isUserNowReady && !firebaseUserReady) {
      isUserVerified.current = !!authUser?.emailVerified; // used in step 6
      setFirebaseUserReady(true);
    }
  }, [authUser, firebaseUserReady, isAuthenticated, isReady, isUserLoading]);

  // Stage 2 - Wait for Id/Custom Token
  useEffect(() => {
    let stillMounted = true;

    // only set once
    if (firebaseUserReady && !userToken && !fetchingUserToken.current) {
      // if the user is ready and we haven't already tried to get a token

      const fetchUserToken = async () => {
        const useForceRefresh = true;
        let token;
        if (isIdTokenJwtMode) {
          token = await getIdToken(useForceRefresh);
        } else {
          token = await getCustomToken(useForceRefresh);
        }
        // only if the component is still mounted
        // and token should only be set once, ignore the rest
        if (stillMounted && !userToken) {
          if (token?.jwt) {
            // only set the token if valid
            setUserToken(token);
          } else {
            if (!isIdTokenJwtMode) {
              // expired token preventing customToken generation.
              // force signout
              window.location.assign(SIGNOUT_ROUTE);
            } else {
              throw new Error('Could not get jwt');
              // caught by catch below
            }
          }
        }
      };

      fetchingUserToken.current = true;
      fetchUserToken()
        .catch((err) => {
          if (stillMounted) {
            logError('Could not fetch user token', err, {
              isIdTokenJwtMode,
            });
            setError(new Error('Sign In Failed'));
            // error message allows manual user intervention
          }
        })
        .finally(() => {
          fetchingUserToken.current = false;
        });
    }

    return () => {
      stillMounted = false;
      fetchingUserToken.current = false;
    };
  }, [
    firebaseUserReady,
    getCustomToken,
    getIdToken,
    isIdTokenJwtMode,
    userToken,
  ]);

  // Stage 3 - Wait for Session Id
  useEffect(() => {
    if (userToken && authSession?.sessionId && !sessionId) {
      setSessionId(authSession.sessionId);
    }
  }, [authSession?.sessionId, sessionId, userToken]);

  // Stage 4 - Build Return Url
  useEffect(() => {
    // require jwt, sessionId to build the return url
    // most importantly, only set the returnUrl once
    if (userToken?.jwt && sessionId && !getReturnUrlFromStore()) {
      const returnUrl = makeReturnUrl({
        [RETURN_JWT_KEY]: userToken.jwt,
        [RETURN_SESSION_ID_KEY]: sessionId,
        [RETURN_LANGUAGE_KEY]: languageKey,
      });
      logLocal('returnUrl', returnUrl);
      setReturnUrlInStore(returnUrl);
      // save our work and handle mobile sign out and redirection on another page
      navigate(RETURN_COMPLETE_ROUTE);
    }
  }, [
    getReturnUrlFromStore,
    navigate,
    languageKey,
    makeReturnUrl,
    sessionId,
    setReturnUrlInStore,
    userToken?.jwt,
  ]);

  let currentReturnStage = ReturnStages.WaitingForFirebase;
  if (firebaseUserReady) {
    currentReturnStage = ReturnStages.WaitingForUserToken;
  }
  if (userToken) {
    currentReturnStage = ReturnStages.WaitingForAuthSession;
  }
  if (sessionId) {
    currentReturnStage = ReturnStages.BuildingReturnUrl;
  }
  const showLoading = () => {
    if (didTimeout) {
      return (
        <Box>
          <Center>
            <CircularProgress
              capIsRound
              isIndeterminate
              size={PROGRESS_SIZE}
              color={currentReturnStage.color}
            />
          </Center>
          <Center>
            {txn(currentReturnStage.msg)}
            <span>&hellip;</span>
          </Center>
        </Box>
      );
    } else {
      return <LoadingSpinner />;
    }
  };

  // if (onboardV2) {
  //   return (
  //     <PageBaseV2 titleBase={txn('Redirecting')}>
  //       <Loader text={txn('One moment while we get everything ready')} />
  //     </PageBaseV2>
  //   );
  // }

  return (
    <LoadingPage
      titleBase={txn('Redirecting')}
      timeout={timeout}
      error={error}
      onTimeout={() => {
        setDidTimeout(true);
        disableLoader();

        if (!shownUnexpectedDelay.current) {
          logWarning(
            'This is taking a little longer than expected. Please wait or refresh your page.',
            { timeout, currentReturnStage }
          );
        }
        // Prevent the log from showing multiple times
        shownUnexpectedDelay.current = true;
      }}
    >
      {showLoading()}
    </LoadingPage>
  );
};

export default ReturnPage;
