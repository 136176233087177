import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { PADDING_HALF } from '../../../providers/chakra/layout';
import { SUBTITLE_FONT } from '../../../providers/chakra/typography';

/**
 * Text to display, generally below the FormHeading.
 */
const FormSubtitle = ({
  children,
  /** @type {React.Children} */ title,
  /** @type {string} text to display, overridden by children */
  ...props /** rest of props, forwarded to Chakra <Box> */
}) => {
  return (
    <Box w="100%" paddingTop={PADDING_HALF} fontSize={SUBTITLE_FONT} {...props}>
      <Text>{children ? children : title}</Text>
    </Box>
  );
};

FormSubtitle.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};

export default FormSubtitle;
