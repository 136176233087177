import { Button, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { useColorScheme } from '../../../providers/chakra/colors';
import { INPUT_RADIUS, PADDING_INPUT } from '../../../providers/chakra/layout';
import { INPUT_FONT } from '../../../providers/chakra/typography';

/**
 * A button useful for submit actions in forms
 * Ref is forwarded for compatibility with react-hook-form
 * https://react-hook-form.com/get-started#Integratinganexistingform
 */
const FormButton = forwardRef(function FormButtonFwdRef(
  {
    /** @type {React.Children} react element to display in button */
    children,
    /** @type {string} text to display in button, overridden by children */
    title,
    /** @type {boolean} whether to display a loading indicator */
    isLoading,
    /** @type {() => {}} callback for click actions */
    onClick,
    ...props /** rest of props, forwarded to Chakra <Button> */
  },
  ref
) {
  const buttonColors = useColorScheme('makeButtonColors');
  return (
    <Button
      colorScheme="blue"
      variant="solid"
      w="100%"
      borderRadius={INPUT_RADIUS}
      padding={PADDING_INPUT}
      fontSize={INPUT_FONT}
      fontWeight="medium"
      isLoading={isLoading}
      onClick={onClick}
      {...buttonColors}
      ref={ref}
      {...props}
    >
      <Text>{children ? children : title}</Text>
    </Button>
  );
});

FormButton.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FormButton;
