import { ExternalLink } from '../components/Links';
import { dataTestIds } from '../config/data-testids';

export const supportEmail = 'support@wonderschool.com';

export const showEmailInstructions = (txn) => (
  <>
    {txn(
      "If you don't see it, make sure to check your spam folder for an email from"
    )}{' '}
    <SupportEmailLink />.
  </>
);
export const SupportEmailLink = (props) => (
  <ExternalLink
    href={`mailto:${supportEmail}`}
    data-testid={dataTestIds.supportEmailLink}
    {...props}
  >
    {supportEmail}
  </ExternalLink>
);
