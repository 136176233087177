import VerifyCheck from '../components/Forms/VerifyCheck';
import { useTranslation } from '../providers/i18next';
import PageBase from './PageLayout/PageBase';

const VerifyPage = () => {
  const { txn } = useTranslation();
  return (
    <PageBase titleBase={txn('Verify')}>
      <VerifyCheck />
    </PageBase>
  );
};

export default VerifyPage;
