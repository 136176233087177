import {
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { FormHeading } from '../components/Forms/Parts';
import { PADDING_MORE } from '../providers/chakra/layout';
import { useFeatureFlags } from '../providers/featureFlags';
import { useTranslation } from '../providers/i18next';
import PageBase from './PageLayout/PageBase';

const FeatureFlagsPage = () => {
  const { txn } = useTranslation();

  const allFlags = useFeatureFlags();

  const renderFlagRow = ({ name, enabled, toggle }) => {
    return (
      <Tr key={name}>
        <Td>{name}</Td>
        <Td>
          <Checkbox
            size="lg"
            isChecked={enabled}
            isInvalid={!enabled}
            colorScheme={enabled ? 'green' : 'red'}
            onChange={() => {
              toggle();
            }}
          />
        </Td>
      </Tr>
    );
  };

  return (
    <PageBase titleBase={txn('Feature Flags')}>
      <FormHeading mono>{txn('Feature Flags')}</FormHeading>
      <TableContainer marginTop={PADDING_MORE}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{txn('Name')}</Th>
              <Th>{txn('Enabled?')}</Th>
            </Tr>
          </Thead>
          <Tbody>{Object.values(allFlags).map(renderFlagRow)}</Tbody>
        </Table>
      </TableContainer>
    </PageBase>
  );
};

export default FeatureFlagsPage;
