import { Box } from '@chakra-ui/react';

import { dataTestIds } from '../../config/data-testids';
import { useAuthState } from '../../providers/authState';
import { PADDING, PADDING_MORE } from '../../providers/chakra/layout';
import { useTranslation } from '../../providers/i18next';
import { useReturnValue } from '../../providers/returnValue';
import { logError } from '../../providers/rollbar';
import { RETURN_ROUTE, SIGNIN_ROUTE } from '../../routes/constants';
import { showEmailInstructions } from '../../utils/support';
import { InternalLink } from '../Links';
import {
  FormButton,
  FormFooter,
  FormHeading,
  FormInput,
  FormSubtitle,
  ReturnToSigninLink,
  useFormStateHook,
} from './Parts';

const InitChangePasswordForm = ({ resetMode }) => {
  const { txn, Trans } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    isDisabled,
    isFinished,
    setFinished,
    markError,
    watch,
  } = useFormStateHook();
  const { sendForgotPasswordEmail } = useAuthState();
  const { formDefaults } = useReturnValue();

  const emailOriginal = watch('email'); // will keep capitalization until the submit form

  const onSubmit = async ({ email }) => {
    const emailLower = email.toLocaleLowerCase();
    try {
      const defaultError = new Error('Failed to send email');
      const result = await sendForgotPasswordEmail(emailLower);
      if (result?.errorCode) {
        switch (result.errorCode) {
          case 'emailInvalid':
            markError(txn('This email address is invalid.'), 'email');
            return;
          case 'email':
            markError(
              txn(
                "We can't find this email. Please check the email and try again."
              ),
              'email'
            );
            return;
          default:
            throw defaultError; // caught below
        }
      } else if (result) {
        // success
        setFinished(true);
      } else {
        throw defaultError; // caught below
      }
    } catch (err) {
      logError(`InitChangePasswordForm: ${err.message}`, err);
      markError(txn('We could not process your request.'), 'toast');
    }
  };

  const showForm = () => (
    <>
      <FormHeading>
        {txn(resetMode ? 'Reset your password' : 'Forgot your password?')}
      </FormHeading>
      <FormSubtitle>
        {txn("You'll receive an email with reset instructions.")}{' '}
        {showEmailInstructions(txn)}
      </FormSubtitle>
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <Box w="100%" marginTop={PADDING}>
          <FormInput
            label={txn('Email')}
            placeholder={txn('your-email@example.com')}
            type="email"
            autoComplete="email"
            disabled={isDisabled}
            defaultValue={formDefaults?.email}
            error={errors.email}
            {...register('email', {
              required: txn('Email is required'),
            })}
            data-testid={dataTestIds.changePwd.email}
            style={{
              textTransform: emailOriginal ? 'lowercase' : 'none',
            }}
          />
        </Box>
        <Box marginTop={PADDING_MORE}>
          <FormButton
            type="submit"
            isLoading={isDisabled}
            data-testid={dataTestIds.changePwd.btnReqReset}
          >
            {txn('Request reset')}
          </FormButton>
        </Box>
      </form>
      <FormFooter>
        {resetMode ? (
          <InternalLink
            to={RETURN_ROUTE}
            data-testid={dataTestIds.changePwd.btn}
          >
            {txn('Never mind, go back')}
          </InternalLink>
        ) : (
          <Trans i18nKey="Never mind, go back to log in">
            Never mind, go back to{' '}
            <InternalLink
              to={SIGNIN_ROUTE}
              data-testid={dataTestIds.changePwd.btnLogin}
            >
              log in
            </InternalLink>
          </Trans>
        )}
      </FormFooter>
    </>
  );

  const showSuccess = () => (
    <>
      <FormHeading>{txn('Check your email')}</FormHeading>
      <Box marginTop={PADDING}>
        <FormSubtitle>
          {txn('We have sent a password reset instructions to your email.')}
        </FormSubtitle>
      </Box>
      <Box marginTop={PADDING_MORE}>
        <FormSubtitle>{showEmailInstructions(txn)}</FormSubtitle>
      </Box>
      <Box marginTop={PADDING_MORE}>
        <FormSubtitle>
          {resetMode ? (
            <InternalLink
              to={RETURN_ROUTE}
              data-testid={dataTestIds.changePwd.btnGoBack}
            >
              {txn('Click here to go back')}
            </InternalLink>
          ) : (
            <ReturnToSigninLink
              route={SIGNIN_ROUTE}
              data-testid={dataTestIds.changePwd.returnToSignin}
            />
          )}
        </FormSubtitle>
      </Box>
    </>
  );

  return isFinished ? showSuccess() : showForm();
};

export default InitChangePasswordForm;
