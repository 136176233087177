import InitChangePasswordForm from '../components/Forms/InitChangePasswordForm';
import { useTranslation } from '../providers/i18next';
import PageBase from './PageLayout/PageBase';

const ForgotPasswordPage = () => {
  const { txn } = useTranslation();

  return (
    <PageBase titleBase={txn('Forgot Password')}>
      <InitChangePasswordForm />
    </PageBase>
  );
};

export default ForgotPasswordPage;
