import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../components/Forms/Parts/v2/Button';
import FormHeader from '../components/Forms/Parts/v2/FormHeader';
import Loader from '../components/LoaderV2';
import useAuthUser from '../providers/auth/useAuthUserV2';
import { useTranslation } from '../providers/i18next';
import { useInvitationListener } from '../providers/invitations';
import { RETURN_URL_KEY, useReturnValue } from '../providers/returnValue';
import { RETURN_ROUTE_V2, SIGNOUT_ROUTE } from '../routes/constants';
import PageBaseV2 from './PageLayout/PageBaseV2';

const InvitationStatusPage = () => {
  const { txn } = useTranslation();
  const navigate = useNavigate();
  const { returnValue, replaceReturnValue } = useReturnValue();

  const { state } = useLocation();
  const { isInviteCompleted, isInviteError, inviteRedirectUrl } =
    useInvitationListener(state?.invitationId);
  const { isEmailVerified } = useAuthUser();

  useEffect(() => {
    if (isInviteCompleted && isEmailVerified) {
      replaceReturnValue({
        ...returnValue,
        [RETURN_URL_KEY]: inviteRedirectUrl,
      });
      // artifical loading timer as this is typically such a quick
      // transition it is jarring for the user.
      setTimeout(() => {
        navigate(RETURN_ROUTE_V2, { replace: true });
      }, 500);
    }
  }, [
    isInviteCompleted,
    isEmailVerified,
    inviteRedirectUrl,
    navigate,
    replaceReturnValue,
    returnValue,
  ]);

  // invite fail
  if (isInviteError) {
    return (
      <PageBaseV2 titleBase={txn('Preparing account')}>
        <div className="space-y-10">
          <FormHeader
            text={txn('It looks like this is an invalid invitation.')}
            subtext={txn('Please click the button below to sign in.')}
          />
          <Button
            onClick={() => navigate(SIGNOUT_ROUTE, { replace: true })}
            text={txn('Sign in')}
          />
        </div>
      </PageBaseV2>
    );
  }

  return (
    <PageBaseV2 titleBase={txn('Preparing account')}>
      <Loader text={txn('One moment while we get everything ready')} />
    </PageBaseV2>
  );
};

export default InvitationStatusPage;
