import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { PADDING, PADDING_MORE } from '../../providers/chakra/layout';
import { useTranslation } from '../../providers/i18next';
import { logError } from '../../providers/rollbar';
import {
  RESET_PASSWORD_ROUTE,
  SIGNIN_RESET_ROUTE,
} from '../../routes/constants';
import { BaseLink, InternalLink } from '../Links';
import {
  BaseSetPasswordForm,
  FormHeading,
  FormSubtitle,
  MIN_PASS_LENGTH,
} from './Parts';

const ResetPasswordForm = ({ actionCode }) => {
  const { txn } = useTranslation();
  const navigate = useNavigate();

  return (
    <BaseSetPasswordForm
      actionCode={actionCode}
      FormMessage={() => {
        return (
          <>
            <FormHeading>{txn('Reset your password')}</FormHeading>
            <FormSubtitle>
              {txn(
                'Choose a new, secure password at least {{minLength}} characters long with both letters and numbers',
                { minLength: MIN_PASS_LENGTH }
              )}
            </FormSubtitle>
          </>
        );
      }}
      SuccessMessage={({ onComplete }) => {
        return (
          <>
            <FormHeading>{txn('Success!')}</FormHeading>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn('Your password has been successfully changed.')}
              </FormSubtitle>
            </Box>
            <Box marginTop={PADDING_MORE}>
              <FormSubtitle>
                {txn('You can now')}{' '}
                <BaseLink
                  onClick={async () => {
                    try {
                      if (onComplete) await onComplete();
                    } catch (err) {
                      logError(`ResetPassword - onComplete Error`, err);
                    } finally {
                      navigate(SIGNIN_RESET_ROUTE, { replace: true });
                    }
                  }}
                >
                  {txn('sign in')}
                </BaseLink>{' '}
                {txn('with your new password')}
              </FormSubtitle>
            </Box>
          </>
        );
      }}
      ErrorMessage={() => {
        return (
          <>
            <FormHeading>{txn('Oops!')}</FormHeading>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn("It looks like you're having a problem with the link.")}
              </FormSubtitle>
            </Box>
            <Box marginTop={PADDING_MORE}>
              <FormSubtitle>
                <InternalLink to={RESET_PASSWORD_ROUTE}>
                  {txn('Try resetting your password again.')}
                </InternalLink>
              </FormSubtitle>
            </Box>
          </>
        );
      }}
    />
  );
};

ResetPasswordForm.propTypes = {
  actionCode: PropTypes.string.isRequired,
};

export default ResetPasswordForm;
