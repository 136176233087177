import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';

import {
  isActive,
  isRecent,
  startNewSession,
  updateSession,
} from '../../api/firebase/session';
import useAuthSession from './useAuthSession';
import useAuthUser from './useAuthUserV2';
import useSavedSessionId from './useSavedSessionId';

/**
 * a hook that manages the one time initialization of the auth user listener and the dependent state
 * management functions (sessions, local storage, transition state, etc).
 * returns various lifecycle state, the user/session objects, captures any unrecoverable errors.
 * provides a method to register state during pre-sign-in to be used post-sign-in.
 * state will listen for user and session updates.
 * also provides session management hooks.
 * - setTransitionState: during sign up/in, you can attach transitionary state to be saved to the (eventual) user session.
 *    this will be consumed and erased by the end of a successful authentication flow.
 */

const useCombinedAuthState = () => {
  const {
    isLoading: isAuthLoading,
    isFailed: isAuthFailed,
    isReady: isAuthReady,
    hasUser,
    authUser,
    authError,
  } = useAuthUser();
  const userId = authUser?.uid;
  const [savedSessionId, setSessionId] = useSavedSessionId(); // from local storage
  const [setMakingSession, makingSessionRef] = useStateRef(false).slice(1);
  const hasSavedSessionId = !!savedSessionId;
  const [transitionState, setTransitionState, transitionStateRef] =
    useStateRef();
  const getTranstionState = useCallback(
    () => transitionStateRef.current,
    [transitionStateRef]
  );
  const getMakingSession = useCallback(
    () => makingSessionRef.current,
    [makingSessionRef]
  );
  const {
    isFailed: isSessionFailed,
    hasSession,
    authSession,
    sessionError,
    isInvalidSession,
  } = useAuthSession(userId, savedSessionId);
  const [stateError, setStateError] = useState();

  const hasError = !!authError || !!sessionError || !!stateError;
  const needsNewSession =
    !hasError && hasUser && !hasSession && !hasSavedSessionId;
  const sessionNeedsRefresh =
    !hasError &&
    hasUser &&
    hasSession &&
    ((isActive(authSession) && !isRecent(authSession)) ||
      !isEmpty(transitionState));
  const sessionNeedsSignout = hasSession && !isActive(authSession);

  // reset the saved session id if it isn't usable
  useEffect(() => {
    if ((hasError || isInvalidSession) && !getMakingSession()) {
      setSessionId(undefined);
    }
  }, [getMakingSession, hasError, isInvalidSession, setSessionId]);

  // if user found but no saved session id, make a new session
  useEffect(() => {
    const makeNewSession = async () => {
      if (needsNewSession && !getMakingSession()) {
        try {
          setMakingSession(true);
          const session = await startNewSession(
            userId,
            getTranstionState() || {}
          ); // throws error if failed
          setSessionId(session.sessionId);
          setTransitionState();
          // new session will be updated by document listener
        } catch (err) {
          setStateError(err);
        } finally {
          setMakingSession(false);
        }
      }
    };
    makeNewSession();
    return () => {
      setMakingSession(false);
    }; // cancellation flag
  }, [
    getMakingSession,
    getTranstionState,
    needsNewSession,
    setMakingSession,
    setSessionId,
    setTransitionState,
    userId,
  ]);

  // if session exists, check if it needs updating
  useEffect(() => {
    const maybeRefreshSession = async () => {
      if (sessionNeedsRefresh && !getMakingSession()) {
        try {
          setMakingSession(true);
          await updateSession(userId, authSession.sessionId, {
            ...authSession,
            ...(getTranstionState() || {}),
          });
          // updated will be updated by document listener
          setTransitionState({});
        } catch (err) {
          setStateError(err);
        } finally {
          setMakingSession(false);
        }
      }
    };
    maybeRefreshSession();
    return () => {
      setMakingSession(false);
    }; // cancellation flag
  }, [
    authSession,
    getMakingSession,
    getTranstionState,
    sessionNeedsRefresh,
    setMakingSession,
    setTransitionState,
    userId,
  ]);

  const isSessionInProgress =
    hasSession && (sessionNeedsRefresh || getMakingSession());
  const isAuthenticated = hasUser && hasSession && !isSessionInProgress;
  const isUserLoading = isAuthReady && hasUser && !isAuthenticated;
  return {
    isLoading: isAuthLoading,
    isFailed: isAuthFailed || isSessionFailed || hasError,
    isReady: isAuthReady, // once ready, this should never revert to an unready state
    isUserLoading,
    isAuthenticated,
    authUser,
    authSession,
    authError: authError || sessionError || stateError,
    sessionNeedsSignout,
    /**
     * @type {(data: Object) => void}
     * @param {Object} state
     * @returns {void}
     * save pre-sign-in state for session creation post-sign-in.
     */
    setTransitionState,
    /**
     * perform cleanup operations during signout
     */
    cleanupSignout: () => {
      setSessionId(undefined);
    },
  };
};

export default useCombinedAuthState;
