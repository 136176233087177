import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ACTION_TYPES } from '../../api/firebase/actionReturn';
import { dataTestIds } from '../../config/data-testids';
import { getSelfServeOnboardingUrl } from '../../config/env';
import { useAuthState } from '../../providers/authState';
import { useTranslation } from '../../providers/i18next';
import { useReturnValue } from '../../providers/returnValue';
import { logError } from '../../providers/rollbar';
import { RETURN_ROUTE } from '../../routes/constants';
import { ExternalLink } from '../Links';
import {
  BaseSigninForm,
  FormHeading,
  FormSubtitle,
  LegalFooter,
} from './Parts';

const SigninForm = ({ resetMode = false } = {}) => {
  const { txn } = useTranslation();
  const didRedirectRef = useRef(false);
  const { isNoSignUpMode, replaceReturnValue } = useReturnValue();
  const navigate = useNavigate();
  const { authUser, consumeActionReturn } = useAuthState();
  const userId = authUser?.uid;
  const hasUser = !!authUser && !!userId;

  const showSignup = !isNoSignUpMode && !resetMode;
  const selfServeOnboardingUrl = getSelfServeOnboardingUrl();

  const onSigninComplete = () => {
    // reset mode handled in useEffect
    if (!resetMode && !didRedirectRef.current) {
      didRedirectRef.current = false;
      navigate(RETURN_ROUTE, { replace: true });
    }
  };

  useEffect(() => {
    async function doAsync() {
      if (hasUser && resetMode && !didRedirectRef.current) {
        didRedirectRef.current = true;
        let actionReturn;
        try {
          actionReturn = await consumeActionReturn(
            userId,
            ACTION_TYPES.RESET_PASSWORD
          );
          if (actionReturn) {
            replaceReturnValue(actionReturn);
          }
        } catch (err) {
          logError(
            `Could not consume action return during signin: ${err.message}`,
            err,
            {
              err,
              resetMode,
              actionReturn,
              userId,
              type: ACTION_TYPES.RESET_PASSWORD,
            }
          );
        }
        navigate(RETURN_ROUTE, { replace: true });
      }
    }
    doAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUser]);

  return (
    <>
      <FormHeading>
        {txn(resetMode ? 'Finish signing in' : 'Welcome back')}
      </FormHeading>
      {showSignup && (
        <FormSubtitle>
          {txn('First time here?')}{' '}
          <ExternalLink
            useNewTab={false}
            href={selfServeOnboardingUrl}
            data-testid={dataTestIds.signinForm.signUpLink}
          >
            {txn('Sign up')}
          </ExternalLink>
        </FormSubtitle>
      )}
      <BaseSigninForm
        onSigninComplete={onSigninComplete}
        showForgotPasswordLink={!resetMode}
        data-testid={dataTestIds.signinForm.baseSigninForm}
      />
      {!resetMode && <LegalFooter />}
    </>
  );
};

export default SigninForm;
