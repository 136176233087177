import { Box } from '@chakra-ui/react';

import { HEADER_SIZE } from '../../../../providers/chakra/layout';

const LogoBox = ({ children }) => {
  return (
    <Box
      flex="1 1 auto"
      h={HEADER_SIZE}
      maxW="200px"
      display="flex"
      alignSelf="center"
      alignItems="center"
      alignContent="center"
    >
      {children}
    </Box>
  );
};

export default LogoBox;
