// CODE MIRRORED IN /functions/api/session.js (v9)
// This is using Firebase v9 syntax

import { v4 as uuidv4 } from 'uuid';

import { logError, trackNewSession } from '../../providers/rollbar';
import {
  deleteDoc,
  finalizeBatch,
  getBatchRef,
  getCollectionDocs,
  getDocRef,
  getDocument,
  writeMergeDoc,
} from './firestore';

/**
 * @typedef {import('firebase/firestore').WriteBatch} WriteBatch
 * @typedef {import('firebase/firestore').DocumentData} DocumentData
 */

/**
 * 1 user can have one session per browser context (ideally).
 * This is meant to be enforced by how the client looks up sessions and auth.
 * A session can be shared across devices if they can share a session id.
 * Otherwise, the only cross-device functionality supported is to sign out of all devices.
 */
const paths = {
  /**
   * @param {string} userId firebase id
   * @returns {string} doc path
   */
  userSessions: (userId) => `authSession/${userId}/sessions`,
  /**
   * @param {string} userId firebase id
   * @param {string} sessionId uuidv4
   * @returns {string} doc path
   */
  session: (userId, sessionId) => `authSession/${userId}/sessions/${sessionId}`,
};

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
const FIVE_MINUTES = 1000 * 60 * 5;
/**
 * @returns {number} epoch millis
 */
const getNow = () => Date.now();
/**
 * @param {number?} now epoch millis, default right now
 * @returns {number} epoch millis, 1 week from now (default)
 */
const getNewExpiration = (now = getNow()) => now + ONE_WEEK;
/**
 * ### Async / Promise
 * write session data to firestore
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {Object} sessionData
 * @param {WriteBatch?} optBatch provide this if you'd prefer to commit as a batch operation
 * @returns {Promise<boolean>}
 * true if write completed, false if an error was caught
 */
const writeSessionData = async (
  userId,
  sessionId,
  sessionData = {},
  optBatch
) => {
  if (
    await writeMergeDoc(paths.session(userId, sessionId), sessionData, optBatch)
  ) {
    return true; // some result provided, maybe just batched
  } else {
    return false; // failed to write, error was caught
  }
};
/**
 * ### Async / Promise
 * delete session document from firestore
 * @param {string*} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {WriteBatch?} optBatch provide this if you'd prefer to commit as a batch operation
 * @returns {Promise<boolean>}
 * true if delete completed, false if an error was caught
 */
const deleteSession = async (userId, sessionId, optBatch) => {
  if (await deleteDoc(paths.session(userId, sessionId), optBatch)) {
    return true; // some result provided, maybe just batched
  } else {
    return false; // failed to write, error was caught
  }
};
/**
 * ### Async / Promise
 * get all sessions for the user. an empty list is returned in case of error.
 * @param {string} userId firebase id
 * @returns {Promise<DocumentData[]>}
 * if the list is empty, either nothing exists or a major error occurred. see console logs for details.
 */
const getUserSessions = async (userId) =>
  (await getCollectionDocs(paths.userSessions(userId))) || [];
/**
 * ### Async / Promise
 * gets a specific session from firestore
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @returns {Promise<DocumentData | undefined>}
 * if undefined, either the document was not found or an error occurred.
 * otherwise, returns the document data as json object
 */
const getSession = async (userId, sessionId) =>
  (await getDocument(paths.session(userId, sessionId))) || undefined;
/**
 * Makes a new session object that is active, starts now, and has a fresh expiration date
 * Can optionally add data to the session
 * Generates a new session id
 * @param {string} userId firebase id
 * @param {string?} optSessionData optional session data
 * @returns {Object}
 */
const makeNewSessionData = (userId, optSessionData = {}) => {
  const sessionId = uuidv4();
  return {
    ...optSessionData,
    userId,
    sessionId,
    active: true,
    startsAt: getNow(),
    expires: getNewExpiration(),
  };
};
/**
 * Makes a "fresh" session object with existing session id
 * Recommend including the original session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {string?} optSessionData original session data
 * @returns {Object}
 */
const makeUpdateSessionData = (userId, sessionId, optSessionData = {}) => {
  return {
    ...optSessionData,
    userId,
    sessionId,
    active: true,
    startsAt: getNow(),
    expires: getNewExpiration(),
  };
};
/**
 * Makes an inactive session that has already expired
 * Recommend including the original session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {string?} optSessionData original session data
 * @returns {Object} updated session
 */
const makeInactiveSessionData = (userId, sessionId, optSessionData = {}) => {
  return {
    ...optSessionData,
    userId,
    sessionId,
    active: false,
    expires: getNow(),
  };
};
/**
 * Active session must be marked active and have an expiration date in the future
 * @param {Object} session session data
 * @returns {boolean}
 */
const isActive = (session) => {
  return session?.active && session?.expires && session.expires > getNow();
};
/**
 * Recent session must active and have started in the last 5 minutes
 * @param {Object} session session data
 * @returns {boolean}
 */
const isRecent = (session) => {
  return isActive(session) && getNow() - session.startsAt < FIVE_MINUTES;
};
/**
 * @param {Object} session session data
 * @returns {number?} epoch millis until expiration, undefined otherwise. may be negative if already expired.
 */
const getRemaining = (session) =>
  session ? session.expires - getNow() : undefined;

// CODE MIRRORED IN /functions/endpoints/authSession.js (v9)

/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to create a new session and return that session data
 * @param {string} userId firebase id
 * @param {Object?} optData
 * @returns { Promise<Object> } new session
 * @throws { Error } if userId is not provided
 * @throws { Error } session fails to write
 */
const startNewSession = async (userId, optData = {}) => {
  if (!userId) {
    throw new Error('userId required');
  }

  const newSessionData = makeNewSessionData(userId, optData);
  const didWrite = await writeSessionData(
    userId,
    newSessionData.sessionId,
    newSessionData
  );
  if (didWrite) {
    trackNewSession(newSessionData);
    return newSessionData;
  } else {
    throw new Error('Failed to create session');
  }
};
/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to update an existing session, including refreshing the timestamps, and returns the new session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {Object?} optData
 * @returns { Promise<Object> } updated session
 * @throws { Error } if userId is not provided
 * @throws { Error } if sessionId is not provided
 * @throws { Error } if session is not found
 * @throws { Error } session fails to write
 */
const updateSession = async (userId, sessionId, optData = {}) => {
  if (!userId) {
    throw new Error('userId required');
  }
  if (!sessionId) {
    throw new Error('userId sessionId');
  }
  const session = await getSession(userId, sessionId);
  if (!session) {
    throw new Error('session not found');
  }

  const refreshedSessionData = makeUpdateSessionData(userId, sessionId, {
    ...session,
    ...optData,
  });
  const didWrite = await writeSessionData(
    userId,
    sessionId,
    refreshedSessionData
  );
  if (didWrite) {
    trackNewSession(refreshedSessionData);
    return refreshedSessionData;
  } else {
    throw new Error('Failed to update session');
  }
};
/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to stop an existing session, including overwriting the expiration
 * and flipping the active flag, and returns the new session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @returns { Promise<Object> } stopped session
 * @throws { Error } if userId is not provided
 * @throws { Error } if sessionId is not provided
 * @throws { Error } if session is not found
 * @throws { Error } session fails to write
 */
const stopSession = async (userId, sessionId) => {
  if (!userId) {
    throw new Error('userId required');
  }
  if (!sessionId) {
    throw new Error('userId sessionId');
  }
  const session = await getSession(userId, sessionId);
  if (!session) {
    throw new Error('session not found');
  }

  const inactiveSessionData = makeInactiveSessionData(
    userId,
    sessionId,
    session
  );
  const didWrite = await writeSessionData(
    userId,
    sessionId,
    inactiveSessionData
  );
  if (didWrite) {
    trackNewSession(inactiveSessionData);
    return inactiveSessionData;
  } else {
    throw new Error('Failed to stop session');
  }
};

/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * for each user, try to find their sessions. try to prune (delete) all the inactive ones
 * errored sessions are ignored and user should try again.
 * returns list of removed sessions. Returns empty list if none to prune.
 * @param {string} userId firebase id
 * @param {boolean} sholdPrune whether the stopped sessions should also be pruned
 * @param {WriteBatch?} batchRef provide this if you'd prefer to use your own batch container
 * @returns {Promise<{ updatedSessions: Object[], inactiveSessions: Object[] }>} list of removed sessions
 * @throws { Error } if userId is not provided
 * @throws { Error } sessions fail to update and no operation was made
 */
const stopAllSessions = async (
  userId,
  shouldPrune = false,
  batchRef = getBatchRef()
) => {
  if (!userId) {
    throw new Error('userId required');
  }

  const userSessions = await getUserSessions(userId);
  const updatedSessions = [];
  const inactiveSessions = [];
  if (userSessions?.length) {
    await Promise.allSettled(
      userSessions.map(async (session) => {
        try {
          const sessionId = session.sessionId;
          if (isActive(session)) {
            const inactiveSessionData = makeInactiveSessionData(
              userId,
              sessionId
            );
            const writeResult = await writeSessionData(
              userId,
              sessionId,
              inactiveSessionData,
              batchRef
            );
            if (writeResult) {
              updatedSessions.push(inactiveSessionData);
              if (shouldPrune) {
                await deleteSession(userId, sessionId, batchRef);
              }
            }
          } else {
            inactiveSessions.push(session);
            if (shouldPrune) {
              await deleteSession(userId, sessionId, batchRef);
            }
          }
        } catch (err) {
          logError(`Error stopping all sessions: ${err.message}`, err, {
            session,
          });
          // swallow and ignore, can try again
        }
      })
    );
    const batchResult = await finalizeBatch(batchRef);
    if (batchResult) {
      // after loop
      if (shouldPrune) {
        await pruneSessions(userId);
      }

      return {
        updatedSessions,
        inactiveSessions,
      };
    } else {
      throw new Error('Failed to stop all sessions');
    }
  }
  // no updates were made
  return {
    updatedSessions: [],
    inactiveSessions: [],
  };
};

/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * for each user, try to find their sessions. try to prune (delete) all the inactive ones
 * errored sessions are ignored and user should try again.
 * returns list of removed sessions. Returns empty list if none to prune.
 * @param {string} userId firebase id
 * @param {WriteBatch?} batchRef provide this if you'd prefer to use your own batch container
 * @returns {Promise<{ removedSessions: Object[] }>} list of removed sessions
 * @throws { Error } if userId is not provided
 * @throws { Error } sessions fail to update and no operation was made
 */
const pruneSessions = async (userId, batchRef = getBatchRef()) => {
  if (!userId) {
    throw new Error('userId required');
  }
  const userSessions = await getUserSessions(userId);
  const removedSessions = [];
  if (userSessions?.length) {
    await Promise.allSettled(
      userSessions.map(async (session) => {
        try {
          if (!isActive(session)) {
            const sessionId = session.sessionId;
            const deleteResult = await deleteSession(
              userId,
              sessionId,
              batchRef
            );
            if (deleteResult) {
              removedSessions.push(session);
            }
          }
        } catch (err) {
          logError(`Error pruning sessions: ${err.message}`, err, { session });
          // swallow and ignore, can try again
        }
      })
    );
    // after loop
    const batchResult = await finalizeBatch(batchRef);
    if (batchResult) {
      return { removedSessions };
    } else {
      throw new Error('Failed to prune sessions');
    }
  }
  // no updates were made
  return { removedSessions: [] };
};

/**
 * @param {string?} userId
 * @param {string?} sessionId
 * @returns {DocumentReference<DocumentData?>} either the reference or undefined if sufficient arguments are not provided
 */
const getSessionRef = (userId, sessionId) => {
  if (!userId || !sessionId) {
    return undefined;
  }
  return getDocRef(paths.session(userId, sessionId));
};

export {
  startNewSession,
  updateSession,
  stopSession,
  stopAllSessions,
  pruneSessions,
  getSession,
  isActive,
  isRecent,
  getRemaining,
  getSessionRef,
};
