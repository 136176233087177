import { useMemo } from 'react';

import { InternalLink } from '../../components/Links';
import { HelmetTitle } from '../../providers/helmet';
import { useReturnValue } from '../../providers/returnValue';
import { ROOT_ROUTE } from '../../routes/constants';
import { checkHasBrandingData } from '../../utils/checkHasBrandingData';
import { LeftBrandLogo, LeftWSLogo } from './Header';
import RightMenuV2 from './Header/RightMenuV2';

const PageBaseV2 = ({ titleBase, children }) => {
  const { brandingData } = useReturnValue();

  const hasBranding = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  const pageData: Record<string, string | JSX.Element | string[]> = {};

  if (hasBranding) {
    // example:
    // "favicon": {
    //   "href": "https://ucarecdn.com/020dbd3a-129c-454c-9d05-a90b2dd365d6/-/resize/32x/",
    //   "rel": "icon",
    //   "sizes": "32x32",
    //   "type": "image/png"
    // },
    const favicons = [brandingData?.favicon, brandingData?.faviconSmall]
      .filter((fav) => fav?.href)
      .map((fav) => {
        const rel = fav?.rel ?? 'icon';
        const type = fav?.type ?? 'image/png';
        const sizes = fav?.sizes ? { sizes: fav.sizes } : {};
        const href = fav?.href;

        return <link key={href} href={href} rel={rel} type={type} {...sizes} />;
      });
    // example:
    // "logo": "https://ucarecdn.com/c1303c54-e765-4c61-bdc0-3922e768c490/-/format/auto/",
    // "logoSmall": "https://ucarecdn.com/2f563cc2-ca9d-4668-b693-f8aed0159112/-/resize/19x/-/quality/smart/"
    Object.assign(pageData, {
      favicons,
      logo: (
        <LeftBrandLogo
          srcBig={brandingData?.logo}
          srcSmall={brandingData?.logoSmall}
        />
      ),
    });
  } else {
    Object.assign(pageData, {
      favicons: [],
      logo: <LeftWSLogo />,
    });
  }

  return (
    <div className="min-h-screen h-full flex flex-col items-center justify-center">
      {/* @ts-expect-error the IDE gets confused over which TS version to use */}
      <HelmetTitle
        title={`${titleBase}${hasBranding ? '' : ' - Wonderschool'}`}
        favicons={pageData.favicons}
      />

      <div className="flex justify-between items-center px-3 pt-3 md:px-6 md:pt-6 w-full space-between">
        <div>
          <InternalLink to={ROOT_ROUTE} text="">
            {pageData.logo as JSX.Element}
          </InternalLink>
        </div>
        <div>
          <RightMenuV2 />
        </div>
      </div>

      <div className="w-full h-full p-3 md:p-6 mt-4 md:mt-0">
        <div className="max-w-screen-sm mx-auto max-sm:px-2">
          {children ?? null}
        </div>
      </div>

      {/* 
        Note: Below this is a straight conversion of legacy chakra to tailwind styles.
        I was unable to figure out for sure what this is doing. 
        Best guess is that it served as a pseudo footer to match the height 
        of the nav header. Legacy code below.
       */}
      {/*        
        <Spacer />
        <Box padding={PADDING} w="100%" minH={HEADER_SIZE / 28px}>
          <Box w={LOGO_WIDTH / 320px} h={HEADER_SIZE / 28px} /> // fake logo spacer
        </Box>
       */}
      <div className="p-3 md:p-6 w-full min-h-7">
        <div className="w-80 h-80" />
      </div>
    </div>
  );
};

export default PageBaseV2;
