import SigninForm from '../components/Forms/SigninForm';
import { useTranslation } from '../providers/i18next';
import PageBase from './PageLayout/PageBase';

const SigninPage = () => {
  const { txn } = useTranslation();

  return (
    <PageBase titleBase={txn('Sign In')}>
      <SigninForm />
    </PageBase>
  );
};

export default SigninPage;
