import {
  unset as deleteObjectPath,
  get as getObjectPath,
  isObject,
  set as setObjectPath,
} from 'lodash';

import { isLocal, isTest } from '../../config/env';
import { isRollbarFlagEnabled } from '../featureFlags';
import { getInitialPayload, getRollbar } from './config';

const paths = {
  payload: 'payload',
  userContext: 'payload.user_context',
  sessionContext: 'payload.user_context.session',
  flagsContext: 'payload.flags_context',
  customContext: 'payload.custom_context',
  defaultPath: 'payload.default_path',
  logLevel: 'logLevel',
};

export const makeInitialPayload = () => ({
  [paths.payload]: {
    ...getInitialPayload(),
  },
});
let loggerContext;
export const _test_getLoggerContext = () => loggerContext;
export const _test_resetLoggerContext = () =>
  (loggerContext = makeInitialPayload());
loggerContext = _test_resetLoggerContext;

export const extendPayload = (
  ctx,
  path = paths.defaultPath,
  newPayload = {}
) => {
  if (!path) {
    throw new Error('Path required in extendPayload');
  }
  const original = getObjectPath(ctx, path);
  return setObjectPath(
    {
      ...ctx,
    },
    path,
    isObject(newPayload)
      ? {
          ...(isObject(original) ? original : {}),
          ...newPayload,
        }
      : newPayload
  );
};
export const setPayload = (ctx, path = paths.defaultPath, newPayload = {}) => {
  if (!path) {
    throw new Error('Path required in setPayload');
  }
  return setObjectPath(
    {
      ...ctx,
    },
    path,
    newPayload
  );
};
export const deleteFromPayload = (ctx, path = paths.defaultPath) => {
  const newCtx = { ...ctx };
  deleteObjectPath(newCtx, path);
  return newCtx;
};

// --------------

export const maybeUpdateLoggerContext = (ctx, operation) => {
  const shouldSetLoggerCtx = !ctx;
  ctx = ctx ?? loggerContext;
  const newCtx = operation(ctx);
  if (shouldSetLoggerCtx) {
    loggerContext = newCtx;
  }
  return newCtx;
};

export const trackUser = (user, session, ctx) => {
  return maybeUpdateLoggerContext(ctx, (ctx) => {
    let newCtx = setPayload(ctx, paths.userContext, user);
    if (session) {
      newCtx = trackNewSession(session, newCtx);
    }
    return newCtx;
  });
};

export const trackUserSignout = (ctx) => {
  return maybeUpdateLoggerContext(ctx, (ctx) => {
    let newCtx = setPayload(ctx, paths.userContext, {});
    newCtx = setPayload(ctx, paths.sessionContext, {});
    return newCtx;
  });
};

export const trackNewSession = (session, ctx) => {
  return maybeUpdateLoggerContext(ctx, (ctx) => {
    return setPayload(ctx, paths.sessionContext, session);
  });
};

export const trackFlags = (flags, ctx) => {
  return maybeUpdateLoggerContext(ctx, (ctx) => {
    return setPayload(ctx, paths.flagsContext, flags);
  });
};

// --------------

export const isRollbarEnabled = () => {
  return !isLocal() && !isTest() && isRollbarFlagEnabled();
};

export const buildLogConfig = (
  level = 'log',
  newContext,
  ctx = loggerContext
) => {
  return {
    [paths.logLevel]: level,
    ...extendPayload(ctx, paths.customContext, newContext),
  };
};

let defaultLogger;
const getDefaultLogger = () => {
  if (!defaultLogger) {
    defaultLogger = (config, message, err, newContext) => {
      if (isRollbarEnabled()) {
        const rollbar = getRollbar();
        rollbar.configure(config);
        rollbar.log(...filterUndefined([message, err, newContext]));
      } else if (!isTest()) {
        logLocal(...filterUndefined([message, err, newContext, config]));
      }
    };
  }
  return defaultLogger;
};
defaultLogger = getDefaultLogger();

export const logByLevel = (
  message,
  err,
  newContext = {},
  level = 'info',
  logger,
  ctx
) => {
  if (!logger) {
    throw new Error('logger required in logByLevel');
  }
  const config = buildLogConfig(level, newContext, ctx);
  return logger(config, message, err, newContext);
};

export const filterUndefined = (args) => args.filter((x) => x !== undefined);

// - log major systemic errors that causes fatal shutdowns or whole-screen errors
export const logCritical = (
  message,
  err,
  context,
  logger = getDefaultLogger(),
  ctx = loggerContext
) => {
  return logByLevel(message, err, context, 'critical', logger, ctx);
};

// Uncaught errors, unexpected errors that affect only the specific operation
export const logError = (
  message,
  err,
  context,
  logger = getDefaultLogger(),
  ctx = loggerContext
) => {
  return logByLevel(message, err, context, 'error', logger, ctx);
};

// Expected/recoverable and well handled error, things like well-known firebase errors, reused auth codes, etc
export const logWarning = (
  message,
  context,
  logger = getDefaultLogger(),
  ctx = loggerContext
) => {
  return logByLevel(
    message,
    new Error(message),
    context,
    'warning',
    logger,
    ctx
  );
};

// normal system operations that should be tracked to provide context, like "Begining signup process"
export const logInfo = (
  message,
  context,
  logger = getDefaultLogger(),
  ctx = loggerContext
) => {
  return logByLevel(message, undefined, context, 'info', logger, ctx);
};

// local console logging
export const logLocal = (...args) => {
  if (isLocal() && !isTest()) {
    console.log(...args, loggerContext);
  }
};
