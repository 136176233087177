/**
 * constants for different font sizes
 * for specific font familes and weights, see ./baseFont.js
 * for colors, see ./colors.js
 */

const TITLE_FONT = {
  base: '2.4em',
  lg: '2.0em',
  xl: '2.8em',
  '2xl': '2.8em',
};
const MEDIUM_FONT = {
  base: '1.2em',
  lg: '1.2em',
  xl: '1.8em',
  '2xl': '1.8em',
};
const SUBTITLE_FONT = {
  base: '1.0em',
  lg: '1.0em',
  xl: '1.1em',
  '2xl': '1.2em',
};

const INPUT_FONT = {
  base: '0.8em',
  md: '1.0em',
  lg: '1.2em',
  xl: '1.2em',
  '2xl': '1.2em',
};

const SMALLER_FONT = {
  base: '0.8em',
  lg: '1.0em',
  xl: '1.0em',
  '2xl': '1.2em',
};

const TINY_FONT = {
  base: '0.6em',
  lg: '0.6em',
  xl: '0.6em',
  '2xl': '0.8em',
};

export {
  TITLE_FONT,
  MEDIUM_FONT,
  SUBTITLE_FONT,
  INPUT_FONT,
  SMALLER_FONT,
  TINY_FONT,
};
