import { useReducer } from 'react';

import { useAuthState } from '../../../providers/authState';
import { useTranslation } from '../../../providers/i18next';
import { logError } from '../../../providers/rollbar';
import { BaseLink } from '../../Links';
import LoadingSpinner from '../../LoadingSpinner';

const ResendVerificationLink = () => {
  const { Trans, txn } = useTranslation();
  const { sendVerificationEmail } = useAuthState();
  const Actions = {
    SendError: { type: 'SendError' },
    Sending: { type: 'Sending' },
    SendSuccess: { type: 'SendSuccess' },
  };
  const [{ isLoading, isError, isSent }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case Actions.SendError.type:
          return {
            ...state,
            isLoading: false,
            isError: true,
            isSent: false,
          };
        case Actions.Sending.type:
          return {
            ...state,
            isLoading: true,
            isError: false,
            isSent: false,
          };
        case Actions.SendSuccess.type:
          return {
            ...state,
            isLoading: false,
            isError: false,
            isSent: true,
          };
        default:
          return state;
      }
    },
    {
      isLoading: false,
      isError: false,
      isSent: false,
    }
  );

  const resendEmailVerification = async () => {
    try {
      dispatch(Actions.Sending);
      const returnValue = await sendVerificationEmail();
      if (returnValue?.errorCode) {
        switch (returnValue.errorCode) {
          // no error codes
          default:
            dispatch(Actions.SendError);
            return;
        }
      } else if (returnValue) {
        dispatch(Actions.SendSuccess);
        return;
      } else {
        throw new Error('Failed to send email verification');
      }
    } catch (err) {
      logError(`sendVerificationEmail failed: ${err.message}`, err);
      dispatch(Actions.SendError);
      return;
    }
  };

  if (isLoading) {
    return <LoadingSpinner size="4" thickness="1px" />;
  } else if (isSent) {
    return <>{txn('We just resent another verification email.')}</>;
  } else if (isError) {
    return (
      <Trans i18nKey="Oops, something went wrong. Click here to resend the confirmation email again.">
        Oops, something went wrong.{' '}
        <BaseLink
          onClick={(e) => {
            e.preventDefault();
            resendEmailVerification();
          }}
        >
          Click here to resend the confirmation email again.
        </BaseLink>
      </Trans>
    );
  } else {
    return (
      <Trans i18nKey="Did not get it? Check your spam folder or resend the confirmation email again.">
        Did not get it? Check your spam folder or{' '}
        <BaseLink
          onClick={(e) => {
            e.preventDefault();
            resendEmailVerification();
          }}
        >
          resend the confirmation email again.
        </BaseLink>
      </Trans>
    );
  }
};

export default ResendVerificationLink;
