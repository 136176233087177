import { useNavigate } from 'react-router-dom';

import { useTranslation } from '../../../providers/i18next';
import { logError } from '../../../providers/rollbar';
import { BaseLink } from '../../Links';

const ReturnToSigninLink = ({ route, onClick }) => {
  const { Trans } = useTranslation();
  const navigate = useNavigate();

  return (
    <Trans i18nKey="Click here to return to the sign in page">
      Click here to{' '}
      <BaseLink
        onClick={async () => {
          try {
            if (onClick) await onClick();
          } catch (err) {
            logError(`Error with ReturnToSigninLink: ${err.message}`, err);
          } finally {
            if (route) navigate(route, { replace: true });
          }
        }}
      >
        return to the sign in page.
      </BaseLink>
    </Trans>
  );
};

export default ReturnToSigninLink;
