import { Box, Center, Flex, Image, Stack } from '@chakra-ui/react';
import InputMask from 'comigo-tech-react-input-mask'; // https://github.com/sanniassin/react-input-mask/issues/239
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Validator from 'validator';

import { getPendingUserInvite } from '../../api/firebase/invitations.js';
import { dataTestIds } from '../../config/data-testids';
import { getSelfServeOnboardingUrl } from '../../config/env';
import { useAuthState } from '../../providers/authState';
import { PADDING, PADDING_MORE } from '../../providers/chakra/layout';
import { useGlobalLoader } from '../../providers/globalLoader';
import { useTranslation } from '../../providers/i18next';
import {
  RETURN_LANGUAGE_KEY,
  useReturnValue,
} from '../../providers/returnValue';
import { logError, logInfo, logWarning } from '../../providers/rollbar';
import {
  OLD_INVITATION_STATUS_ROUTE,
  RETURN_ROUTE,
} from '../../routes/constants';
import { checkHasBrandingData } from '../../utils/checkHasBrandingData';
import { ExternalLink } from '../Links';
import {
  FormButton,
  FormCheckbox,
  FormHeading,
  FormInput,
  LegalText,
  useFormStateHook,
} from './Parts';

const FORM_FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
  password: 'password',
  newSupply: 'newSupply',
  termsAndConditions: 'termsAndConditions',
};

const PLACEHOLDERS = {
  [FORM_FIELDS.firstName]: 'First Name',
  [FORM_FIELDS.lastName]: 'Last Name',
  [FORM_FIELDS.email]: 'Email Address',
  [FORM_FIELDS.phone]: 'Mobile Phone',
  [FORM_FIELDS.password]: 'Password',
};

const SUNNY_WIDTH = 120;

const SignupForm = ({ invitation }) => {
  const { txn, languageKey } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    markError,
    setValue,
    isDisabled,
    reset,
    watch,
  } = useFormStateHook();
  const { signUpNewUser, signUpInvitedUser } = useAuthState();
  const { formDefaults, brandingData, getReturnState, returnValue } =
    useReturnValue();
  const {
    // activateLoader,
    disableLoader,
  } = useGlobalLoader();

  const emailOriginal = watch('email'); // will keep capitalization until the submit form

  const hasBranding = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  const hasInvitation = !!invitation?.id && !!invitation?.email;

  // ensure user context exists
  useEffect(() => {
    const userContext = {
      returnState: getReturnState(),
      returnValue,
      invitation,
    };
    const noUserContextFound =
      !Object.keys(userContext.returnState || {}).length &&
      !userContext.returnValue &&
      !hasInvitation;

    if (noUserContextFound) {
      const onboardUrl = getSelfServeOnboardingUrl();
      if (onboardUrl) {
        window.location.replace(onboardUrl);
      }
    }
  }, [getReturnState, returnValue, hasInvitation, invitation]);

  // check for and instantiate newSupply flag
  useEffect(() => {
    const returnState = getReturnState();

    if (!returnState || !Object.keys(returnState || {}).length) {
      return;
    }
    setValue(FORM_FIELDS.newSupply, returnState?.newSupply ?? false);
  }, [getReturnState, setValue, returnValue]);

  useEffect(() => {
    if (!invitation) {
      return;
    } else if (!invitation?.id || !invitation?.email) {
      logWarning('Unexpected: invitation without id/email', { invitation });
      return;
    }
    // Populate form with invitation data if available
    const {
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
    } = invitation;
    reset({ firstName, lastName, email, phone });
  }, [invitation, reset]);

  const onSubmit = async (userData) => {
    // ensure lowercase
    userData.email = userData.email.toLocaleLowerCase();

    logInfo('create account form submit: ', { email: userData.email });

    try {
      // activateLoader();

      const optData = {
        [RETURN_LANGUAGE_KEY]: languageKey,
      };
      const defaultError = new Error('Failed to sign up');
      let signupResult = undefined;
      let pendingInvitation = null;

      try {
        pendingInvitation = await getPendingUserInvite(userData.email);
      } catch (error) {
        logWarning('error searching for pending invites: ', {
          email: userData.email,
          error,
        });
      }

      // Handles signup with invitation.
      if (hasInvitation || pendingInvitation?.id) {
        // Send invitation id for post signup user profile update.
        userData.invitationId = invitation?.id || pendingInvitation?.id;
        // invites should not be considered newSupply
        userData.newSupply = false;
        // we MUST ensure that return context does not override the invite type.
        if (pendingInvitation?.id && pendingInvitation.type) {
          userData.type = pendingInvitation.type;
        }
        signupResult = await signUpInvitedUser(userData, optData);
      } else {
        signupResult = await signUpNewUser(userData, optData, hasBranding);
      }

      if (signupResult?.errorCode) {
        switch (signupResult.errorCode) {
          case 'email':
            markError(txn('This email address is already in use.'), 'email');
            return;
          case 'emailInvalid':
            markError(txn('This email address is invalid.'), 'email');
            return;
          case 'password':
            markError(
              txn('This password is not strong enough. Please try another.'),
              'password'
            );
            return;
          default:
            throw defaultError; // caught below
        }
      } else if (!signupResult) {
        throw defaultError; // caught below
      } else {
        // success
        if (hasInvitation || pendingInvitation?.id) {
          disableLoader();
          // Redirect to next route with invitation id if available.
          navigate(OLD_INVITATION_STATUS_ROUTE, {
            replace: true,
            state: {
              ...userData,
              invitationId: invitation?.id || pendingInvitation?.id,
            },
          });
        } else {
          // Normal return
          navigate(RETURN_ROUTE, { replace: true });
        }
      }
    } catch (err) {
      logError(`SignUpForm: ${err.message}`, err);
      markError(txn('We could not create your account'));
    }
  };

  return (
    <Box position="relative">
      <Center>
        <FormHeading w={null}>
          {/* 
            IMPORTANT: if there is alternate branding, no mention of wonderschool is permitted
          */}
          {hasBranding
            ? txn('Create your account')
            : !hasInvitation
              ? txn('Create your account')
              : txn("You're invited to join Wonderschool")}
        </FormHeading>
      </Center>

      {/* sunny */}
      {!hasInvitation && !hasBranding ? (
        <Box position="absolute" top={`-${SUNNY_WIDTH / 2}px`} right="0">
          <Image
            width={`${SUNNY_WIDTH}px`}
            src="/sunny-left.png"
            transform="scaleX(-1)"
            alt="Sunny Logo"
          />
        </Box>
      ) : null}

      <form
        disabled={isDisabled}
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <Box w="100%" marginTop={PADDING}>
          <FormInput
            hidden
            type="checkbox"
            autoComplete="off"
            {...register(FORM_FIELDS.newSupply)}
            data-testid={dataTestIds.signupForm.newSupplyCheckbox}
          />
          <Stack
            direction={['column', 'row']}
            spacing={PADDING}
            paddingTop={PADDING}
          >
            <FormInput
              label={null}
              placeholder={txn(PLACEHOLDERS[FORM_FIELDS.firstName])}
              type="text"
              autoComplete="off"
              noTop
              disabled={isDisabled}
              defaultValue={formDefaults?.firstName}
              error={errors.firstName}
              {...register(FORM_FIELDS.firstName, {
                required: txn('First name is required'),
              })}
              data-testid={dataTestIds.signupForm.firstName}
            />
            <FormInput
              label={null}
              placeholder={txn(PLACEHOLDERS[FORM_FIELDS.lastName])}
              type="text"
              autoComplete="off"
              noTop
              disabled={isDisabled}
              defaultValue={formDefaults?.lastName}
              error={errors.lastName}
              {...register(FORM_FIELDS.lastName, {
                required: txn('Last name is required'),
              })}
              data-testid={dataTestIds.signupForm.lastName}
            />
          </Stack>

          <FormInput
            label={null}
            placeholder={txn(PLACEHOLDERS[FORM_FIELDS.phone])}
            type="tel"
            autoComplete="off"
            disabled={isDisabled}
            defaultValue={formDefaults?.phone}
            error={errors.phone}
            {...register(FORM_FIELDS.phone, {
              required: txn('Phone is required'),
              validate: (value) =>
                Validator.isMobilePhone(value, 'en-US') ||
                txn('Phone is invalid'),
            })}
            as={InputMask}
            mask={'(999) 999-9999'}
            maskPlaceholder={null}
            data-testid={dataTestIds.signupForm.mobile}
          />
          {(!hasInvitation || errors?.email) && (
            <FormInput
              label={null}
              placeholder={txn(PLACEHOLDERS[FORM_FIELDS.email])}
              type="email"
              autoComplete="off"
              disabled={hasInvitation || isDisabled}
              defaultValue={formDefaults?.email}
              error={errors.email}
              {...register(FORM_FIELDS.email, {
                required: txn('Email is required'),
              })}
              style={{
                textTransform: emailOriginal ? 'lowercase' : 'none',
              }}
              data-testid={dataTestIds.signupForm.email}
            />
          )}

          <FormInput
            label={null}
            placeholder={txn(PLACEHOLDERS[FORM_FIELDS.password])}
            type="password"
            autoComplete="new-password"
            disabled={isDisabled}
            error={errors.password}
            {...register(FORM_FIELDS.password, {
              required: txn('Password is required'),
            })}
            data-testid={dataTestIds.signupForm.password}
          />

          <FormCheckbox
            error={errors.termsAndConditions}
            {...register(FORM_FIELDS.termsAndConditions, {
              required: txn(
                'Please accept the Terms of Use and Privacy Policy to continue.'
              ),
            })}
            data-testid={dataTestIds.signupForm.termsAndConditions}
          >
            <LegalText />
          </FormCheckbox>

          <Flex marginTop={PADDING_MORE} flex direction={'row'}>
            <Center w={'50%'}>
              <ExternalLink
                useNewTab={false}
                href={getSelfServeOnboardingUrl()}
                text={txn('Back')}
                data-testid={dataTestIds.signupForm.btnGoBack}
              />
            </Center>
            <Center w={'50%'}>
              <FormButton
                type="submit"
                isLoading={isDisabled}
                disabled={isDisabled}
                data-testid={dataTestIds.signupForm.btnSubmit}
              >
                {txn('Next')}
              </FormButton>
            </Center>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default SignupForm;
