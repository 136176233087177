import { getDocument, writeMergeDoc } from './firestore';

/**
 * 1-per-user record of data used during signup.
 * Intended to be used to help complete sign up process in different apps.
 */
const paths = {
  /**
   * @param {string} userId firebase id
   * @returns {string} doc path
   */
  signupData: (userId) => `authSession/${userId}/signupData/${userId}`,
};
/**
 * make json payload for signup data
 * @param {string} userId firebase id
 * @param {Object} signupData data collected during signup
 * @returns {Object} signup data, sanitized
 */
const makeSignupData = (userId, signupData = {}) => ({
  userId,
  ...signupData,
});
/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to persist signup data. If successful, the (slightly different) written data is returned.
 * @param {string} userId firebase id
 * @param {Object} data data to be saved
 * @returns { Promise<Object> } saved data
 * @throws { Error } if userId is not provided
 * @throws { Error } record fails to write
 */
const saveSignupData = async (userId, data) => {
  if (!userId) {
    throw new Error('userId required');
  }
  const signupData = makeSignupData(userId, data);
  const didWrite = await writeMergeDoc(paths.signupData(userId), signupData);
  if (didWrite) {
    return signupData;
  } else {
    throw new Error('Error writing signup data');
  }
};
/**
 * ### Async / Promise
 * gets the saved signup data from firestore
 * @param {string} userId firebase id
 * @returns {Promise<DocumentData | undefined>}
 * if undefined, either the document was not found or an error occurred.
 * otherwise, returns the document data as json object
 */
const getSignupData = async (userId) => {
  const docPath = paths.signupData(userId);
  return (await getDocument(docPath)) || undefined;
};

export { saveSignupData, getSignupData };
