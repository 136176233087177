import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ACTION_TYPES } from '../../api/firebase/actionReturn';
import { useAuthState } from '../../providers/authState';
import { PADDING, PADDING_MORE } from '../../providers/chakra/layout';
import { useTranslation } from '../../providers/i18next';
import { useReturnValue } from '../../providers/returnValue';
import { logError } from '../../providers/rollbar';
import { RETURN_ROUTE } from '../../routes/constants';
import { checkHasBrandingData } from '../../utils/checkHasBrandingData';
import { BaseLink, InternalLink } from '../Links';
import LoadingSpinner from '../LoadingSpinner';
import {
  BaseSigninForm,
  FormFooter,
  FormHeading,
  FormSubtitle,
  useFormStateHook,
  useVerificationHook,
} from './Parts';

const VerifyEmailForm = ({ actionCode }) => {
  const { txn } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useFormStateHook();
  const {
    isLoading: isLoadingAuth,
    isFailed,
    isReady,
    isAuthenticated,
    authUser,
    consumeActionReturn,
    sendVerificationEmail,
  } = useAuthState();
  const { brandingData, replaceReturnValue } = useReturnValue();
  const hasBrandingData = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);
  const useApply = true;
  const { isVerifyLoading, isVerifyError, isVerified, verifyError } =
    useVerificationHook(actionCode, useApply);

  const [didRedirect, setDidRedirect] = useState(false);
  const hasUser = !!authUser;
  const userId = authUser?.uid;
  const userEmail = authUser?.email;
  const isAlreadyVerified = isAuthenticated && authUser?.emailVerified;
  // if a user is already verified
  //  - we still consume the action code but ignore the results and display success

  const isLoading = isVerifyLoading || isLoadingAuth || !isReady;
  const isSuccess = isAlreadyVerified || isVerified;
  const isError = !isAlreadyVerified && (isFailed || isVerifyError);
  const shouldRedirect =
    !isLoading && isSuccess && isAuthenticated && !!userId && !didRedirect;

  useEffect(() => {
    if (verifyError) {
      toast({
        title: verifyError,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyError]);

  useEffect(() => {
    let timer;
    const returnToApp = async () => {
      if (shouldRedirect) {
        setDidRedirect(true); // after this, no other
        let actionReturn;
        try {
          actionReturn = await consumeActionReturn(
            userId,
            ACTION_TYPES.VERIFY_EMAIL
          );
          replaceReturnValue(actionReturn);
        } catch (err) {
          logError('Error Consuming Action Return Code', err, {
            err,
            userId,
            actionReturn,
            type: ACTION_TYPES.VERIFY_EMAIL,
          });
          // do nothing, use original return value
        }
        timer = setTimeout(() => {
          navigate(RETURN_ROUTE, { replace: true });
        }, 1000);
      }
    };
    returnToApp();
    return () => {
      clearTimeout(timer);
    };
  }, [
    consumeActionReturn,
    navigate,
    replaceReturnValue,
    shouldRedirect,
    userId,
  ]);

  const showSendSuccess = () => {
    toast({
      title: txn('Email Sent'),
      description: txn(
        'We just sent you a new verification email to {{userEmail}}',
        { userEmail }
      ),
      status: 'success',
    });
  };

  const showSendError = () => {
    toast({
      title: txn('Failed to Send'),
      description: txn('We could not send the verification email'),
      status: 'error',
    });
  };

  const resendEmailVerification = async () => {
    try {
      const returnValue = await sendVerificationEmail();
      if (returnValue?.errorCode) {
        switch (returnValue.errorCode) {
          // no error codes
          default:
            return showSendError();
        }
      } else if (returnValue) {
        return showSendSuccess();
      } else {
        throw new Error('Failed to send email verification');
      }
    } catch (err) {
      logError(`sendVerificationEmail failed: ${err.message}`, err);
      return showSendError();
    }
  };

  const showSuccessMessage = () => {
    return (
      <>
        <FormHeading>{txn('Email verified!')}</FormHeading>
        {hasUser ? (
          <>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn(hasBrandingData ? 'Welcome!' : 'Welcome to Wonderschool!')}{' '}
                {txn('Your account has been successfully verified.')}
              </FormSubtitle>
            </Box>
            <Box marginTop={PADDING_MORE}>
              <LoadingSpinner />
            </Box>
            <Box marginTop={PADDING_MORE}>
              <FormFooter>
                {txn('You will now be')}{' '}
                <InternalLink to={RETURN_ROUTE}>
                  {txn('redirected')}
                </InternalLink>{' '}
                {txn(
                  hasBrandingData
                    ? 'to your application.'
                    : 'to your Wonderschool application.'
                )}
              </FormFooter>
            </Box>
          </>
        ) : (
          <>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn(hasBrandingData ? 'Welcome!' : 'Welcome to Wonderschool!')}{' '}
                {txn('Your account has been successfully verified.')}{' '}
                {txn(
                  'Complete the verification process by signing into your account.'
                )}
              </FormSubtitle>
            </Box>
            <BaseSigninForm showForgotPasswordLink={false} />
          </>
        )}
      </>
    );
  };

  const showErrorMessage = () => {
    return (
      <>
        <FormHeading>{txn('Oops!')}</FormHeading>
        {hasUser ? (
          <>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn("It looks like you're having a problem with the link.")}
              </FormSubtitle>
            </Box>

            <Box marginTop={PADDING_MORE}>
              <FormSubtitle>
                {txn('Click here to')}{' '}
                <BaseLink onClick={resendEmailVerification}>
                  {txn('resend the confirmation email again')}
                </BaseLink>
                .
              </FormSubtitle>
            </Box>
          </>
        ) : (
          <>
            <Box marginTop={PADDING}>
              <FormSubtitle>
                {txn("It looks like you're having a problem with the link.")}{' '}
                {txn('Sign in to send a new confirmation email.')}
              </FormSubtitle>
            </Box>
            <BaseSigninForm showForgotPasswordLink={false} />
          </>
        )}
      </>
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (isError) {
    // Verification Invalid
    return showErrorMessage();
  } else if (isSuccess) {
    // Verification Complete
    return showSuccessMessage();
  } else {
    // Unexpected Error
    return showErrorMessage();
  }
};

VerifyEmailForm.propTypes = {
  actionCode: PropTypes.string.isRequired,
};

export default VerifyEmailForm;
