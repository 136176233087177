import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingSpinner from '../components/LoadingSpinner';
import { isProd } from '../config/env';
import { useTranslation } from '../providers/i18next';
import { useReturnValue } from '../providers/returnValue';
import { SIGNIN_ROUTE } from '../routes/constants';
import PageBase from './PageLayout/PageBase';

const TIMEOUT = 1000;
/**
 * This page doesn't do much but it's a empty page that will
 * complete the signout process. It is mainly used to reload the page
 * to reset the firebase app due to weirdness post-signout where the
 * internal socket channel won't reinitialize.
 */
const SignoutCompletePage = () => {
  const { txn } = useTranslation();
  const navigate = useNavigate();
  const { returnValue, isInstantReturnMode, makeBounceUrl } = useReturnValue();
  const hasReturnValue = !isEmpty(returnValue);

  useEffect(() => {
    if (hasReturnValue) {
      if (!isProd()) {
        console.log({ returnValue });
      }
      if (isInstantReturnMode) {
        const returnUrl = makeBounceUrl();
        window.location.replace(returnUrl);
      } else {
        navigate(SIGNIN_ROUTE, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReturnValue]);

  // taking too long, give up and go directly to sign in page
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace(SIGNIN_ROUTE);
    }, TIMEOUT);
    return () => clearTimeout(timer);
  });

  return (
    <PageBase titleBase={txn('Sign Out')}>
      <LoadingSpinner />
    </PageBase>
  );
};

export default SignoutCompletePage;
