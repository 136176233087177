import { dataTestIds } from '../../../config/data-testids';
import { useTranslation } from '../../../providers/i18next';
import { useReturnValue } from '../../../providers/returnValue';
import { convertTextLinks } from '../../../utils/brandingLinks';
import { ExternalLink } from '../../Links';
import FormFooter from './FormFooter';

/**
 * A standard FormFooter that displays legal information.
 */
const LegalFooter = () => {
  const { txn } = useTranslation();
  const { brandingData } = useReturnValue();
  /* 
    Example: 
    "disclaimer": {
      "key": "AUTH-TOS-PRIVACY-DISCLAIMER-ELI",
      "params": {
        "ws_eli_tos": "https://corp.wonderschool.com/eli-terms",
        "ws_eli_privacy": "https://corp.wonderschool.com/eli-privacy",
        "eli_tos": "https://theearlylearningmarketplace.org/terms/",
        "eli_privacy": "https://theearlylearningmarketplace.org/privacy/"
      }
    },
  */
  const hasBranding = !!brandingData && brandingData?.disclaimer;

  const footerText = hasBranding ? (
    convertTextLinks(
      txn(brandingData.disclaimer.key, brandingData.disclaimer.params)
    )
  ) : (
    <>
      {txn('By performing this action, you agree to our')}{' '}
      <ExternalLink
        href="https://www.wonderschool.com/corp/terms"
        data-testid={dataTestIds.legalFooter.termsLink}
      >
        {txn('Terms of Use')}
      </ExternalLink>{' '}
      {txn('and')}{' '}
      <ExternalLink
        href="https://www.wonderschool.com/corp/privacy"
        data-testid={dataTestIds.legalFooter.privacyPolicyLink}
      >
        {txn('Privacy Policy')}
      </ExternalLink>
      .
    </>
  );

  return <FormFooter>{footerText}</FormFooter>;
};

export default LegalFooter;
