import { Link } from '@chakra-ui/react';
import { useEffect } from 'react';

import { FormHeading, FormSubtitle } from '../components/Forms/Parts';
import { useColorScheme } from '../providers/chakra/colors';
import { useGlobalLoader } from '../providers/globalLoader';
import { useTranslation } from '../providers/i18next';
import { logError } from '../providers/rollbar';
import { logLocal } from '../providers/rollbar/api';
import { SIGNOUT_ROUTE } from '../routes/constants';
import PageBase from './PageLayout/PageBase';

type ErrorProps = {
  error?: Error | null;
};
/**
 * Renders a big error message and a link to reload the page.
 * Break glass in case of emergencies.
 */
const ErrorPage = ({ error = null }: ErrorProps) => {
  const { txn } = useTranslation();
  const linkColors = useColorScheme('makeLinkColors');
  const { disableLoader, setSkipLoadingTimeout } = useGlobalLoader();

  useEffect(() => {
    if (error) {
      logError(`Error on Error Page: ${error?.message}`, error);
      disableLoader();
      setSkipLoadingTimeout();
    }
  }, [error, disableLoader, setSkipLoadingTimeout]);

  let errorMessage = txn('Oops, something went wrong');
  try {
    errorMessage = `${txn('Error')}: ${txn(error?.message || errorMessage)}`;
  } catch (err) {
    logLocal('Error within error', err); // generally something wrong with rollbar
  }

  return (
    <PageBase titleBase={txn('Oh no!')}>
      <FormHeading textAlign="center" mono title="">
        {errorMessage}
      </FormHeading>
      <FormSubtitle textAlign="center" title="">
        <Link
          {...linkColors}
          onClick={() => {
            window.location.replace(SIGNOUT_ROUTE);
          }}
        >
          {txn('Click here to reload the app')}
        </Link>
      </FormSubtitle>
    </PageBase>
  );
};

export default ErrorPage;
