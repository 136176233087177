import { Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { MEDIUM_FONT, TITLE_FONT } from '../../../providers/chakra/typography';

/**
 * Form Heading to display, at top of form. Pairs with FormFooter.
 */
const FormHeading = ({
  /** @type {React.Children} */
  children,
  /** @type {string} text to display, overridden by children */
  title,
  /** @type {boolean} whether to use monospaced font */
  mono = false,
  ...props /** rest of props, forwarded to Chakra <Heading> */
}) => {
  const fontOptions = mono
    ? { fontSize: MEDIUM_FONT, fontFamily: 'mono' }
    : { fontSize: TITLE_FONT, fontFamily: 'heading' };
  return (
    <Heading w="100%" as="h1" {...fontOptions} fontWeight="light" {...props}>
      <Text>{children ? children : title}</Text>
    </Heading>
  );
};

FormHeading.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  mono: PropTypes.bool,
};

export default FormHeading;
