import { Link } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useColorScheme } from '../../providers/chakra/colors';

interface Props {
  children: JSX.Element;
  text?: string;
  to: string;
}
/**
 * A link that redirects to an internal ReactRouter route only
 */
const InternalLink = ({
  children /** @type {React.Children} */,
  text /** @type {string} text to display, overridden by children */,
  to /** @type {string} internal route */,
  ...props
}: Props) => {
  const linkColors = useColorScheme('makeLinkColors');
  const combinedProps = {
    ...linkColors,
    fontWeight: 'medium',
    ...props,
  };
  return (
    <Link {...combinedProps} as={ReactRouterLink} to={to}>
      {children ? children : text}
    </Link>
  );
};

export default InternalLink;
