import {
  applyActionCode as applyActionCodeFirebase,
  checkActionCode,
  confirmPasswordReset as confirmPasswordResetFirebase,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  signInWithCustomToken as signInWithCustomTokenFirebase,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
} from 'firebase/auth';

import { getAuth } from '../../config/init';
import {
  logError,
  logWarning,
  trackUser,
  trackUserSignout,
} from '../../providers/rollbar';
import { createIdentity } from '../authApi';
import { getActionCodeSettings } from './actionCodeSettings';
import { ACTION_TYPES } from './actionReturn';
import { getIdToken } from './currentUser';
import { saveGuestReturnData as saveGuestReturnDataAPI } from './functions';

/**
 * @typedef {import('firebase/auth').User} User
 * @typedef {import('firebase/auth').UserCredential} UserCredential
 * @typedef {{
 *  email: string,
 *  password: string,
 *  rest...
 * }} UserSignupData
 * @typedef {{
 *  credential: UserCredential,
 *  user: User,
 *  idToken: string,
 *  userData: {
 *    email: string,
 *    ...rest
 *  }
 * }} UserPayload
 */

/**
 * ### Async / Promise
 * Sign in an existing user in firebase and return credential information,
 * including id token jwt.
 * @param {string} email
 * @param {string} password
 * @returns {Promise<UserPayload | undefined | {errorCode: string}>}
 * If undefined, an unrecoverable error occurred.
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: emailInvalid - provided email is malformed
 * - errorCode: email - user account does not exist or is disabled
 * - errorCode: password - password does not match
 */
export const signInWithEmail = async (email, password, authCtx = getAuth()) => {
  try {
    if (!email) {
      return { errorCode: 'email' };
    }
    if (!password) {
      return { errorCode: 'password' };
    }
    // https://firebase.google.com/docs/reference/js/auth.usercredential.md#usercredential_interface
    const credential = await signInWithEmailAndPassword(
      authCtx,
      email,
      password
    );
    const user = credential.user;
    const idToken = await getIdToken(user, true);
    if (!idToken) {
      throw new Error('Could not get id token for user');
    }
    trackUser(user);
    return {
      credential,
      user,
      idToken,
    };
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/invalid-email - Thrown if the email address is not valid.
     * auth/user-disabled - Thrown if the user corresponding to the given email has been disabled.
     * auth/user-not-found - Thrown if there is no user corresponding to the given email.
     * auth/wrong-password - Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
     * auth/too-many-requests - Thrown after 5 failed attempts on a valid email with invalid
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/invalid-email':
          return {
            errorCode: 'emailInvalid',
          };
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          return {
            errorCode: 'email',
          };
        case 'auth/wrong-password':
          return {
            errorCode: 'password',
          };
        case 'auth/too-many-requests':
        case 'auth/permission-denied':
        case 'auth/internal-error':
          // coordinated with errors returned by authTriggers-onBeforeSignIn:
          // but there's a bug with the error message code
          // https://github.com/firebase/firebase-js-sdk/issues/6834
          return {
            errorCode: 'tooMany',
          };
        default:
          logWarning(`sign in error (google-expected): ${err.message}`, {
            err,
            code: err.code,
            email,
          });
          return; // signals unexpected error
      }
    } else {
      logError(`sign in error: ${err.message}`, err, { err, email });
      return; // signals unexpected runtime error
    }
  }
};
/**
 * ### Async / Promise
 * Sign in an existing user in firebase and return credential information,
 * including id token jwt.
 * @param {string} token
 * @returns {Promise<UserPayload | undefined | {errorCode: string}>}
 * If undefined, an unrecoverable error occurred.
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 */
export const signInWithCustomToken = async (token, authCtx = getAuth()) => {
  try {
    if (!token) {
      return { errorCode: 'token' };
    }

    const credential = await signInWithCustomTokenFirebase(authCtx, token);
    const user = credential.user;
    const idToken = await getIdToken(user, true);
    if (!idToken) {
      throw new Error('Could not get id token for user');
    }
    trackUser(user);
    return {
      credential,
      user,
      idToken,
    };
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/invalid-email - Thrown if the email address is not valid.
     * auth/user-disabled - Thrown if the user corresponding to the given email has been disabled.
     * auth/user-not-found - Thrown if there is no user corresponding to the given email.
     * auth/wrong-password - Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
     * auth/too-many-requests - Thrown after 5 failed attempts on a valid email with invalid
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          return {
            errorCode: 'email',
          };
        case 'auth/too-many-requests':
        case 'auth/permission-denied':
        case 'auth/internal-error':
          // coordinated with errors returned by authTriggers-onBeforeSignIn:
          // but there's a bug with the error message code
          // https://github.com/firebase/firebase-js-sdk/issues/6834
          return {
            errorCode: 'tooMany',
          };
        default:
          logWarning(`sign in error (google-expected): ${err.message}`, {
            err,
            code: err.code,
          });
          return; // signals unexpected error
      }
    } else {
      logError(`sign in error: ${err.message}`, err, { err });
      return; // signals unexpected runtime error
    }
  }
};

/**
 * ### Async / Promise
 * Sign up a new user in firebase and return credential information,
 * including id token jwt.
 * The user will be authenticated and signed into this firebase application.
 * It will NOT create additional firestore documents.
 * @param {UserSignupData} userData
 * @returns {Promise<UserPayload | undefined | {errorCode: string}>}
 * If undefined, an unrecoverable error occurred.
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: emailInvalid - provided email is malformed
 * - errorCode: email - user account already exists
 * - errorCode: password - password is not strong enough
 */
export const signUpWithAuthApi = async (userData = {}, authCtx = getAuth()) => {
  const { email, password, ...restData } = userData;
  if (!email) {
    return { errorCode: 'email' };
  }
  if (!password) {
    return { errorCode: 'password' };
  }
  try {
    const firebaseCustomToken = await createIdentity({
      email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      ...(userData.phone ? { phone: `+1${userData.phone}` } : {}),
      password,
    });

    const credential = await signInWithCustomTokenFirebase(
      authCtx,
      firebaseCustomToken
    );
    const user = credential.user;
    const idToken = await getIdToken(user);

    if (!idToken) {
      throw new Error('Could not get id token for user');
    }
    trackUser(user);
    return {
      credential,
      user,
      idToken,
      userData: { email, ...restData },
    };
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/email-already-in-use - Thrown if there already exists an account with the given email address.
     * auth/invalid-email - Thrown if the email address is not valid.
     * auth/operation-not-allowed - Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
     * auth/weak-password - Thrown if the password is not strong enough.
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/invalid-email':
          return {
            errorCode: 'emailInvalid',
            msg: err.code,
          };
        case 'auth/email-already-in-use':
          return {
            errorCode: 'email',
            msg: err.code,
          };
        case 'auth/weak-password':
          return {
            errorCode: 'password',
            msg: err.code,
          };
        default:
          logWarning(`sign up: create user error: ${err.message}`, {
            err,
            code: err.code,
            email,
            ...restData,
          });
          return; // signals unhandled error
      }
    } else {
      logError(`sign up: create user error: ${err.message}`, err, {
        err,
        email,
      });
      return; // signals failure
    }
  }
};

/**
 * ### Async / Promise
 * Sign up a new user in firebase and return credential information,
 * including id token jwt.
 * The user will be authenticated and signed into this firebase application.
 * It will NOT create additional firestore documents.
 * @param {UserSignupData} userData
 * @returns {Promise<UserPayload | undefined | {errorCode: string}>}
 * If undefined, an unrecoverable error occurred.
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: emailInvalid - provided email is malformed
 * - errorCode: email - user account already exists
 * - errorCode: password - password is not strong enough
 */
export const signUp = async (userData = {}, authCtx = getAuth()) => {
  const { email, password, ...restData } = userData;
  if (!email) {
    return { errorCode: 'email' };
  }
  if (!password) {
    return { errorCode: 'password' };
  }
  try {
    const credential = await createUserWithEmailAndPassword(
      authCtx,
      email,
      password
    );
    const user = credential.user;
    const idToken = await getIdToken(user);

    if (!idToken) {
      throw new Error('Could not get id token for user');
    }
    trackUser(user);
    return {
      credential,
      user,
      idToken,
      userData: { email, ...restData },
    };
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/email-already-in-use - Thrown if there already exists an account with the given email address.
     * auth/invalid-email - Thrown if the email address is not valid.
     * auth/operation-not-allowed - Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
     * auth/weak-password - Thrown if the password is not strong enough.
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/invalid-email':
          return {
            errorCode: 'emailInvalid',
            msg: err.code,
          };
        case 'auth/email-already-in-use':
          return {
            errorCode: 'email',
            msg: err.code,
          };
        case 'auth/weak-password':
          return {
            errorCode: 'password',
            msg: err.code,
          };
        default:
          logWarning(`sign up: create user error: ${err.message}`, {
            err,
            code: err.code,
            email,
            ...restData,
          });
          return; // signals unhandled error
      }
    } else {
      logError(`sign up: create user error: ${err.message}`, err, {
        err,
        email,
      });
      return; // signals failure
    }
  }
};
/**
 * ### Async / Promise
 * signs the user out of JUST this firebase app instance.
 * the client should either sign out of the session before calling this
 * @returns { Promise<boolean> } whether successful
 *  - true: success
 *  - false: generic error
 */
export const signOut = async (authCtx = getAuth()) => {
  try {
    await signOutFirebase(authCtx);
    trackUserSignout();
    return true; // signals success
  } catch (err) {
    // no error codes generated by firebase for this function
    logError(`sign out error: ${err.message}`, err);
    return; // signals failure
  }
};
/**
 * ### Async / Promise
 * Tries to send a password reset email to the provided email.
 * See here for error conditions: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#sendpasswordresetemail
 * @param {string} email
 * @param {Object} returnData
 * @returns {Promise<Object | undefined | {errorCode: string}>} to signal success or failure
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: emailInvalid - provided email is malformed
 * - errorCode: email - user account does not exist or is disabled
 */
export const sendPasswordResetEmail = async (
  email,
  returnData = {},
  isInvite = false,
  authCtx = getAuth()
) => {
  if (!email) {
    return { errorCode: 'email' };
  }
  let saved;
  try {
    saved = await saveGuestReturnDataAPI(
      email,
      ACTION_TYPES.RESET_PASSWORD,
      returnData
    );
    if (!saved) {
      throw new Error('Failed to save guest return data');
    }
    await sendPasswordResetEmailFirebase(
      authCtx,
      email,
      getActionCodeSettings(isInvite)
    );
    return saved; // signals success
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/user-not-found - Thrown if there is no user corresponding to the email address.
     * auth/invalid-email - Thrown if the email address is not valid.
     * auth/missing-android-pkg-name - An Android package name must be provided if the Android app is required to be installed.
     * auth/missing-continue-uri - A continue URL must be provided in the request.
     * auth/missing-ios-bundle-id - An iOS Bundle ID must be provided if an App Store ID is provided.
     * auth/invalid-continue-uri - The continue URL provided in the request is invalid.
     * auth/unauthorized-continue-uri - The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/user-not-found':
          return {
            errorCode: 'email',
            msg: err.code,
          };
        case 'auth/invalid-email':
          return {
            errorCode: 'emailInvalid',
            msg: err.code,
          };
        default:
          logWarning(
            `sendPasswordResetEmail email configuration error (handled): ${err.message}`,
            {
              err,
              code: err.code,
              returnData,
              isInvite,
              saved,
            }
          );
          return; // signals unhandled error
      }
    } else {
      logError(`sendPasswordResetEmail error: ${err.message}`, err, {
        err,
        returnData,
        isInvite,
        saved,
      });
      return; // signals failure
    }
  }
};

/**
 * ### Async / Promise
 * Checks if the action code is still valid
 * Wrapper for https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#verifypasswordresetcode
 * @param {string} actionCode secret code
 * @returns {Promise<ActionCodeInfo | boolean | { errorCode: string}>} whether the action code is still usable
 *  - ActionCodeInfo: success
 *  - false: generic error
 *  - errorCode object - firebase handled error
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: actionCode - actionCode is no longer valid. recommend resending email
 * - errorCode: user - user account has been disabled
 */
export const verifyActionCode = async (actionCode, authCtx = getAuth()) => {
  if (!actionCode) {
    return {
      errorCode: 'actionCode',
    };
  }
  try {
    const data = await checkActionCode(authCtx, actionCode);
    return data; // signals success
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/expired-action-code - Thrown if the action code has expired.
     * auth/invalid-action-code - Thrown if the action code is invalid. This can happen if the code is malformed or has already been used.
     * auth/user-disabled - Thrown if the user corresponding to the given action code has been disabled.
     * auth/user-not-found - Thrown if there is no user corresponding to the action code. This may have happened if the user was deleted between when the action code was issued and when this method was called.
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/expired-action-code':
        case 'auth/invalid-action-code':
          return {
            errorCode: 'actionCode',
            msg: err.code,
          };
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          return {
            errorCode: 'user',
            msg: err.code,
          };
        default:
          logWarning(`verifyActionCode error: ${err.message}`, {
            err,
            code: err.code,
          });
          return false; // signals unhandled error
      }
    } else {
      logError(`verifyActionCode error: ${err.message}`, err, {
        err,
      });
      return false; // signals failure
    }
  }
};
/**
 * ### Async / Promise
 * Attempts to consume a one-time verification action code
 * Wrapper for https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#applyactioncode
 * @param {string} actionCode secret code
 * @returns {Promise<boolean | { errorCode: string}>} whether the action code was successfully applied
 *  - true: success
 *  - false: generic error
 *  - errorCode object - firebase handled error
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: actionCode - actionCode is no longer valid. recommend resending email
 * - errorCode: user - user account has been disabled
 */
export const applyActionCode = async (actionCode, authCtx = getAuth()) => {
  if (!actionCode) {
    return {
      errorCode: 'actionCode',
    };
  }
  try {
    // throws if anything goes wrong
    await applyActionCodeFirebase(authCtx, actionCode);
    return true; // signals success
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/expired-action-code - Thrown if the action code has expired.
     * auth/invalid-action-code - Thrown if the action code is invalid. This can happen if the code is malformed or has already been used.
     * auth/user-disabled - Thrown if the user corresponding to the given action code has been disabled.
     * auth/user-not-found - Thrown if there is no user corresponding to the action code. This may have happened if the user was deleted between when the action code was issued and when this method was called.
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/expired-action-code':
        case 'auth/invalid-action-code':
          return {
            errorCode: 'actionCode',
            msg: err.code,
          };
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          return {
            errorCode: 'user',
            msg: err.code,
          };
        default:
          logWarning(`applyActionCode error (handled): ${err.message}`, {
            err,
            code: err.code,
          });
          return false; // signals unhandled error
      }
    } else {
      logError(`applyActionCode error: ${err.message}`, err, {
        err,
      });
      return false; // signals failure
    }
  }
};
/**
 * ### Async / Promise
 * Resets the password for the user attached to the actionCode
 * Wrapper for https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#confirmpasswordreset
 * @param {string} actionCode secret code
 * @returns {Promise<boolean | { errorCode: string}>} whether the operation completed successfully
 *  - true: success
 *  - false: generic error
 *  - errorCode object - firebase handled error
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: actionCode - actionCode is no longer valid. recommend resending email
 * - errorCode: user - user account has been disabled
 * - errorCode: password - password is not strong enough
 */
export const confirmPasswordReset = async (
  actionCode,
  newPassword,
  authCtx = getAuth()
) => {
  if (!actionCode) {
    return {
      errorCode: 'actionCode',
    };
  }
  if (!newPassword) {
    return {
      errorCode: 'password',
    };
  }
  try {
    await confirmPasswordResetFirebase(authCtx, actionCode, newPassword);
    return true; // signals success
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/expired-action-code - Thrown if the password reset code has expired.
     * auth/invalid-action-code - Thrown if the password reset code is invalid. This can happen if the code is malformed or has already been used.
     * auth/user-disabled - Thrown if the user corresponding to the given password reset code has been disabled.
     * auth/user-not-found - Thrown if there is no user corresponding to the password reset code. This may have happened if the user was deleted between when the code was issued and when this method was called.
     * auth/weak-password - Thrown if the new password is not strong enough.
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/expired-action-code':
        case 'auth/invalid-action-code':
          return {
            errorCode: 'actionCode',
            msg: err.code,
          };
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          return {
            errorCode: 'user',
            msg: err.code,
          };
        case 'auth/weak-password':
          return {
            errorCode: 'password',
            msg: err.code,
          };
        default:
          logWarning(`confirmPasswordReset error (handled): ${err.message}`, {
            err,
            code: err.code,
          });
          return false; // signals unhandled error
      }
    } else {
      logError(`confirmPasswordReset error: ${err.message}`, err, {
        err,
      });
      return false; // signals failure
    }
  }
};
