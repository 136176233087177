import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Checkbox,
  FormControl,
  FormErrorIcon,
  FormLabel,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { useColorScheme } from '../../../providers/chakra/colors';
import { PADDING } from '../../../providers/chakra/layout';
import { SMALLER_FONT } from '../../../providers/chakra/typography';

/**
 * A wrapped form component for one-line checkbox
 * Handles error display if provided. This is shown in the form of a warning icon on the right side
 * with a tooltip for the specific error message.
 *
 *
 *
 * Ref is forwarded for compatibility with react-hook-form
 * https://react-hook-form.com/get-started#Integratinganexistingform
 */
const FormCheckbox = forwardRef(function FormCheckboxFwdRef(
  {
    error,
    /** @type {Error} If truthy, the input will show the error state and message */
    label,
    /** @type {Error} If truthy, the input will show the error state and message */
    autoComplete,
    /** @type { boolean | string } https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
    noTop,
    /** @type {boolean} hide top padding and margin, useful for top of forms or paired/half inputs */
    ...props /** rest of props, forwarded to Chakra <Input> */
  },
  ref
) {
  const errorIconColors = useColorScheme('makeErrorColors');
  const errorTooltipColors = useColorScheme('makeErrorColors', 'bg');

  return (
    <FormControl isInvalid={error} marginTop={noTop ? '0' : PADDING}>
      {label && <FormLabel htmlFor={props.name}>{label}</FormLabel>}
      <Checkbox autoComplete={autoComplete} ref={ref} {...props}>
        {props.children}
        <FormErrorIcon
          as={() => (
            <Tooltip
              hasArrow
              placement="top"
              label={error?.message}
              fontSize={SMALLER_FONT}
              closeOnClick={false}
              gutter={10}
              borderRadius="4"
              defaultIsOpen
              {...errorTooltipColors}
            >
              <Icon
                as={WarningTwoIcon}
                boxSize={PADDING}
                {...errorIconColors}
              />
            </Tooltip>
          )}
        />
      </Checkbox>
    </FormControl>
  );
});

FormCheckbox.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  noTop: PropTypes.bool,
};

export default FormCheckbox;
