import Spinner, { WidgetSizeEnum } from './Spinner';

interface Props {
  text?: string;
  hasBranding?: boolean;
}
export default function Loader({ hasBranding, text }: Props) {
  return (
    <div className="space-y-10">
      {hasBranding ? null : (
        <div className="flex justify-center">
          <img className="max-w-96" src="/town-vector.svg" />
        </div>
      )}
      <div className="flex flex-row items-center gap-x-3.5 justify-center">
        <div>
          <Spinner
            size={WidgetSizeEnum.SMALL}
            spinnerColor="blue"
            overlayColor="blue"
          />
        </div>
        <div className="text-xl text-gray-900">{text}</div>
      </div>
    </div>
  );
}
