import { dataTestIds } from '../../../config/data-testids';
import { useTranslation } from '../../../providers/i18next';
import { useReturnValue } from '../../../providers/returnValue';
import { convertTextLinks } from '../../../utils/brandingLinks';
import { ExternalLinkV2 } from '../../Links';

const LegalTextV2 = () => {
  const { txn } = useTranslation();
  const { brandingData } = useReturnValue();
  /* 
    Example: 
    "disclaimer": {
      "key": "AUTH-TOS-PRIVACY-DISCLAIMER-ELI",
      "params": {
        "ws_eli_tos": "https://corp.wonderschool.com/eli-terms",
        "ws_eli_privacy": "https://corp.wonderschool.com/eli-privacy",
        "eli_tos": "https://theearlylearningmarketplace.org/terms/",
        "eli_privacy": "https://theearlylearningmarketplace.org/privacy/"
      }
    },
  */
  const hasBranding = !!brandingData && brandingData?.disclaimer;

  if (hasBranding) {
    return (
      <>
        {convertTextLinks(
          txn(brandingData.disclaimer.key, brandingData.disclaimer.params)
        )}
      </>
    );
  }

  return (
    <>
      {txn('I have read and agree to the')}{' '}
      <ExternalLinkV2
        href="https://www.wonderschool.com/corp/terms"
        data-testid={dataTestIds.legalFooter.termsLink}
      >
        {txn('Terms of Use')}
      </ExternalLinkV2>{' '}
      {txn('and')}{' '}
      <ExternalLinkV2
        href="https://www.wonderschool.com/corp/privacy"
        data-testid={dataTestIds.legalFooter.privacyPolicyLink}
      >
        {txn('Privacy Policy')}
      </ExternalLinkV2>{' '}
      {txn('and consent to be contacted by phone, email or text message.')}
    </>
  );
};

export default LegalTextV2;
