import InitChangePasswordForm from '../components/Forms/InitChangePasswordForm';
import { useTranslation } from '../providers/i18next';
import PageBase from './PageLayout/PageBase';

const ResetPasswordPage = () => {
  const { txn } = useTranslation();
  return (
    <PageBase titleBase={txn('Reset Password')}>
      <InitChangePasswordForm resetMode />
    </PageBase>
  );
};

export default ResetPasswordPage;
