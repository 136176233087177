import { docOnListen } from './firestore';
import { makeFunctionsRequest } from './functions';

const paths = {
  /**
   * @param {string} invitationId
   * @returns {string} doc path
   */
  invitation: (invitationId) => `invitations/${invitationId}`,
};

/**
 * Listens for updates to an invitation doc by the specified id
 * If invitation doesn't exist, callback is called with undefined
 * @param {string} invitationId
 * @param {(doc: DocumentData | undefined) => {}} next callback passed User object https://firebase.google.com/docs/reference/js/auth.user.md#user_interface
 * @param {(err: Error) => {}} error callback triggered on error
 * @returns unsubscribe function
 */
export const listenForInvitation = (invitationId, next, error) => {
  if (!invitationId) {
    throw new Error('invitationId required');
  }
  return docOnListen(paths.invitation(invitationId), next, error);
};

export const getPendingUserInvite = async (email) => {
  return makeFunctionsRequest('/endpoints-getPendingUserInvite', {
    email,
  });
};
