// This file must exist outside of routes/index.js and *Route.js because of circular dependencies

// TODO: remove all routes with preface OLD_
export const ROOT_ROUTE = '/';
export const ACTION_ROUTE = '/action';
export const ERROR_ROUTE = '/error';
export const FORGOT_PASSWORD_ROUTE = '/forgot';
export const INVITATION_ROUTE = '/invited';
export const OLD_INVITATION_SIGNUP_ROUTE = '/old/invitation/:invitationHash';
export const OLD_INVITATION_STATUS_ROUTE = '/old/invitation-status';
export const INVITATION_SIGNUP_ROUTE = '/invitation/:invitationHash';
export const INVITATION_STATUS_ROUTE = '/invitaton-status';
export const FEATURE_FLAGS_ROUTE = '/feature-flags';
export const CUSTOM_TOKEN_LOGIN_ROUTE = '/custom-token-login';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const RETURN_ROUTE = '/return';
// TODO: swap for RETURN_ROUTE when feature flag is removed.
export const RETURN_ROUTE_V2 = '/v2/return';
export const RETURN_COMPLETE_ROUTE = '/return-complete';
export const SIGNIN_ROUTE = '/signin';
export const SIGNIN_RESET_ROUTE = '/signin-reset';
export const SIGNOUT_ROUTE = '/signout';
export const SIGNOUT_COMPLETE_ROUTE = '/signout-complete';
export const SIGNUP_ROUTE = '/signup';
export const OLD_SIGNUP_ROUTE = '/old/signup';
export const OLD_SIGNUP_ROUTE_WITH_HASH = '/old/signup/:signupHash';
export const VERIFY_ROUTE = '/verify';

// saml paths
export const SAML_IDP_ROUTE = '/saml-idp';
