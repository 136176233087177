interface Props {
  text: string;
  subtext?: string;
}

export default function FormHeader({ text, subtext }: Props) {
  return (
    <div className="text-center space-y-6 max-sm:space-y-2">
      <div className="text-4xl max-sm:text-xl font-bold">{text}</div>
      {subtext ? (
        <div className="text-xl max-sm:text-sm">
          <div>{subtext}</div>
        </div>
      ) : null}
    </div>
  );
}
