// CODE MIRRORED in /functions/config/env.js

import { isEmpty } from 'lodash';

// firebaseConfig is configuration just for initialization of firebase app
// wsConfig is wonderschool specific settings
// _meta includes debugging information
import configJson from './config.json';

const { firebaseConfig, wsConfig, _meta } = configJson;

const LOCAL = 'local';
const STAGING = 'staging';
const DEV = 'dev';
const PROD = 'prod';
const Environments = {
  [LOCAL]: LOCAL,
  [DEV]: DEV,
  [STAGING]: STAGING,
  [PROD]: PROD,
};

const ENV_KEY = 'envName';
let currentEnv = Environments[wsConfig[ENV_KEY]];
/**
 * @returns {string} current environment name
 */
const getCurrentEnv = () => {
  return currentEnv;
};

/**
 * configuration for specified env, from file copied during build step
 * see package.json
 * @returns {{
 *  _meta: Object,
 *  firebaseConfig: FirebaseOptions,
 *  wsConfig: {
 *    functionsDomain: string,
 *    ccmsFunctionsDomain: string,
 *    ccmsDomain: string,
 *    accountsDomain: string,
 *    onboardingDomain: string,
 *    authApiDomain: string,
 *    secretsProject: string,
 *    envName: string,
 *    cookieSecret: string,
 *    defaultReturn: string,
 *    rollbar: {
 *      accessToken: string,
 *      environment: string,
 *    },
 *    launchDarklyClientId: string,
 *    gtmId: string,
 * }}} configuration settings
 */
const getConfig = () => {
  return { _meta, firebaseConfig, wsConfig };
};

/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isLocal = (env = getCurrentEnv()) => env === LOCAL;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isDev = (env = getCurrentEnv()) => env === DEV;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isStaging = (env = getCurrentEnv()) => env === STAGING;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isProd = (env = getCurrentEnv()) => env === PROD;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isTest = (env = getCurrentEnv()) =>
  (isLocal(env) && isEmpty(process.env.REACT_APP_GIT_SHA)) ||
  process.env.REACT_APP_IS_TEST === 'true' ||
  process.env.REACT_APP_IS_TEST === true;

/**
 * whether the emulation env variable has been set
 * @returns {boolean}
 */
const shouldEmulateFirebase = () =>
  process.env.REACT_APP_FIREBASE_EMULATOR === 'ACTIVE';

const SELF_SERVE_ONBOARDING_URL =
  process.env.REACT_APP_SELF_SERVE_ONBOARDING_URL;
const getSelfServeOnboardingUrl = () => {
  return (
    SELF_SERVE_ONBOARDING_URL ||
    getConfig().wsConfig.onboardingDomain ||
    'https://onboarding.wonderschool.com'
  );
};

const getGtmId = () => {
  return getConfig()?.wsConfig?.gtmId || undefined;
};

export {
  LOCAL,
  DEV,
  PROD,
  Environments,
  getCurrentEnv,
  getConfig,
  isLocal,
  isDev,
  isStaging,
  isProd,
  isTest,
  shouldEmulateFirebase,
  getSelfServeOnboardingUrl,
  getGtmId,
};
