import { INVITATION_ROUTE } from '../../routes/constants';
import { getUrlRoot } from '../../utils/urls';

export const getInvitationSettings = (url = window.location.href) => {
  return {
    url: `${getUrlRoot(url)}${INVITATION_ROUTE}`,
  };
};

export const getDefaultSettings = () => undefined;

export const getActionCodeSettings = (isInvitation = false) => {
  if (isInvitation) {
    return getInvitationSettings();
  } else {
    return getDefaultSettings();
  }
};
