import React from 'react';

import { styles } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}
export default function Button({ disabled, text, ...rest }: Props) {
  return (
    <button
      className={`${styles.btnBase} ${disabled ? styles.btnDisabled : styles.btnEnabled}`}
      {...rest}
    >
      {text}
    </button>
  );
}
