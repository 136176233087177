/**
 * Gets the root of the url
 * @param {string} urlString url
 * @returns root of a url (origin AND port)
 * @example https://127.0.0.1:4000/signin -> https://127.0.0.1:4000/
 */
export const getUrlRoot = (urlString) => {
  if (!urlString) {
    throw new Error('Invalid urlString');
  }
  const url = new URL(urlString);
  const index = urlString.indexOf(url.pathname);
  if (index < 0) {
    throw new Error('Invalid url pathname', url.pathname);
  }
  return urlString.slice(0, index);
};

/**
 * Gets the full path of a url (no search params or hash)
 * @param {string} urlString url
 * @returns path of the url
 * @example https://127.0.0.1:4000/invited -> /invited
 */
export const getUrlPathname = (urlString) => {
  if (!urlString) {
    throw new Error('Invalid urlString');
  }
  const url = new URL(urlString);
  return url.pathname;
};
