import { useColorModeValue } from '@chakra-ui/react';

import { useTranslation } from '../../../../providers/i18next';
import logoMark from './images/logo-mark.png';
import logoWordLight from './images/logo-word-white.png';
import logoWordDark from './images/logo-word.png';
import LogoBox from './LogoBox';
import LogoImage from './LogoImage';

const LeftWSLogo = () => {
  const { txn } = useTranslation();
  const alt = txn('Wonderschool Logo');
  const logoWord = useColorModeValue(logoWordDark, logoWordLight);

  return (
    <LogoBox>
      <LogoImage
        // small
        display={{
          base: 'block',
          sm: 'none',
        }}
        src={logoMark}
        alt={alt}
      />
      <LogoImage
        // big
        display={{
          base: 'none',
          sm: 'block',
        }}
        src={logoWord}
        alt={alt}
      />
    </LogoBox>
  );
};

export default LeftWSLogo;
