import { useEffect, useRef } from 'react';

const SamlPostForm = ({ token }) => {
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
  }, []);

  return (
    <form ref={formRef} method="POST" action={token.entityEndpoint}>
      <input type="hidden" name="RelayState" value={token.relayState} />
      <input type="hidden" name="SAMLResponse" value={token.context} />
    </form>
  );
};

export default SamlPostForm;
