/*****************************/
/* CLONED in CCMS-WEB-WSAuth */
/*****************************/

import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { clearIntervalAsync, setIntervalAsync } from 'set-interval-async/fixed';

import { getAuth } from '../../config/init';
import { logError, trackUser, trackUserSignout } from '../rollbar';

const CHECK_INTERVAL = 1000;

/**
 * Simple hook that monitors for user state updates.
 */
const useAuthUser = () => {
  // unlike authSession, isLoading starts true (instead of false with sessions)
  // which means is ready will always start false and transition one way to true
  const [authUser, isLoading, authError] = useAuthState(getAuth());
  const isReady = !isLoading;
  const isFailed = !!authError;
  const hasUser = !!authUser;

  const [isEmailVerified, setIsEmailVerified] = useState(
    !!authUser?.emailVerified
  );

  useEffect(() => {
    if (authUser) {
      trackUser(authUser);
    } else {
      trackUserSignout();
    }
  }, [authUser]);

  useEffect(() => {
    let interval = null;
    const checkVerified = async () => {
      if (interval) clearIntervalAsync(interval);
      if (authUser && !isEmailVerified) {
        interval = setIntervalAsync(async () => {
          if (!isEmailVerified) {
            try {
              await getAuth().currentUser?.reload();
              setIsEmailVerified(authUser?.emailVerified || false);
            } catch (err: any) {
              logError(
                `Polling for authUser.emailVerified: ${err.message}`,
                err
              );
              clearIntervalAsync(interval);
            }
          } else {
            clearIntervalAsync(interval);
          }
        }, CHECK_INTERVAL);
      }
    };
    checkVerified();

    return () => {
      if (interval) clearIntervalAsync(interval);
    };
  }, [authUser, isEmailVerified]);

  return {
    isLoading, // whether firebase is initialized and the authentication listener is setup
    isFailed, // whether there was an error trying to load the user
    isReady, // whether initialization has completed
    isEmailVerified, // whether the user has verified their email
    hasUser, // whether a user has been found
    authUser, // latest value for authUser. `null` if not present, `undefined` if not yet loaded
    authError, // Any AuthError returned by Firebase when trying to load the user, or undefined if there is no error
  };
};

export default useAuthUser;
