import ExternalLink from '../components/Links/ExternalLink';

export const convertTextLinks = (textWithLinks) => {
  const LINK_REGEX = /(\[[^\]]*\]\([^)]*\))/g;
  const LINK_NAME_REGEX = /\[(.*?)\]/;
  const LINK_HREF_REGEX = /\((.*?)\)/;

  const stringArray = textWithLinks
    .replace(LINK_REGEX, (match) => `|${match}|`)
    .split('|');

  return stringArray.map((strMatch, index) => {
    if (strMatch.match(LINK_REGEX)) {
      const [name, href] = [
        (LINK_NAME_REGEX.exec(strMatch) || [])[1],
        (LINK_HREF_REGEX.exec(strMatch) || [])[1],
      ];

      return (
        <ExternalLink key={index} href={href}>
          {name}
        </ExternalLink>
      );
    } else {
      return strMatch;
    }
  });
};
