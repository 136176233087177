import {
  // useCallback,
  useMemo,
} from 'react';
import Validator from 'validator';
import * as yup from 'yup';

import { useTranslation } from '../../../providers/i18next';
import { passwordRegex } from '../../../utils/passwordRegex';
import { FORM_FIELD_NAMES, UserCredentialsFormSchema } from '../types';

function useSignup() {
  // const { currentUser } = useAuth();
  const { txn } = useTranslation();

  const userCredentialsFormSchema: yup.SchemaOf<UserCredentialsFormSchema> =
    useMemo(
      () =>
        yup
          .object({
            [FORM_FIELD_NAMES.NEW_SUPPLY]: yup.boolean().optional(),
            [FORM_FIELD_NAMES.INVITATION_ID]: yup.string().optional(),
            [FORM_FIELD_NAMES.NETWORK_CODE]: yup.string().optional(),
            [FORM_FIELD_NAMES.PHONE]: yup
              .string()
              .optional()
              .test('is-mobile', txn('Phone is invalid'), function (value) {
                // need to keep this an optional field
                if (!value) return true;
                // if value is not empty, validate it
                return Validator.isMobilePhone(value, 'en-US');
              }),
            [FORM_FIELD_NAMES.FIRST_NAME]: yup
              .string()
              .max(50, txn('First name must be less than 50 characters'))
              .required(txn('First name is required'))
              .default(''),
            [FORM_FIELD_NAMES.LAST_NAME]: yup
              .string()
              .max(50, txn('Last name must be less than 50 characters'))
              .required(txn('Last name is required'))
              .default(''),
            [FORM_FIELD_NAMES.EMAIL]: yup
              .string()
              .default('')
              .required(txn('Email is required')),
            [FORM_FIELD_NAMES.NEW_SUPPLY]: yup.boolean().default(false),
            [FORM_FIELD_NAMES.PASSWORD]: yup
              .string()
              .required(txn('Password is required'))
              .matches(
                passwordRegex,
                txn('Password does meet minimum complexity requirements')
              ),
            [FORM_FIELD_NAMES.PASSWORD_CONFIRM]: yup
              .string()
              .required(txn('Please confirm your password'))
              .oneOf(
                [yup.ref(FORM_FIELD_NAMES.PASSWORD)],
                txn('Passwords do not match')
              ),
            [FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]: yup
              .boolean()
              .oneOf(
                [true],
                txn(
                  'Please accept the Terms of Use and Privacy Policy to continue.'
                )
              )
              .required(
                txn(
                  'Please accept the Terms of Use and Privacy Policy to continue.'
                )
              ),
          })
          .required(),
      [txn]
    );

  return {
    userCredentialsFormSchema,
  };
}

export default useSignup;
