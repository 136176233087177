import { useLDClient } from 'launchdarkly-react-client-sdk';
import { isNil, omitBy } from 'lodash';
import { useEffect } from 'react';

import { getConfig } from '../../config/env';
import useAuthUser from '../auth/useAuthUserV2';
import { logWarning } from '../rollbar';
import { setLDClient } from './ldClient';

const clientSideID = getConfig()?.wsConfig?.launchDarklyClientId;

export const makeLDContext = (authUser = null) => {
  return {
    clientSideID,
    user: authUser
      ? omitBy(
          {
            key: authUser?.uid ?? 'anonymous-user',
            name: authUser ? authUser?.displayName : 'anonymous-user', // user!.displayName might not exist. if that's the case, do not set
            email: authUser?.email,
            anonymous: !authUser?.uid,
          },
          isNil
        )
      : {
          anonymous: true,
        },
    options: {
      bootstrap: 'localStorage',
    },
  };
};

export function LaunchDarklyContextInitializer() {
  const { authUser } = useAuthUser();
  const ldClient = useLDClient();

  useEffect(() => {
    const doInitialize = async () => {
      if (!ldClient) return;

      setLDClient(ldClient);

      try {
        const ldContext = makeLDContext(authUser);
        await ldClient.identify(ldContext);
      } catch (error) {
        logWarning('LaunchDarklyContextInitializer error', { error });
      }
    };
    doInitialize();
  }, [authUser, ldClient]);
  return <div />;
}
